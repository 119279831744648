import { GraphQLClient } from 'graphql-request';
import { GraphQLClientRequestHeaders } from 'graphql-request/build/cjs/types';
import gql from 'graphql-tag';
export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  /** A date-time string at UTC, such as 2019-12-03T09:54:33Z, compliant with the date-time format. */
  DateTime: any;
  /** The `JSON` scalar type represents JSON values as specified by [ECMA-404](http://www.ecma-international.org/publications/files/ECMA-ST/ECMA-404.pdf). */
  JSON: any;
  /** The `JSONObject` scalar type represents JSON objects as specified by [ECMA-404](http://www.ecma-international.org/publications/files/ECMA-ST/ECMA-404.pdf). */
  JSONObject: any;
};

export type AnalyticsOrder = {
  __typename?: 'AnalyticsOrder';
  companyId: Scalars['String'];
  configuration: Scalars['JSON'];
  createdAt: Scalars['DateTime'];
  createdBy: Scalars['String'];
  id: Scalars['ID'];
  state: AnalyticsOrderState;
  stateUpdatedAt: Scalars['DateTime'];
  type: AnalyticsType;
  updatedAt: Scalars['DateTime'];
};

export type AnalyticsOrderOrderByWithRelationInput = {
  configuration?: InputMaybe<SortOrder>;
  createdAt?: InputMaybe<SortOrder>;
  createdBy?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  state?: InputMaybe<SortOrder>;
  stateUpdatedAt?: InputMaybe<SortOrder>;
  type?: InputMaybe<SortOrder>;
  updatedAt?: InputMaybe<SortOrder>;
};

export enum AnalyticsOrderScalarFieldEnum {
  CompanyId = 'companyId',
  Configuration = 'configuration',
  CreatedAt = 'createdAt',
  CreatedBy = 'createdBy',
  Id = 'id',
  State = 'state',
  StateUpdatedAt = 'stateUpdatedAt',
  Type = 'type',
  UpdatedAt = 'updatedAt'
}

export enum AnalyticsOrderState {
  Created = 'CREATED',
  Done = 'DONE',
  Error = 'ERROR',
  Running = 'RUNNING'
}

export type AnalyticsOrderWhereInput = {
  AND?: InputMaybe<Array<AnalyticsOrderWhereInput>>;
  NOT?: InputMaybe<Array<AnalyticsOrderWhereInput>>;
  OR?: InputMaybe<Array<AnalyticsOrderWhereInput>>;
  configuration?: InputMaybe<JsonFilter>;
  createdAt?: InputMaybe<DateTimeFilter>;
  createdBy?: InputMaybe<StringFilter>;
  id?: InputMaybe<StringFilter>;
  state?: InputMaybe<EnumAnalyticsOrderStateFilter>;
  stateUpdatedAt?: InputMaybe<DateTimeFilter>;
  type?: InputMaybe<EnumAnalyticsTypeFilter>;
  updatedAt?: InputMaybe<DateTimeFilter>;
};

export type AnalyticsOrderWhereUniqueInput = {
  id?: InputMaybe<Scalars['String']>;
};

export enum AnalyticsType {
  ActiveUser = 'ACTIVE_USER',
  ChatInteraction = 'CHAT_INTERACTION',
  ChatInteractionDetailed = 'CHAT_INTERACTION_DETAILED',
  ReferenceStat = 'REFERENCE_STAT',
  UserChatExport = 'USER_CHAT_EXPORT'
}

/** List of possible assignments for a user */
export enum Assignments {
  SpaceManager = 'SPACE_MANAGER'
}

export type Assistant = {
  __typename?: 'Assistant';
  _count: AssistantCount;
  access?: Maybe<Array<Scalars['String']>>;
  alert?: Maybe<Scalars['String']>;
  assistantAccess: Array<AssistantAccessDto>;
  chatUpload: ChatUpload;
  company: Company;
  companyId: Scalars['String'];
  createdAt: Scalars['DateTime'];
  defaultForCompanyId?: Maybe<Scalars['String']>;
  examples?: Maybe<Array<Scalars['String']>>;
  explanation?: Maybe<Scalars['String']>;
  fallbackModule: Scalars['String'];
  goals?: Maybe<Array<Scalars['String']>>;
  id: Scalars['ID'];
  inputLimit?: Maybe<Scalars['Int']>;
  inputPlaceholder?: Maybe<Scalars['String']>;
  isExternal: Scalars['Boolean'];
  languageModel?: Maybe<Scalars['String']>;
  modules?: Maybe<Array<Module>>;
  name: Scalars['String'];
  prompts?: Maybe<Array<Prompt>>;
  scopeRules?: Maybe<Array<ScopeRule>>;
  settings?: Maybe<Scalars['JSON']>;
  subtitle?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
  updatedAt: Scalars['DateTime'];
  userPrompts: Array<UserPrompt>;
};

export type AssistantAccess = {
  __typename?: 'AssistantAccess';
  assistantId: Scalars['String'];
  createdAt: Scalars['DateTime'];
  entityId: Scalars['String'];
  entityType: AssistantAccessEntityType;
  id: Scalars['ID'];
  type: AssistantAccessType;
  updatedAt: Scalars['DateTime'];
};

export type AssistantAccessAssistantIdEntityIdTypeCompoundUniqueInput = {
  assistantId: Scalars['String'];
  entityId: Scalars['String'];
  type: AssistantAccessType;
};

export type AssistantAccessCreateDto = {
  entityId: Scalars['String'];
  entityType: Scalars['String'];
  type: Scalars['String'];
};

export type AssistantAccessCreateManyInput = {
  entityId: Scalars['String'];
  entityType: AssistantAccessEntityType;
  id?: InputMaybe<Scalars['String']>;
  type: AssistantAccessType;
};

export type AssistantAccessDto = {
  __typename?: 'AssistantAccessDto';
  entityId: Scalars['String'];
  entityType: Scalars['String'];
  id: Scalars['String'];
  type: Scalars['String'];
};

export enum AssistantAccessEntityType {
  Group = 'GROUP',
  User = 'USER'
}

export type AssistantAccessOrderByWithRelationInput = {
  assistantId?: InputMaybe<SortOrder>;
  entityId?: InputMaybe<SortOrder>;
  entityType?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  type?: InputMaybe<SortOrder>;
};

export enum AssistantAccessScalarFieldEnum {
  AssistantId = 'assistantId',
  CreatedAt = 'createdAt',
  EntityId = 'entityId',
  EntityType = 'entityType',
  Id = 'id',
  Type = 'type',
  UpdatedAt = 'updatedAt'
}

export enum AssistantAccessType {
  Manage = 'MANAGE',
  Upload = 'UPLOAD',
  Use = 'USE'
}

export type AssistantAccessWhereInput = {
  AND?: InputMaybe<Array<AssistantAccessWhereInput>>;
  NOT?: InputMaybe<Array<AssistantAccessWhereInput>>;
  OR?: InputMaybe<Array<AssistantAccessWhereInput>>;
  assistantId?: InputMaybe<StringFilter>;
  entityId?: InputMaybe<StringFilter>;
  entityType?: InputMaybe<EnumAssistantAccessEntityTypeFilter>;
  id?: InputMaybe<StringFilter>;
  type?: InputMaybe<EnumAssistantAccessTypeFilter>;
};

export type AssistantAccessWhereUniqueInput = {
  assistantId_entityId_type?: InputMaybe<AssistantAccessAssistantIdEntityIdTypeCompoundUniqueInput>;
  id?: InputMaybe<Scalars['String']>;
};

export type AssistantCount = {
  __typename?: 'AssistantCount';
  chats: Scalars['Int'];
  modules: Scalars['Int'];
  prompts: Scalars['Int'];
  scopeRules: Scalars['Int'];
  userPrompts: Scalars['Int'];
};

export type AssistantCreateInput = {
  access?: InputMaybe<Array<Scalars['String']>>;
  alert?: InputMaybe<Scalars['String']>;
  chatUpload?: InputMaybe<ChatUpload>;
  createdAt?: InputMaybe<Scalars['DateTime']>;
  examples?: InputMaybe<Array<Scalars['String']>>;
  explanation?: InputMaybe<Scalars['String']>;
  fallbackModule: Scalars['String'];
  goals?: InputMaybe<Array<Scalars['String']>>;
  id?: InputMaybe<Scalars['String']>;
  inputLimit?: InputMaybe<Scalars['Int']>;
  inputPlaceholder?: InputMaybe<Scalars['String']>;
  isExternal?: InputMaybe<Scalars['Boolean']>;
  languageModel?: InputMaybe<Scalars['String']>;
  modules?: InputMaybe<ModuleCreateNestedManyWithoutAssistantInput>;
  name: Scalars['String'];
  prompts?: InputMaybe<PromptCreateNestedManyWithoutAssistantInput>;
  scopeRules?: InputMaybe<ScopeRuleCreateNestedManyWithoutAssistantInput>;
  settings?: InputMaybe<Scalars['JSON']>;
  subtitle?: InputMaybe<Scalars['String']>;
  title?: InputMaybe<Scalars['String']>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
  userPrompts?: InputMaybe<UserPromptCreateNestedManyWithoutAssistantInput>;
};

export type AssistantCreateManyCompanyInput = {
  access?: InputMaybe<Array<Scalars['String']>>;
  alert?: InputMaybe<Scalars['String']>;
  chatUpload?: InputMaybe<ChatUpload>;
  createdAt?: InputMaybe<Scalars['DateTime']>;
  defaultForCompanyId?: InputMaybe<Scalars['String']>;
  examples?: InputMaybe<Array<Scalars['String']>>;
  explanation?: InputMaybe<Scalars['String']>;
  fallbackModule: Scalars['String'];
  goals?: InputMaybe<Array<Scalars['String']>>;
  id?: InputMaybe<Scalars['String']>;
  inputLimit?: InputMaybe<Scalars['Int']>;
  inputPlaceholder?: InputMaybe<Scalars['String']>;
  isExternal?: InputMaybe<Scalars['Boolean']>;
  languageModel?: InputMaybe<Scalars['String']>;
  name: Scalars['String'];
  settings?: InputMaybe<Scalars['JSON']>;
  subtitle?: InputMaybe<Scalars['String']>;
  title?: InputMaybe<Scalars['String']>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
};

export type AssistantCreateManyCompanyInputEnvelope = {
  data: Array<AssistantCreateManyCompanyInput>;
  skipDuplicates?: InputMaybe<Scalars['Boolean']>;
};

export type AssistantCreateNestedManyWithoutCompanyInput = {
  connect?: InputMaybe<Array<AssistantWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<AssistantCreateOrConnectWithoutCompanyInput>>;
  create?: InputMaybe<Array<AssistantCreateWithoutCompanyInput>>;
  createMany?: InputMaybe<AssistantCreateManyCompanyInputEnvelope>;
};

export type AssistantCreateNestedOneWithoutDefaultForCompanyInput = {
  connect?: InputMaybe<AssistantWhereUniqueInput>;
  connectOrCreate?: InputMaybe<AssistantCreateOrConnectWithoutDefaultForCompanyInput>;
  create?: InputMaybe<AssistantCreateWithoutDefaultForCompanyInput>;
};

export type AssistantCreateOrConnectWithoutCompanyInput = {
  create: AssistantCreateWithoutCompanyInput;
  where: AssistantWhereUniqueInput;
};

export type AssistantCreateOrConnectWithoutDefaultForCompanyInput = {
  create: AssistantCreateWithoutDefaultForCompanyInput;
  where: AssistantWhereUniqueInput;
};

export type AssistantCreateWithoutCompanyInput = {
  access?: InputMaybe<Array<Scalars['String']>>;
  alert?: InputMaybe<Scalars['String']>;
  chatUpload?: InputMaybe<ChatUpload>;
  createdAt?: InputMaybe<Scalars['DateTime']>;
  examples?: InputMaybe<Array<Scalars['String']>>;
  explanation?: InputMaybe<Scalars['String']>;
  fallbackModule: Scalars['String'];
  goals?: InputMaybe<Array<Scalars['String']>>;
  id?: InputMaybe<Scalars['String']>;
  inputLimit?: InputMaybe<Scalars['Int']>;
  inputPlaceholder?: InputMaybe<Scalars['String']>;
  isExternal?: InputMaybe<Scalars['Boolean']>;
  languageModel?: InputMaybe<Scalars['String']>;
  modules?: InputMaybe<ModuleCreateNestedManyWithoutAssistantInput>;
  name: Scalars['String'];
  prompts?: InputMaybe<PromptCreateNestedManyWithoutAssistantInput>;
  scopeRules?: InputMaybe<ScopeRuleCreateNestedManyWithoutAssistantInput>;
  settings?: InputMaybe<Scalars['JSON']>;
  subtitle?: InputMaybe<Scalars['String']>;
  title?: InputMaybe<Scalars['String']>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
  userPrompts?: InputMaybe<UserPromptCreateNestedManyWithoutAssistantInput>;
};

export type AssistantCreateWithoutDefaultForCompanyInput = {
  access?: InputMaybe<Array<Scalars['String']>>;
  alert?: InputMaybe<Scalars['String']>;
  chatUpload?: InputMaybe<ChatUpload>;
  createdAt?: InputMaybe<Scalars['DateTime']>;
  examples?: InputMaybe<Array<Scalars['String']>>;
  explanation?: InputMaybe<Scalars['String']>;
  fallbackModule: Scalars['String'];
  goals?: InputMaybe<Array<Scalars['String']>>;
  id?: InputMaybe<Scalars['String']>;
  inputLimit?: InputMaybe<Scalars['Int']>;
  inputPlaceholder?: InputMaybe<Scalars['String']>;
  isExternal?: InputMaybe<Scalars['Boolean']>;
  languageModel?: InputMaybe<Scalars['String']>;
  modules?: InputMaybe<ModuleCreateNestedManyWithoutAssistantInput>;
  name: Scalars['String'];
  prompts?: InputMaybe<PromptCreateNestedManyWithoutAssistantInput>;
  scopeRules?: InputMaybe<ScopeRuleCreateNestedManyWithoutAssistantInput>;
  settings?: InputMaybe<Scalars['JSON']>;
  subtitle?: InputMaybe<Scalars['String']>;
  title?: InputMaybe<Scalars['String']>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
  userPrompts?: InputMaybe<UserPromptCreateNestedManyWithoutAssistantInput>;
};

export type AssistantListRelationFilter = {
  every?: InputMaybe<AssistantWhereInput>;
  none?: InputMaybe<AssistantWhereInput>;
  some?: InputMaybe<AssistantWhereInput>;
};

export type AssistantOrderByRelationAggregateInput = {
  _count?: InputMaybe<SortOrder>;
};

export type AssistantOrderByWithRelationInput = {
  access?: InputMaybe<SortOrder>;
  alert?: InputMaybe<SortOrder>;
  chatUpload?: InputMaybe<SortOrder>;
  createdAt?: InputMaybe<SortOrder>;
  defaultForCompanyId?: InputMaybe<SortOrder>;
  examples?: InputMaybe<SortOrder>;
  explanation?: InputMaybe<SortOrder>;
  fallbackModule?: InputMaybe<SortOrder>;
  goals?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  inputLimit?: InputMaybe<SortOrder>;
  inputPlaceholder?: InputMaybe<SortOrder>;
  isExternal?: InputMaybe<SortOrder>;
  languageModel?: InputMaybe<SortOrder>;
  modules?: InputMaybe<ModuleOrderByRelationAggregateInput>;
  name?: InputMaybe<SortOrder>;
  prompts?: InputMaybe<PromptOrderByRelationAggregateInput>;
  scopeRules?: InputMaybe<ScopeRuleOrderByRelationAggregateInput>;
  settings?: InputMaybe<SortOrder>;
  subtitle?: InputMaybe<SortOrder>;
  title?: InputMaybe<SortOrder>;
  updatedAt?: InputMaybe<SortOrder>;
  userPrompts?: InputMaybe<UserPromptOrderByRelationAggregateInput>;
};

export type AssistantRelationFilter = {
  is?: InputMaybe<AssistantWhereInput>;
  isNot?: InputMaybe<AssistantWhereInput>;
};

export enum AssistantScalarFieldEnum {
  Access = 'access',
  Alert = 'alert',
  ChatUpload = 'chatUpload',
  CompanyId = 'companyId',
  CreatedAt = 'createdAt',
  DefaultForCompanyId = 'defaultForCompanyId',
  Examples = 'examples',
  Explanation = 'explanation',
  FallbackModule = 'fallbackModule',
  Goals = 'goals',
  Id = 'id',
  InputLimit = 'inputLimit',
  InputPlaceholder = 'inputPlaceholder',
  IsExternal = 'isExternal',
  LanguageModel = 'languageModel',
  Name = 'name',
  Settings = 'settings',
  Subtitle = 'subtitle',
  Title = 'title',
  UpdatedAt = 'updatedAt'
}

export type AssistantScalarWhereInput = {
  AND?: InputMaybe<Array<AssistantScalarWhereInput>>;
  NOT?: InputMaybe<Array<AssistantScalarWhereInput>>;
  OR?: InputMaybe<Array<AssistantScalarWhereInput>>;
  access?: InputMaybe<StringNullableListFilter>;
  alert?: InputMaybe<StringNullableFilter>;
  chatUpload?: InputMaybe<EnumChatUploadFilter>;
  createdAt?: InputMaybe<DateTimeFilter>;
  defaultForCompanyId?: InputMaybe<StringNullableFilter>;
  examples?: InputMaybe<StringNullableListFilter>;
  explanation?: InputMaybe<StringNullableFilter>;
  fallbackModule?: InputMaybe<StringFilter>;
  goals?: InputMaybe<StringNullableListFilter>;
  id?: InputMaybe<StringFilter>;
  inputLimit?: InputMaybe<IntNullableFilter>;
  inputPlaceholder?: InputMaybe<StringNullableFilter>;
  isExternal?: InputMaybe<BoolFilter>;
  languageModel?: InputMaybe<StringNullableFilter>;
  name?: InputMaybe<StringFilter>;
  settings?: InputMaybe<JsonNullableFilter>;
  subtitle?: InputMaybe<StringNullableFilter>;
  title?: InputMaybe<StringNullableFilter>;
  updatedAt?: InputMaybe<DateTimeFilter>;
};

export type AssistantUpdateInput = {
  access?: InputMaybe<Array<Scalars['String']>>;
  alert?: InputMaybe<Scalars['String']>;
  chatUpload?: InputMaybe<ChatUpload>;
  createdAt?: InputMaybe<Scalars['DateTime']>;
  examples?: InputMaybe<Array<Scalars['String']>>;
  explanation?: InputMaybe<Scalars['String']>;
  fallbackModule?: InputMaybe<Scalars['String']>;
  goals?: InputMaybe<Array<Scalars['String']>>;
  id?: InputMaybe<Scalars['String']>;
  inputLimit?: InputMaybe<Scalars['Int']>;
  inputPlaceholder?: InputMaybe<Scalars['String']>;
  isExternal?: InputMaybe<Scalars['Boolean']>;
  languageModel?: InputMaybe<Scalars['String']>;
  modules?: InputMaybe<ModuleUpdateManyWithoutAssistantNestedInput>;
  name?: InputMaybe<Scalars['String']>;
  prompts?: InputMaybe<PromptUpdateManyWithoutAssistantNestedInput>;
  scopeRules?: InputMaybe<ScopeRuleUpdateManyWithoutAssistantNestedInput>;
  settings?: InputMaybe<Scalars['JSON']>;
  subtitle?: InputMaybe<Scalars['String']>;
  title?: InputMaybe<Scalars['String']>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
  userPrompts?: InputMaybe<UserPromptUpdateManyWithoutAssistantNestedInput>;
};

export type AssistantUpdateManyMutationInput = {
  access?: InputMaybe<Array<Scalars['String']>>;
  alert?: InputMaybe<Scalars['String']>;
  chatUpload?: InputMaybe<ChatUpload>;
  createdAt?: InputMaybe<Scalars['DateTime']>;
  examples?: InputMaybe<Array<Scalars['String']>>;
  explanation?: InputMaybe<Scalars['String']>;
  fallbackModule?: InputMaybe<Scalars['String']>;
  goals?: InputMaybe<Array<Scalars['String']>>;
  id?: InputMaybe<Scalars['String']>;
  inputLimit?: InputMaybe<Scalars['Int']>;
  inputPlaceholder?: InputMaybe<Scalars['String']>;
  isExternal?: InputMaybe<Scalars['Boolean']>;
  languageModel?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
  settings?: InputMaybe<Scalars['JSON']>;
  subtitle?: InputMaybe<Scalars['String']>;
  title?: InputMaybe<Scalars['String']>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
};

export type AssistantUpdateManyWithWhereWithoutCompanyInput = {
  data: AssistantUpdateManyMutationInput;
  where: AssistantScalarWhereInput;
};

export type AssistantUpdateManyWithoutCompanyNestedInput = {
  connect?: InputMaybe<Array<AssistantWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<AssistantCreateOrConnectWithoutCompanyInput>>;
  create?: InputMaybe<Array<AssistantCreateWithoutCompanyInput>>;
  createMany?: InputMaybe<AssistantCreateManyCompanyInputEnvelope>;
  delete?: InputMaybe<Array<AssistantWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<AssistantScalarWhereInput>>;
  disconnect?: InputMaybe<Array<AssistantWhereUniqueInput>>;
  set?: InputMaybe<Array<AssistantWhereUniqueInput>>;
  update?: InputMaybe<Array<AssistantUpdateWithWhereUniqueWithoutCompanyInput>>;
  updateMany?: InputMaybe<Array<AssistantUpdateManyWithWhereWithoutCompanyInput>>;
  upsert?: InputMaybe<Array<AssistantUpsertWithWhereUniqueWithoutCompanyInput>>;
};

export type AssistantUpdateOneWithoutDefaultForCompanyNestedInput = {
  connect?: InputMaybe<AssistantWhereUniqueInput>;
  connectOrCreate?: InputMaybe<AssistantCreateOrConnectWithoutDefaultForCompanyInput>;
  create?: InputMaybe<AssistantCreateWithoutDefaultForCompanyInput>;
  delete?: InputMaybe<Scalars['Boolean']>;
  disconnect?: InputMaybe<Scalars['Boolean']>;
  update?: InputMaybe<AssistantUpdateWithoutDefaultForCompanyInput>;
  upsert?: InputMaybe<AssistantUpsertWithoutDefaultForCompanyInput>;
};

export type AssistantUpdateWithWhereUniqueWithoutCompanyInput = {
  data: AssistantUpdateWithoutCompanyInput;
  where: AssistantWhereUniqueInput;
};

export type AssistantUpdateWithoutCompanyInput = {
  access?: InputMaybe<Array<Scalars['String']>>;
  alert?: InputMaybe<Scalars['String']>;
  chatUpload?: InputMaybe<ChatUpload>;
  createdAt?: InputMaybe<Scalars['DateTime']>;
  examples?: InputMaybe<Array<Scalars['String']>>;
  explanation?: InputMaybe<Scalars['String']>;
  fallbackModule?: InputMaybe<Scalars['String']>;
  goals?: InputMaybe<Array<Scalars['String']>>;
  id?: InputMaybe<Scalars['String']>;
  inputLimit?: InputMaybe<Scalars['Int']>;
  inputPlaceholder?: InputMaybe<Scalars['String']>;
  isExternal?: InputMaybe<Scalars['Boolean']>;
  languageModel?: InputMaybe<Scalars['String']>;
  modules?: InputMaybe<ModuleUpdateManyWithoutAssistantNestedInput>;
  name?: InputMaybe<Scalars['String']>;
  prompts?: InputMaybe<PromptUpdateManyWithoutAssistantNestedInput>;
  scopeRules?: InputMaybe<ScopeRuleUpdateManyWithoutAssistantNestedInput>;
  settings?: InputMaybe<Scalars['JSON']>;
  subtitle?: InputMaybe<Scalars['String']>;
  title?: InputMaybe<Scalars['String']>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
  userPrompts?: InputMaybe<UserPromptUpdateManyWithoutAssistantNestedInput>;
};

export type AssistantUpdateWithoutDefaultForCompanyInput = {
  access?: InputMaybe<Array<Scalars['String']>>;
  alert?: InputMaybe<Scalars['String']>;
  chatUpload?: InputMaybe<ChatUpload>;
  createdAt?: InputMaybe<Scalars['DateTime']>;
  examples?: InputMaybe<Array<Scalars['String']>>;
  explanation?: InputMaybe<Scalars['String']>;
  fallbackModule?: InputMaybe<Scalars['String']>;
  goals?: InputMaybe<Array<Scalars['String']>>;
  id?: InputMaybe<Scalars['String']>;
  inputLimit?: InputMaybe<Scalars['Int']>;
  inputPlaceholder?: InputMaybe<Scalars['String']>;
  isExternal?: InputMaybe<Scalars['Boolean']>;
  languageModel?: InputMaybe<Scalars['String']>;
  modules?: InputMaybe<ModuleUpdateManyWithoutAssistantNestedInput>;
  name?: InputMaybe<Scalars['String']>;
  prompts?: InputMaybe<PromptUpdateManyWithoutAssistantNestedInput>;
  scopeRules?: InputMaybe<ScopeRuleUpdateManyWithoutAssistantNestedInput>;
  settings?: InputMaybe<Scalars['JSON']>;
  subtitle?: InputMaybe<Scalars['String']>;
  title?: InputMaybe<Scalars['String']>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
  userPrompts?: InputMaybe<UserPromptUpdateManyWithoutAssistantNestedInput>;
};

export type AssistantUpsertWithWhereUniqueWithoutCompanyInput = {
  create: AssistantCreateWithoutCompanyInput;
  update: AssistantUpdateWithoutCompanyInput;
  where: AssistantWhereUniqueInput;
};

export type AssistantUpsertWithoutDefaultForCompanyInput = {
  create: AssistantCreateWithoutDefaultForCompanyInput;
  update: AssistantUpdateWithoutDefaultForCompanyInput;
};

export type AssistantWhereInput = {
  AND?: InputMaybe<Array<AssistantWhereInput>>;
  NOT?: InputMaybe<Array<AssistantWhereInput>>;
  OR?: InputMaybe<Array<AssistantWhereInput>>;
  access?: InputMaybe<StringNullableListFilter>;
  alert?: InputMaybe<StringNullableFilter>;
  chatUpload?: InputMaybe<EnumChatUploadFilter>;
  createdAt?: InputMaybe<DateTimeFilter>;
  defaultForCompanyId?: InputMaybe<StringNullableFilter>;
  examples?: InputMaybe<StringNullableListFilter>;
  explanation?: InputMaybe<StringNullableFilter>;
  fallbackModule?: InputMaybe<StringFilter>;
  goals?: InputMaybe<StringNullableListFilter>;
  id?: InputMaybe<StringFilter>;
  inputLimit?: InputMaybe<IntNullableFilter>;
  inputPlaceholder?: InputMaybe<StringNullableFilter>;
  isExternal?: InputMaybe<BoolFilter>;
  languageModel?: InputMaybe<StringNullableFilter>;
  modules?: InputMaybe<ModuleListRelationFilter>;
  name?: InputMaybe<StringFilter>;
  prompts?: InputMaybe<PromptListRelationFilter>;
  scopeRules?: InputMaybe<ScopeRuleListRelationFilter>;
  settings?: InputMaybe<JsonNullableFilter>;
  subtitle?: InputMaybe<StringNullableFilter>;
  title?: InputMaybe<StringNullableFilter>;
  updatedAt?: InputMaybe<DateTimeFilter>;
  userPrompts?: InputMaybe<UserPromptListRelationFilter>;
};

export type AssistantWhereUniqueInput = {
  defaultForCompanyId?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['String']>;
};

export type Benchmark = {
  __typename?: 'Benchmark';
  BenchmarkEntry?: Maybe<Array<BenchmarkEntry>>;
  _count: BenchmarkCount;
  abortedAt?: Maybe<Scalars['DateTime']>;
  benchmarkContext?: Maybe<Scalars['JSON']>;
  company: Company;
  companyId: Scalars['String'];
  createdAt: Scalars['DateTime'];
  id: Scalars['ID'];
  input: Scalars['JSON'];
  lastHeartbeatAt?: Maybe<Scalars['DateTime']>;
  output: Scalars['JSON'];
  status: BenchmarkStatus;
  updatedAt: Scalars['DateTime'];
};

export type BenchmarkCount = {
  __typename?: 'BenchmarkCount';
  BenchmarkEntry: Scalars['Int'];
};

export type BenchmarkCreateManyCompanyInput = {
  abortedAt?: InputMaybe<Scalars['DateTime']>;
  id?: InputMaybe<Scalars['String']>;
  input: Scalars['JSON'];
  lastHeartbeatAt?: InputMaybe<Scalars['DateTime']>;
  output: Scalars['JSON'];
  status?: InputMaybe<BenchmarkStatus>;
};

export type BenchmarkCreateManyCompanyInputEnvelope = {
  data: Array<BenchmarkCreateManyCompanyInput>;
  skipDuplicates?: InputMaybe<Scalars['Boolean']>;
};

export type BenchmarkCreateNestedManyWithoutCompanyInput = {
  connect?: InputMaybe<Array<BenchmarkWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<BenchmarkCreateOrConnectWithoutCompanyInput>>;
  create?: InputMaybe<Array<BenchmarkCreateWithoutCompanyInput>>;
  createMany?: InputMaybe<BenchmarkCreateManyCompanyInputEnvelope>;
};

export type BenchmarkCreateOrConnectWithoutCompanyInput = {
  create: BenchmarkCreateWithoutCompanyInput;
  where: BenchmarkWhereUniqueInput;
};

export type BenchmarkCreateWithoutCompanyInput = {
  abortedAt?: InputMaybe<Scalars['DateTime']>;
  id?: InputMaybe<Scalars['String']>;
  input: Scalars['JSON'];
  lastHeartbeatAt?: InputMaybe<Scalars['DateTime']>;
  output: Scalars['JSON'];
  status?: InputMaybe<BenchmarkStatus>;
};

export type BenchmarkEntry = {
  __typename?: 'BenchmarkEntry';
  benchmarkId: Scalars['String'];
  companyId: Scalars['String'];
  createdAt: Scalars['DateTime'];
  id: Scalars['ID'];
  requeued: Scalars['Int'];
  status: BenchmarkEntryStatus;
  updatedAt: Scalars['DateTime'];
};

export enum BenchmarkEntryStatus {
  Done = 'DONE',
  Error = 'ERROR',
  Queued = 'QUEUED',
  ReQueued = 'RE_QUEUED',
  Running = 'RUNNING'
}

export type BenchmarkListRelationFilter = {
  every?: InputMaybe<BenchmarkWhereInput>;
  none?: InputMaybe<BenchmarkWhereInput>;
  some?: InputMaybe<BenchmarkWhereInput>;
};

export type BenchmarkOrderByRelationAggregateInput = {
  _count?: InputMaybe<SortOrder>;
};

export type BenchmarkScalarWhereInput = {
  AND?: InputMaybe<Array<BenchmarkScalarWhereInput>>;
  NOT?: InputMaybe<Array<BenchmarkScalarWhereInput>>;
  OR?: InputMaybe<Array<BenchmarkScalarWhereInput>>;
  abortedAt?: InputMaybe<DateTimeNullableFilter>;
  companyId?: InputMaybe<StringFilter>;
  id?: InputMaybe<StringFilter>;
  input?: InputMaybe<JsonFilter>;
  lastHeartbeatAt?: InputMaybe<DateTimeNullableFilter>;
  output?: InputMaybe<JsonFilter>;
  status?: InputMaybe<EnumBenchmarkStatusFilter>;
};

export enum BenchmarkStatus {
  Done = 'DONE',
  Error = 'ERROR',
  NotAvailable = 'NOT_AVAILABLE',
  Pending = 'PENDING',
  Running = 'RUNNING'
}

export type BenchmarkStatusResponse = {
  __typename?: 'BenchmarkStatusResponse';
  benchmarkId?: Maybe<Scalars['String']>;
  done: Scalars['Float'];
  error: Scalars['Float'];
  filename: Scalars['String'];
  status: Scalars['String'];
  total: Scalars['Float'];
};

export type BenchmarkUpdateManyMutationInput = {
  abortedAt?: InputMaybe<Scalars['DateTime']>;
  id?: InputMaybe<Scalars['String']>;
  input?: InputMaybe<Scalars['JSON']>;
  lastHeartbeatAt?: InputMaybe<Scalars['DateTime']>;
  output?: InputMaybe<Scalars['JSON']>;
  status?: InputMaybe<BenchmarkStatus>;
};

export type BenchmarkUpdateManyWithWhereWithoutCompanyInput = {
  data: BenchmarkUpdateManyMutationInput;
  where: BenchmarkScalarWhereInput;
};

export type BenchmarkUpdateManyWithoutCompanyNestedInput = {
  connect?: InputMaybe<Array<BenchmarkWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<BenchmarkCreateOrConnectWithoutCompanyInput>>;
  create?: InputMaybe<Array<BenchmarkCreateWithoutCompanyInput>>;
  createMany?: InputMaybe<BenchmarkCreateManyCompanyInputEnvelope>;
  delete?: InputMaybe<Array<BenchmarkWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<BenchmarkScalarWhereInput>>;
  disconnect?: InputMaybe<Array<BenchmarkWhereUniqueInput>>;
  set?: InputMaybe<Array<BenchmarkWhereUniqueInput>>;
  update?: InputMaybe<Array<BenchmarkUpdateWithWhereUniqueWithoutCompanyInput>>;
  updateMany?: InputMaybe<Array<BenchmarkUpdateManyWithWhereWithoutCompanyInput>>;
  upsert?: InputMaybe<Array<BenchmarkUpsertWithWhereUniqueWithoutCompanyInput>>;
};

export type BenchmarkUpdateWithWhereUniqueWithoutCompanyInput = {
  data: BenchmarkUpdateWithoutCompanyInput;
  where: BenchmarkWhereUniqueInput;
};

export type BenchmarkUpdateWithoutCompanyInput = {
  abortedAt?: InputMaybe<Scalars['DateTime']>;
  id?: InputMaybe<Scalars['String']>;
  input?: InputMaybe<Scalars['JSON']>;
  lastHeartbeatAt?: InputMaybe<Scalars['DateTime']>;
  output?: InputMaybe<Scalars['JSON']>;
  status?: InputMaybe<BenchmarkStatus>;
};

export type BenchmarkUpsertWithWhereUniqueWithoutCompanyInput = {
  create: BenchmarkCreateWithoutCompanyInput;
  update: BenchmarkUpdateWithoutCompanyInput;
  where: BenchmarkWhereUniqueInput;
};

export type BenchmarkWhereInput = {
  AND?: InputMaybe<Array<BenchmarkWhereInput>>;
  NOT?: InputMaybe<Array<BenchmarkWhereInput>>;
  OR?: InputMaybe<Array<BenchmarkWhereInput>>;
  abortedAt?: InputMaybe<DateTimeNullableFilter>;
  company?: InputMaybe<CompanyRelationFilter>;
  companyId?: InputMaybe<StringFilter>;
  id?: InputMaybe<StringFilter>;
  input?: InputMaybe<JsonFilter>;
  lastHeartbeatAt?: InputMaybe<DateTimeNullableFilter>;
  output?: InputMaybe<JsonFilter>;
  status?: InputMaybe<EnumBenchmarkStatusFilter>;
};

export type BenchmarkWhereUniqueInput = {
  id?: InputMaybe<Scalars['String']>;
};

export type BoolFilter = {
  equals?: InputMaybe<Scalars['Boolean']>;
  not?: InputMaybe<NestedBoolFilter>;
};

export type Chat = {
  __typename?: 'Chat';
  MagicTableSheet?: Maybe<Array<MagicTableSheet>>;
  ShortTermMemory?: Maybe<Array<ShortTermMemory>>;
  _count: ChatCount;
  assistant?: Maybe<Assistant>;
  assistantId?: Maybe<Scalars['String']>;
  benchmarkEntries?: Maybe<Array<BenchmarkEntry>>;
  companyId: Scalars['String'];
  createdAt: Scalars['DateTime'];
  deleted: Scalars['Boolean'];
  id: Scalars['ID'];
  messages?: Maybe<Array<Message>>;
  title?: Maybe<Scalars['String']>;
  updatedAt: Scalars['DateTime'];
  userId: Scalars['String'];
};

export type ChatCount = {
  __typename?: 'ChatCount';
  MagicTableSheet: Scalars['Int'];
  ShortTermMemory: Scalars['Int'];
  benchmarkEntries: Scalars['Int'];
  messages: Scalars['Int'];
};

export type ChatOrderByWithRelationInput = {
  MagicTableSheet?: InputMaybe<MagicTableSheetOrderByRelationAggregateInput>;
  assistant?: InputMaybe<AssistantOrderByWithRelationInput>;
  companyId?: InputMaybe<SortOrder>;
  createdAt?: InputMaybe<SortOrder>;
  deleted?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  messages?: InputMaybe<MessageOrderByRelationAggregateInput>;
  title?: InputMaybe<SortOrder>;
  updatedAt?: InputMaybe<SortOrder>;
  userId?: InputMaybe<SortOrder>;
};

export type ChatRelationFilter = {
  is?: InputMaybe<ChatWhereInput>;
  isNot?: InputMaybe<ChatWhereInput>;
};

export enum ChatScalarFieldEnum {
  AssistantId = 'assistantId',
  CompanyId = 'companyId',
  CreatedAt = 'createdAt',
  Deleted = 'deleted',
  Id = 'id',
  Title = 'title',
  UpdatedAt = 'updatedAt',
  UserId = 'userId'
}

export enum ChatUpload {
  Disabled = 'DISABLED',
  Enabled = 'ENABLED'
}

export type ChatWhereInput = {
  AND?: InputMaybe<Array<ChatWhereInput>>;
  MagicTableSheet?: InputMaybe<MagicTableSheetListRelationFilter>;
  NOT?: InputMaybe<Array<ChatWhereInput>>;
  OR?: InputMaybe<Array<ChatWhereInput>>;
  assistant?: InputMaybe<AssistantRelationFilter>;
  companyId?: InputMaybe<StringFilter>;
  createdAt?: InputMaybe<DateTimeFilter>;
  deleted?: InputMaybe<BoolFilter>;
  id?: InputMaybe<StringFilter>;
  messages?: InputMaybe<MessageListRelationFilter>;
  title?: InputMaybe<StringNullableFilter>;
  updatedAt?: InputMaybe<DateTimeFilter>;
  userId?: InputMaybe<StringFilter>;
};

export type ChatWhereUniqueInput = {
  id?: InputMaybe<Scalars['String']>;
};

export type Chunk = {
  __typename?: 'Chunk';
  /** @DtoReadOnly */
  contentId: Scalars['String'];
  /** @DtoReadOnly */
  createdAt: Scalars['DateTime'];
  embedding?: Maybe<Scalars['JSON']>;
  embeddingsFirst10: Array<Scalars['Float']>;
  endPage?: Maybe<Scalars['Int']>;
  id: Scalars['ID'];
  model?: Maybe<Scalars['String']>;
  order?: Maybe<Scalars['Int']>;
  startPage?: Maybe<Scalars['Int']>;
  /** @DtoReadOnly */
  text: Scalars['String'];
  /** @DtoReadOnly */
  updatedAt: Scalars['DateTime'];
  /**
   * @DtoReadOnly
   * @DtoEntityHidden
   */
  vectorId: Scalars['String'];
};

export type ChunkCreateInput = {
  createdAt?: InputMaybe<Scalars['DateTime']>;
  embedding?: InputMaybe<Scalars['JSON']>;
  endPage?: InputMaybe<Scalars['Int']>;
  fingerprint: Scalars['String'];
  id?: InputMaybe<Scalars['String']>;
  model?: InputMaybe<Scalars['String']>;
  order?: InputMaybe<Scalars['Int']>;
  startPage?: InputMaybe<Scalars['Int']>;
  text: Scalars['String'];
  updatedAt?: InputMaybe<Scalars['DateTime']>;
};

export type ChunkOrderByWithRelationInput = {
  contentId?: InputMaybe<SortOrder>;
  createdAt?: InputMaybe<SortOrder>;
  embedding?: InputMaybe<SortOrder>;
  endPage?: InputMaybe<SortOrder>;
  fingerprint?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  model?: InputMaybe<SortOrder>;
  order?: InputMaybe<SortOrder>;
  startPage?: InputMaybe<SortOrder>;
  text?: InputMaybe<SortOrder>;
  updatedAt?: InputMaybe<SortOrder>;
};

export enum ChunkScalarFieldEnum {
  ContentId = 'contentId',
  CreatedAt = 'createdAt',
  Embedding = 'embedding',
  EndPage = 'endPage',
  Fingerprint = 'fingerprint',
  Id = 'id',
  Model = 'model',
  Order = 'order',
  StartPage = 'startPage',
  Text = 'text',
  UpdatedAt = 'updatedAt',
  VectorId = 'vectorId'
}

export type ChunkUpdateInput = {
  createdAt?: InputMaybe<Scalars['DateTime']>;
  embedding?: InputMaybe<Scalars['JSON']>;
  endPage?: InputMaybe<Scalars['Int']>;
  fingerprint?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['String']>;
  model?: InputMaybe<Scalars['String']>;
  order?: InputMaybe<Scalars['Int']>;
  startPage?: InputMaybe<Scalars['Int']>;
  text?: InputMaybe<Scalars['String']>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
};

export type ChunkWhereInput = {
  AND?: InputMaybe<Array<ChunkWhereInput>>;
  NOT?: InputMaybe<Array<ChunkWhereInput>>;
  OR?: InputMaybe<Array<ChunkWhereInput>>;
  contentId?: InputMaybe<StringFilter>;
  createdAt?: InputMaybe<DateTimeFilter>;
  embedding?: InputMaybe<JsonNullableFilter>;
  endPage?: InputMaybe<IntNullableFilter>;
  fingerprint?: InputMaybe<StringFilter>;
  id?: InputMaybe<StringFilter>;
  model?: InputMaybe<StringNullableFilter>;
  order?: InputMaybe<IntNullableFilter>;
  startPage?: InputMaybe<IntNullableFilter>;
  text?: InputMaybe<StringFilter>;
  updatedAt?: InputMaybe<DateTimeFilter>;
};

export type ChunkWhereUniqueInput = {
  id?: InputMaybe<Scalars['String']>;
};

export type Color = {
  __typename?: 'Color';
  createdAt?: Maybe<Scalars['DateTime']>;
  hexValue: Scalars['String'];
  id: Scalars['ID'];
  name: Scalars['String'];
  theme?: Maybe<Theme>;
  themeId?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type ColorCreateManyThemeInput = {
  createdAt?: InputMaybe<Scalars['DateTime']>;
  hexValue: Scalars['String'];
  id?: InputMaybe<Scalars['String']>;
  name: Scalars['String'];
  updatedAt?: InputMaybe<Scalars['DateTime']>;
};

export type ColorCreateManyThemeInputEnvelope = {
  data: Array<ColorCreateManyThemeInput>;
  skipDuplicates?: InputMaybe<Scalars['Boolean']>;
};

export type ColorCreateNestedManyWithoutThemeInput = {
  connect?: InputMaybe<Array<ColorWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<ColorCreateOrConnectWithoutThemeInput>>;
  create?: InputMaybe<Array<ColorCreateWithoutThemeInput>>;
  createMany?: InputMaybe<ColorCreateManyThemeInputEnvelope>;
};

export type ColorCreateOrConnectWithoutThemeInput = {
  create: ColorCreateWithoutThemeInput;
  where: ColorWhereUniqueInput;
};

export type ColorCreateWithoutThemeInput = {
  createdAt?: InputMaybe<Scalars['DateTime']>;
  hexValue: Scalars['String'];
  id?: InputMaybe<Scalars['String']>;
  name: Scalars['String'];
  updatedAt?: InputMaybe<Scalars['DateTime']>;
};

export type ColorScalarWhereInput = {
  AND?: InputMaybe<Array<ColorScalarWhereInput>>;
  NOT?: InputMaybe<Array<ColorScalarWhereInput>>;
  OR?: InputMaybe<Array<ColorScalarWhereInput>>;
  createdAt?: InputMaybe<DateTimeNullableFilter>;
  hexValue?: InputMaybe<StringFilter>;
  id?: InputMaybe<StringFilter>;
  name?: InputMaybe<StringFilter>;
  themeId?: InputMaybe<StringNullableFilter>;
  updatedAt?: InputMaybe<DateTimeNullableFilter>;
};

export type ColorUpdateManyMutationInput = {
  createdAt?: InputMaybe<Scalars['DateTime']>;
  hexValue?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
};

export type ColorUpdateManyWithWhereWithoutThemeInput = {
  data: ColorUpdateManyMutationInput;
  where: ColorScalarWhereInput;
};

export type ColorUpdateManyWithoutThemeNestedInput = {
  connect?: InputMaybe<Array<ColorWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<ColorCreateOrConnectWithoutThemeInput>>;
  create?: InputMaybe<Array<ColorCreateWithoutThemeInput>>;
  createMany?: InputMaybe<ColorCreateManyThemeInputEnvelope>;
  delete?: InputMaybe<Array<ColorWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<ColorScalarWhereInput>>;
  disconnect?: InputMaybe<Array<ColorWhereUniqueInput>>;
  set?: InputMaybe<Array<ColorWhereUniqueInput>>;
  update?: InputMaybe<Array<ColorUpdateWithWhereUniqueWithoutThemeInput>>;
  updateMany?: InputMaybe<Array<ColorUpdateManyWithWhereWithoutThemeInput>>;
  upsert?: InputMaybe<Array<ColorUpsertWithWhereUniqueWithoutThemeInput>>;
};

export type ColorUpdateWithWhereUniqueWithoutThemeInput = {
  data: ColorUpdateWithoutThemeInput;
  where: ColorWhereUniqueInput;
};

export type ColorUpdateWithoutThemeInput = {
  createdAt?: InputMaybe<Scalars['DateTime']>;
  hexValue?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
};

export type ColorUpsertWithWhereUniqueWithoutThemeInput = {
  create: ColorCreateWithoutThemeInput;
  update: ColorUpdateWithoutThemeInput;
  where: ColorWhereUniqueInput;
};

export type ColorWhereUniqueInput = {
  id?: InputMaybe<Scalars['String']>;
};

export type Company = {
  __typename?: 'Company';
  Benchmark?: Maybe<Array<Benchmark>>;
  ModuleTemplate?: Maybe<Array<ModuleTemplate>>;
  TermsAndConditions?: Maybe<Array<TermsAndConditions>>;
  _count: CompanyCount;
  acronyms?: Maybe<Scalars['JSON']>;
  assistants?: Maybe<Array<Assistant>>;
  configuration: Scalars['JSON'];
  createdAt: Scalars['DateTime'];
  defaultAssistant?: Maybe<Assistant>;
  groupConfiguration: Scalars['JSON'];
  id: Scalars['ID'];
  name: Scalars['String'];
  theme?: Maybe<Theme>;
  themeId?: Maybe<Scalars['String']>;
  updatedAt: Scalars['DateTime'];
  users?: Maybe<Array<User>>;
};

export type CompanyCount = {
  __typename?: 'CompanyCount';
  Benchmark: Scalars['Int'];
  ModuleTemplate: Scalars['Int'];
  TermsAndConditions: Scalars['Int'];
  assistants: Scalars['Int'];
  users: Scalars['Int'];
};

export type CompanyCreateManyThemeInput = {
  createdAt?: InputMaybe<Scalars['DateTime']>;
  id?: InputMaybe<Scalars['String']>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
};

export type CompanyCreateManyThemeInputEnvelope = {
  data: Array<CompanyCreateManyThemeInput>;
  skipDuplicates?: InputMaybe<Scalars['Boolean']>;
};

export type CompanyCreateNestedManyWithoutThemeInput = {
  connect?: InputMaybe<Array<CompanyWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<CompanyCreateOrConnectWithoutThemeInput>>;
  create?: InputMaybe<Array<CompanyCreateWithoutThemeInput>>;
  createMany?: InputMaybe<CompanyCreateManyThemeInputEnvelope>;
};

export type CompanyCreateNestedOneWithoutModuleTemplateInput = {
  connect?: InputMaybe<CompanyWhereUniqueInput>;
  connectOrCreate?: InputMaybe<CompanyCreateOrConnectWithoutModuleTemplateInput>;
  create?: InputMaybe<CompanyCreateWithoutModuleTemplateInput>;
};

export type CompanyCreateNestedOneWithoutUsersInput = {
  connect?: InputMaybe<CompanyWhereUniqueInput>;
  connectOrCreate?: InputMaybe<CompanyCreateOrConnectWithoutUsersInput>;
  create?: InputMaybe<CompanyCreateWithoutUsersInput>;
};

export type CompanyCreateOrConnectWithoutModuleTemplateInput = {
  create: CompanyCreateWithoutModuleTemplateInput;
  where: CompanyWhereUniqueInput;
};

export type CompanyCreateOrConnectWithoutThemeInput = {
  create: CompanyCreateWithoutThemeInput;
  where: CompanyWhereUniqueInput;
};

export type CompanyCreateOrConnectWithoutUsersInput = {
  create: CompanyCreateWithoutUsersInput;
  where: CompanyWhereUniqueInput;
};

export type CompanyCreateWithoutModuleTemplateInput = {
  Benchmark?: InputMaybe<BenchmarkCreateNestedManyWithoutCompanyInput>;
  TermsAndConditions?: InputMaybe<TermsAndConditionsCreateNestedManyWithoutCompanyInput>;
  acronyms?: InputMaybe<Scalars['JSON']>;
  assistants?: InputMaybe<AssistantCreateNestedManyWithoutCompanyInput>;
  configuration?: InputMaybe<Scalars['JSON']>;
  defaultAssistant?: InputMaybe<AssistantCreateNestedOneWithoutDefaultForCompanyInput>;
  id: Scalars['String'];
};

export type CompanyCreateWithoutThemeInput = {
  createdAt?: InputMaybe<Scalars['DateTime']>;
  id?: InputMaybe<Scalars['String']>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
  users?: InputMaybe<UserCreateNestedManyWithoutCompanyInput>;
};

export type CompanyCreateWithoutUsersInput = {
  createdAt?: InputMaybe<Scalars['DateTime']>;
  id?: InputMaybe<Scalars['String']>;
  theme?: InputMaybe<ThemeCreateNestedOneWithoutCompanyInput>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
};

export type CompanyMeta = {
  __typename?: 'CompanyMeta';
  collectionName: Scalars['String'];
  companyId: Scalars['ID'];
  embeddingModel?: Maybe<Scalars['String']>;
};

export type CompanyOrderByWithRelationInput = {
  Benchmark?: InputMaybe<BenchmarkOrderByRelationAggregateInput>;
  ModuleTemplate?: InputMaybe<ModuleTemplateOrderByRelationAggregateInput>;
  TermsAndConditions?: InputMaybe<TermsAndConditionsOrderByRelationAggregateInput>;
  acronyms?: InputMaybe<SortOrder>;
  assistants?: InputMaybe<AssistantOrderByRelationAggregateInput>;
  configuration?: InputMaybe<SortOrder>;
  createdAt?: InputMaybe<SortOrder>;
  defaultAssistant?: InputMaybe<AssistantOrderByWithRelationInput>;
  groupConfiguration?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  name?: InputMaybe<SortOrder>;
  updatedAt?: InputMaybe<SortOrder>;
  users?: InputMaybe<UserOrderByRelationAggregateInput>;
};

export type CompanyRelationFilter = {
  is?: InputMaybe<CompanyWhereInput>;
  isNot?: InputMaybe<CompanyWhereInput>;
};

export type CompanyScalarWhereInput = {
  AND?: InputMaybe<Array<CompanyScalarWhereInput>>;
  NOT?: InputMaybe<Array<CompanyScalarWhereInput>>;
  OR?: InputMaybe<Array<CompanyScalarWhereInput>>;
  createdAt?: InputMaybe<DateTimeNullableFilter>;
  id?: InputMaybe<StringFilter>;
  themeId?: InputMaybe<StringNullableFilter>;
  updatedAt?: InputMaybe<DateTimeNullableFilter>;
};

export type CompanyUpdateInput = {
  Benchmark?: InputMaybe<BenchmarkUpdateManyWithoutCompanyNestedInput>;
  ModuleTemplate?: InputMaybe<ModuleTemplateUpdateManyWithoutCompanyNestedInput>;
  TermsAndConditions?: InputMaybe<TermsAndConditionsUpdateManyWithoutCompanyNestedInput>;
  acronyms?: InputMaybe<Scalars['JSON']>;
  assistants?: InputMaybe<AssistantUpdateManyWithoutCompanyNestedInput>;
  configuration?: InputMaybe<Scalars['JSON']>;
  defaultAssistant?: InputMaybe<AssistantUpdateOneWithoutDefaultForCompanyNestedInput>;
  id?: InputMaybe<Scalars['String']>;
};

export type CompanyUpdateManyMutationInput = {
  createdAt?: InputMaybe<Scalars['DateTime']>;
  id?: InputMaybe<Scalars['String']>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
};

export type CompanyUpdateManyWithWhereWithoutThemeInput = {
  data: CompanyUpdateManyMutationInput;
  where: CompanyScalarWhereInput;
};

export type CompanyUpdateManyWithoutThemeNestedInput = {
  connect?: InputMaybe<Array<CompanyWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<CompanyCreateOrConnectWithoutThemeInput>>;
  create?: InputMaybe<Array<CompanyCreateWithoutThemeInput>>;
  createMany?: InputMaybe<CompanyCreateManyThemeInputEnvelope>;
  delete?: InputMaybe<Array<CompanyWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<CompanyScalarWhereInput>>;
  disconnect?: InputMaybe<Array<CompanyWhereUniqueInput>>;
  set?: InputMaybe<Array<CompanyWhereUniqueInput>>;
  update?: InputMaybe<Array<CompanyUpdateWithWhereUniqueWithoutThemeInput>>;
  updateMany?: InputMaybe<Array<CompanyUpdateManyWithWhereWithoutThemeInput>>;
  upsert?: InputMaybe<Array<CompanyUpsertWithWhereUniqueWithoutThemeInput>>;
};

export type CompanyUpdateOneRequiredWithoutModuleTemplateNestedInput = {
  connect?: InputMaybe<CompanyWhereUniqueInput>;
  connectOrCreate?: InputMaybe<CompanyCreateOrConnectWithoutModuleTemplateInput>;
  create?: InputMaybe<CompanyCreateWithoutModuleTemplateInput>;
  update?: InputMaybe<CompanyUpdateWithoutModuleTemplateInput>;
  upsert?: InputMaybe<CompanyUpsertWithoutModuleTemplateInput>;
};

export type CompanyUpdateOneRequiredWithoutUsersNestedInput = {
  connect?: InputMaybe<CompanyWhereUniqueInput>;
  connectOrCreate?: InputMaybe<CompanyCreateOrConnectWithoutUsersInput>;
  create?: InputMaybe<CompanyCreateWithoutUsersInput>;
  update?: InputMaybe<CompanyUpdateWithoutUsersInput>;
  upsert?: InputMaybe<CompanyUpsertWithoutUsersInput>;
};

export type CompanyUpdateWithWhereUniqueWithoutThemeInput = {
  data: CompanyUpdateWithoutThemeInput;
  where: CompanyWhereUniqueInput;
};

export type CompanyUpdateWithoutModuleTemplateInput = {
  Benchmark?: InputMaybe<BenchmarkUpdateManyWithoutCompanyNestedInput>;
  TermsAndConditions?: InputMaybe<TermsAndConditionsUpdateManyWithoutCompanyNestedInput>;
  acronyms?: InputMaybe<Scalars['JSON']>;
  assistants?: InputMaybe<AssistantUpdateManyWithoutCompanyNestedInput>;
  configuration?: InputMaybe<Scalars['JSON']>;
  defaultAssistant?: InputMaybe<AssistantUpdateOneWithoutDefaultForCompanyNestedInput>;
  id?: InputMaybe<Scalars['String']>;
};

export type CompanyUpdateWithoutThemeInput = {
  createdAt?: InputMaybe<Scalars['DateTime']>;
  id?: InputMaybe<Scalars['String']>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
  users?: InputMaybe<UserUpdateManyWithoutCompanyNestedInput>;
};

export type CompanyUpdateWithoutUsersInput = {
  createdAt?: InputMaybe<Scalars['DateTime']>;
  id?: InputMaybe<Scalars['String']>;
  theme?: InputMaybe<ThemeUpdateOneWithoutCompanyNestedInput>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
};

export type CompanyUpsertWithWhereUniqueWithoutThemeInput = {
  create: CompanyCreateWithoutThemeInput;
  update: CompanyUpdateWithoutThemeInput;
  where: CompanyWhereUniqueInput;
};

export type CompanyUpsertWithoutModuleTemplateInput = {
  create: CompanyCreateWithoutModuleTemplateInput;
  update: CompanyUpdateWithoutModuleTemplateInput;
};

export type CompanyUpsertWithoutUsersInput = {
  create: CompanyCreateWithoutUsersInput;
  update: CompanyUpdateWithoutUsersInput;
};

export type CompanyWhereInput = {
  AND?: InputMaybe<Array<CompanyWhereInput>>;
  Benchmark?: InputMaybe<BenchmarkListRelationFilter>;
  ModuleTemplate?: InputMaybe<ModuleTemplateListRelationFilter>;
  NOT?: InputMaybe<Array<CompanyWhereInput>>;
  OR?: InputMaybe<Array<CompanyWhereInput>>;
  TermsAndConditions?: InputMaybe<TermsAndConditionsListRelationFilter>;
  acronyms?: InputMaybe<JsonNullableFilter>;
  assistants?: InputMaybe<AssistantListRelationFilter>;
  configuration?: InputMaybe<JsonFilter>;
  createdAt?: InputMaybe<DateTimeFilter>;
  defaultAssistant?: InputMaybe<AssistantRelationFilter>;
  groupConfiguration?: InputMaybe<JsonFilter>;
  id?: InputMaybe<StringFilter>;
  name?: InputMaybe<StringFilter>;
  updatedAt?: InputMaybe<DateTimeFilter>;
  users?: InputMaybe<UserListRelationFilter>;
};

export type CompanyWhereUniqueInput = {
  id?: InputMaybe<Scalars['String']>;
};

export type Content = {
  __typename?: 'Content';
  _count: ContentCount;
  appliedIngestionConfig?: Maybe<Scalars['JSON']>;
  byteSize: Scalars['Int'];
  /**
   * @DtoReadOnly
   * @DtoEntityHidden
   */
  chunks?: Maybe<Array<Chunk>>;
  /** @DtoReadOnly */
  createdAt: Scalars['DateTime'];
  /** @DtoReadOnly */
  deletedAt?: Maybe<Scalars['DateTime']>;
  /** @DtoReadOnly */
  expiredAt?: Maybe<Scalars['DateTime']>;
  /** @DtoReadOnly */
  expiresAt?: Maybe<Scalars['DateTime']>;
  expiresInDays?: Maybe<Scalars['Float']>;
  externalFileOwner?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  ingestionConfig?: Maybe<Scalars['JSON']>;
  ingestionProgress: Scalars['Int'];
  /** @DtoEntityHidden */
  ingestionState: IngestionState;
  /** @DtoEntityHidden */
  ingestionStateUpdatedAt?: Maybe<Scalars['DateTime']>;
  internallyStoredAt?: Maybe<Scalars['DateTime']>;
  key: Scalars['String'];
  /** @IsJSON */
  metadata?: Maybe<Scalars['JSON']>;
  mimeType: Scalars['String'];
  /** @DtoReadOnly */
  ownerId: Scalars['String'];
  ownerType: OwnerType;
  pdfPreviewWriteUrl: Scalars['String'];
  previewPdfFileName?: Maybe<Scalars['String']>;
  readUrl: Scalars['String'];
  sasUrl: Scalars['String'];
  /**
   * @DtoReadOnly
   * @DtoEntityHidden
   */
  source: Source;
  title?: Maybe<Scalars['String']>;
  /** @DtoReadOnly */
  updatedAt: Scalars['DateTime'];
  /** @IsUrl */
  url?: Maybe<Scalars['String']>;
  writeUrl: Scalars['String'];
};

export type ContentCount = {
  __typename?: 'ContentCount';
  chunks: Scalars['Int'];
};

export type ContentCreateInput = {
  byteSize?: InputMaybe<Scalars['Int']>;
  createdAt?: InputMaybe<Scalars['DateTime']>;
  deletedAt?: InputMaybe<Scalars['DateTime']>;
  expiredAt?: InputMaybe<Scalars['DateTime']>;
  expiresAt?: InputMaybe<Scalars['DateTime']>;
  externalFileOwner?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['String']>;
  ingestionConfig?: InputMaybe<Scalars['JSON']>;
  ingestionProgress?: InputMaybe<Scalars['Int']>;
  internallyStoredAt?: InputMaybe<Scalars['DateTime']>;
  key: Scalars['String'];
  metadata?: InputMaybe<Scalars['JSON']>;
  mimeType: Scalars['String'];
  ownerType: OwnerType;
  title?: InputMaybe<Scalars['String']>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
  url?: InputMaybe<Scalars['String']>;
};

export type ContentOrderByWithRelationInput = {
  byteSize?: InputMaybe<SortOrder>;
  createdAt?: InputMaybe<SortOrder>;
  deletedAt?: InputMaybe<SortOrder>;
  expiredAt?: InputMaybe<SortOrder>;
  expiresAt?: InputMaybe<SortOrder>;
  externalFileOwner?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  ingestionConfig?: InputMaybe<SortOrder>;
  ingestionProgress?: InputMaybe<SortOrder>;
  internallyStoredAt?: InputMaybe<SortOrder>;
  key?: InputMaybe<SortOrder>;
  metadata?: InputMaybe<SortOrder>;
  mimeType?: InputMaybe<SortOrder>;
  ownerType?: InputMaybe<SortOrder>;
  title?: InputMaybe<SortOrder>;
  updatedAt?: InputMaybe<SortOrder>;
  url?: InputMaybe<SortOrder>;
};

export enum ContentScalarFieldEnum {
  AppliedIngestionConfig = 'appliedIngestionConfig',
  ByteSize = 'byteSize',
  CollectionName = 'collectionName',
  CreatedAt = 'createdAt',
  DeletedAt = 'deletedAt',
  ExpiredAt = 'expiredAt',
  ExpiresAt = 'expiresAt',
  ExternalFileOwner = 'externalFileOwner',
  Id = 'id',
  IngestionConfig = 'ingestionConfig',
  IngestionProgress = 'ingestionProgress',
  IngestionState = 'ingestionState',
  IngestionStateUpdatedAt = 'ingestionStateUpdatedAt',
  InternallyStoredAt = 'internallyStoredAt',
  Key = 'key',
  Metadata = 'metadata',
  MimeType = 'mimeType',
  OwnerId = 'ownerId',
  OwnerType = 'ownerType',
  PreviewPdfFileName = 'previewPdfFileName',
  SourceId = 'sourceId',
  Title = 'title',
  UpdatedAt = 'updatedAt',
  Url = 'url',
  Version = 'version'
}

export type ContentUpdateInput = {
  byteSize?: InputMaybe<Scalars['Int']>;
  createdAt?: InputMaybe<Scalars['DateTime']>;
  deletedAt?: InputMaybe<Scalars['DateTime']>;
  expiredAt?: InputMaybe<Scalars['DateTime']>;
  expiresAt?: InputMaybe<Scalars['DateTime']>;
  externalFileOwner?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['String']>;
  ingestionConfig?: InputMaybe<Scalars['JSON']>;
  ingestionProgress?: InputMaybe<Scalars['Int']>;
  internallyStoredAt?: InputMaybe<Scalars['DateTime']>;
  key?: InputMaybe<Scalars['String']>;
  metadata?: InputMaybe<Scalars['JSON']>;
  mimeType?: InputMaybe<Scalars['String']>;
  ownerType?: InputMaybe<OwnerType>;
  title?: InputMaybe<Scalars['String']>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
  url?: InputMaybe<Scalars['String']>;
};

export type ContentWhereInput = {
  AND?: InputMaybe<Array<ContentWhereInput>>;
  NOT?: InputMaybe<Array<ContentWhereInput>>;
  OR?: InputMaybe<Array<ContentWhereInput>>;
  byteSize?: InputMaybe<IntFilter>;
  createdAt?: InputMaybe<DateTimeFilter>;
  deletedAt?: InputMaybe<DateTimeNullableFilter>;
  expiredAt?: InputMaybe<DateTimeNullableFilter>;
  expiresAt?: InputMaybe<DateTimeNullableFilter>;
  externalFileOwner?: InputMaybe<StringNullableFilter>;
  id?: InputMaybe<StringFilter>;
  ingestionConfig?: InputMaybe<JsonNullableFilter>;
  ingestionProgress?: InputMaybe<IntFilter>;
  ingestionState?: InputMaybe<EnumIngestionStateFilter>;
  ingestionStateUpdatedAt?: InputMaybe<DateTimeNullableFilter>;
  internallyStoredAt?: InputMaybe<DateTimeNullableFilter>;
  key?: InputMaybe<StringFilter>;
  metadata?: InputMaybe<JsonNullableFilter>;
  mimeType?: InputMaybe<StringFilter>;
  ownerId?: InputMaybe<StringFilter>;
  ownerType?: InputMaybe<EnumOwnerTypeFilter>;
  source?: InputMaybe<SourceRelationFilter>;
  title?: InputMaybe<StringNullableFilter>;
  updatedAt?: InputMaybe<DateTimeFilter>;
  url?: InputMaybe<StringNullableFilter>;
};

export type ContentWhereUniqueInput = {
  id?: InputMaybe<Scalars['String']>;
};

export type DateTimeFilter = {
  equals?: InputMaybe<Scalars['DateTime']>;
  gt?: InputMaybe<Scalars['DateTime']>;
  gte?: InputMaybe<Scalars['DateTime']>;
  in?: InputMaybe<Array<Scalars['DateTime']>>;
  lt?: InputMaybe<Scalars['DateTime']>;
  lte?: InputMaybe<Scalars['DateTime']>;
  not?: InputMaybe<NestedDateTimeFilter>;
  notIn?: InputMaybe<Array<Scalars['DateTime']>>;
};

export type DateTimeNullableFilter = {
  equals?: InputMaybe<Scalars['DateTime']>;
  gt?: InputMaybe<Scalars['DateTime']>;
  gte?: InputMaybe<Scalars['DateTime']>;
  in?: InputMaybe<Array<Scalars['DateTime']>>;
  lt?: InputMaybe<Scalars['DateTime']>;
  lte?: InputMaybe<Scalars['DateTime']>;
  not?: InputMaybe<NestedDateTimeNullableFilter>;
  notIn?: InputMaybe<Array<Scalars['DateTime']>>;
};

export type DueDiligence = {
  __typename?: 'DueDiligence';
  companyId: Scalars['String'];
  contentIds?: Maybe<Array<Scalars['String']>>;
  createdAt: Scalars['DateTime'];
  createdBy: Scalars['String'];
  /** @DtoReadOnly */
  deletedAt?: Maybe<Scalars['DateTime']>;
  id: Scalars['ID'];
  magicTableSheet: MagicTableSheet;
  magicTableSheetId: Scalars['String'];
  updatedAt: Scalars['DateTime'];
};

export type DueDiligenceOrderByWithRelationInput = {
  companyId?: InputMaybe<SortOrder>;
  contentIds?: InputMaybe<SortOrder>;
  createdAt?: InputMaybe<SortOrder>;
  createdBy?: InputMaybe<SortOrder>;
  deletedAt?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  magicTableSheet?: InputMaybe<MagicTableSheetOrderByWithRelationInput>;
  magicTableSheetId?: InputMaybe<SortOrder>;
  updatedAt?: InputMaybe<SortOrder>;
};

export type DueDiligenceRelationFilter = {
  is?: InputMaybe<DueDiligenceWhereInput>;
  isNot?: InputMaybe<DueDiligenceWhereInput>;
};

export enum DueDiligenceScalarFieldEnum {
  CompanyId = 'companyId',
  ContentIds = 'contentIds',
  CreatedAt = 'createdAt',
  CreatedBy = 'createdBy',
  DeletedAt = 'deletedAt',
  Id = 'id',
  MagicTableSheetId = 'magicTableSheetId',
  UpdatedAt = 'updatedAt'
}

export type DueDiligenceWhereInput = {
  AND?: InputMaybe<Array<DueDiligenceWhereInput>>;
  NOT?: InputMaybe<Array<DueDiligenceWhereInput>>;
  OR?: InputMaybe<Array<DueDiligenceWhereInput>>;
  companyId?: InputMaybe<StringFilter>;
  contentIds?: InputMaybe<StringNullableListFilter>;
  createdAt?: InputMaybe<DateTimeFilter>;
  createdBy?: InputMaybe<StringFilter>;
  deletedAt?: InputMaybe<DateTimeNullableFilter>;
  id?: InputMaybe<StringFilter>;
  magicTableSheet?: InputMaybe<MagicTableSheetRelationFilter>;
  magicTableSheetId?: InputMaybe<StringFilter>;
  updatedAt?: InputMaybe<DateTimeFilter>;
};

export type DueDiligenceWhereUniqueInput = {
  id?: InputMaybe<Scalars['String']>;
  magicTableSheetId?: InputMaybe<Scalars['String']>;
};

export type EnumAnalyticsOrderStateFilter = {
  equals?: InputMaybe<AnalyticsOrderState>;
  in?: InputMaybe<Array<AnalyticsOrderState>>;
  not?: InputMaybe<NestedEnumAnalyticsOrderStateFilter>;
  notIn?: InputMaybe<Array<AnalyticsOrderState>>;
};

export type EnumAnalyticsTypeFilter = {
  equals?: InputMaybe<AnalyticsType>;
  in?: InputMaybe<Array<AnalyticsType>>;
  not?: InputMaybe<NestedEnumAnalyticsTypeFilter>;
  notIn?: InputMaybe<Array<AnalyticsType>>;
};

export type EnumAssistantAccessEntityTypeFilter = {
  equals?: InputMaybe<AssistantAccessEntityType>;
  in?: InputMaybe<Array<AssistantAccessEntityType>>;
  not?: InputMaybe<NestedEnumAssistantAccessEntityTypeFilter>;
  notIn?: InputMaybe<Array<AssistantAccessEntityType>>;
};

export type EnumAssistantAccessTypeFilter = {
  equals?: InputMaybe<AssistantAccessType>;
  in?: InputMaybe<Array<AssistantAccessType>>;
  not?: InputMaybe<NestedEnumAssistantAccessTypeFilter>;
  notIn?: InputMaybe<Array<AssistantAccessType>>;
};

export type EnumBenchmarkStatusFilter = {
  equals?: InputMaybe<BenchmarkStatus>;
  in?: InputMaybe<Array<BenchmarkStatus>>;
  not?: InputMaybe<NestedEnumBenchmarkStatusFilter>;
  notIn?: InputMaybe<Array<BenchmarkStatus>>;
};

export type EnumChatUploadFilter = {
  equals?: InputMaybe<ChatUpload>;
  in?: InputMaybe<Array<ChatUpload>>;
  not?: InputMaybe<NestedEnumChatUploadFilter>;
  notIn?: InputMaybe<Array<ChatUpload>>;
};

export type EnumIngestionStateFilter = {
  equals?: InputMaybe<IngestionState>;
  in?: InputMaybe<Array<IngestionState>>;
  not?: InputMaybe<NestedEnumIngestionStateFilter>;
  notIn?: InputMaybe<Array<IngestionState>>;
};

export type EnumOwnerTypeFilter = {
  equals?: InputMaybe<OwnerType>;
  in?: InputMaybe<Array<OwnerType>>;
  not?: InputMaybe<NestedEnumOwnerTypeFilter>;
  notIn?: InputMaybe<Array<OwnerType>>;
};

export type EnumRoleFilter = {
  equals?: InputMaybe<Role>;
  in?: InputMaybe<Array<Role>>;
  not?: InputMaybe<NestedEnumRoleFilter>;
  notIn?: InputMaybe<Array<Role>>;
};

export type EnumScopeAccessEntityTypeFilter = {
  equals?: InputMaybe<ScopeAccessEntityType>;
  in?: InputMaybe<Array<ScopeAccessEntityType>>;
  not?: InputMaybe<NestedEnumScopeAccessEntityTypeFilter>;
  notIn?: InputMaybe<Array<ScopeAccessEntityType>>;
};

export type EnumScopeAccessTypeFilter = {
  equals?: InputMaybe<ScopeAccessType>;
  in?: InputMaybe<Array<ScopeAccessType>>;
  not?: InputMaybe<NestedEnumScopeAccessTypeFilter>;
  notIn?: InputMaybe<Array<ScopeAccessType>>;
};

export type EnumSourceKindFilter = {
  equals?: InputMaybe<SourceKind>;
  in?: InputMaybe<Array<SourceKind>>;
  not?: InputMaybe<NestedEnumSourceKindFilter>;
  notIn?: InputMaybe<Array<SourceKind>>;
};

export type ExternalLanguageModel = {
  __typename?: 'ExternalLanguageModel';
  _count: ExternalLanguageModelCount;
  apiVersion: Scalars['String'];
  authHeaderName?: Maybe<Scalars['String']>;
  authHeaderPrefix?: Maybe<Scalars['String']>;
  companyId: Scalars['String'];
  createdAt: Scalars['DateTime'];
  deploymentName: Scalars['String'];
  endpoint: Scalars['String'];
  id: Scalars['ID'];
  internalDescription: Scalars['String'];
  languageModelGroups?: Maybe<Array<LanguageModelGroup>>;
  maxTokens: Scalars['Int'];
  model: Scalars['String'];
  updatedAt: Scalars['DateTime'];
  version: Scalars['String'];
};

export type ExternalLanguageModelCount = {
  __typename?: 'ExternalLanguageModelCount';
  languageModelGroups: Scalars['Int'];
};

export type ExternalLanguageModelCreateInput = {
  apiVersion: Scalars['String'];
  authHeaderName?: InputMaybe<Scalars['String']>;
  authHeaderPrefix?: InputMaybe<Scalars['String']>;
  authHeaderValue?: InputMaybe<Scalars['String']>;
  deploymentName: Scalars['String'];
  endpoint: Scalars['String'];
  id?: InputMaybe<Scalars['String']>;
  internalDescription: Scalars['String'];
  maxTokens: Scalars['Int'];
  model: Scalars['String'];
  version: Scalars['String'];
};

export type ExternalLanguageModelOrderByWithRelationInput = {
  apiVersion?: InputMaybe<SortOrder>;
  authHeaderName?: InputMaybe<SortOrder>;
  authHeaderPrefix?: InputMaybe<SortOrder>;
  createdAt?: InputMaybe<SortOrder>;
  deploymentName?: InputMaybe<SortOrder>;
  endpoint?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  internalDescription?: InputMaybe<SortOrder>;
  maxTokens?: InputMaybe<SortOrder>;
  model?: InputMaybe<SortOrder>;
  updatedAt?: InputMaybe<SortOrder>;
  version?: InputMaybe<SortOrder>;
};

export enum ExternalLanguageModelScalarFieldEnum {
  ApiVersion = 'apiVersion',
  AuthHeaderName = 'authHeaderName',
  AuthHeaderPrefix = 'authHeaderPrefix',
  AuthHeaderValue = 'authHeaderValue',
  CompanyId = 'companyId',
  CreatedAt = 'createdAt',
  DeploymentName = 'deploymentName',
  Endpoint = 'endpoint',
  Id = 'id',
  InternalDescription = 'internalDescription',
  MaxTokens = 'maxTokens',
  Model = 'model',
  UpdatedAt = 'updatedAt',
  Version = 'version'
}

export type ExternalLanguageModelUpdateInput = {
  apiVersion?: InputMaybe<Scalars['String']>;
  authHeaderName?: InputMaybe<Scalars['String']>;
  authHeaderPrefix?: InputMaybe<Scalars['String']>;
  authHeaderValue?: InputMaybe<Scalars['String']>;
  deploymentName?: InputMaybe<Scalars['String']>;
  endpoint?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['String']>;
  internalDescription?: InputMaybe<Scalars['String']>;
  maxTokens?: InputMaybe<Scalars['Int']>;
  model?: InputMaybe<Scalars['String']>;
  version?: InputMaybe<Scalars['String']>;
};

export type ExternalLanguageModelWhereInput = {
  AND?: InputMaybe<Array<ExternalLanguageModelWhereInput>>;
  NOT?: InputMaybe<Array<ExternalLanguageModelWhereInput>>;
  OR?: InputMaybe<Array<ExternalLanguageModelWhereInput>>;
  apiVersion?: InputMaybe<StringFilter>;
  authHeaderName?: InputMaybe<StringNullableFilter>;
  authHeaderPrefix?: InputMaybe<StringNullableFilter>;
  createdAt?: InputMaybe<DateTimeFilter>;
  deploymentName?: InputMaybe<StringFilter>;
  endpoint?: InputMaybe<StringFilter>;
  id?: InputMaybe<StringFilter>;
  internalDescription?: InputMaybe<StringFilter>;
  maxTokens?: InputMaybe<IntFilter>;
  model?: InputMaybe<StringFilter>;
  updatedAt?: InputMaybe<DateTimeFilter>;
  version?: InputMaybe<StringFilter>;
};

export type ExternalLanguageModelWhereUniqueInput = {
  id?: InputMaybe<Scalars['String']>;
};

export type Feedback = {
  __typename?: 'Feedback';
  additionalInfo?: Maybe<Scalars['JSON']>;
  id: Scalars['ID'];
  message: Message;
  messageId: Scalars['String'];
  positive: Scalars['Boolean'];
  text?: Maybe<Scalars['String']>;
};

export type FeedbackCreateNestedOneWithoutMessageInput = {
  connect?: InputMaybe<FeedbackWhereUniqueInput>;
  connectOrCreate?: InputMaybe<FeedbackCreateOrConnectWithoutMessageInput>;
  create?: InputMaybe<FeedbackCreateWithoutMessageInput>;
};

export type FeedbackCreateOrConnectWithoutMessageInput = {
  create: FeedbackCreateWithoutMessageInput;
  where: FeedbackWhereUniqueInput;
};

export type FeedbackCreateWithoutMessageInput = {
  additionalInfo?: InputMaybe<Scalars['JSON']>;
  id?: InputMaybe<Scalars['String']>;
  positive?: InputMaybe<Scalars['Boolean']>;
  text?: InputMaybe<Scalars['String']>;
};

export type FeedbackOrderByWithRelationInput = {
  additionalInfo?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  message?: InputMaybe<MessageOrderByWithRelationInput>;
  messageId?: InputMaybe<SortOrder>;
  positive?: InputMaybe<SortOrder>;
  text?: InputMaybe<SortOrder>;
};

export type FeedbackRelationFilter = {
  is?: InputMaybe<FeedbackWhereInput>;
  isNot?: InputMaybe<FeedbackWhereInput>;
};

export type FeedbackUpdateOneWithoutMessageNestedInput = {
  connect?: InputMaybe<FeedbackWhereUniqueInput>;
  connectOrCreate?: InputMaybe<FeedbackCreateOrConnectWithoutMessageInput>;
  create?: InputMaybe<FeedbackCreateWithoutMessageInput>;
  delete?: InputMaybe<Scalars['Boolean']>;
  disconnect?: InputMaybe<Scalars['Boolean']>;
  update?: InputMaybe<FeedbackUpdateWithoutMessageInput>;
  upsert?: InputMaybe<FeedbackUpsertWithoutMessageInput>;
};

export type FeedbackUpdateWithoutMessageInput = {
  additionalInfo?: InputMaybe<Scalars['JSON']>;
  id?: InputMaybe<Scalars['String']>;
  positive?: InputMaybe<Scalars['Boolean']>;
  text?: InputMaybe<Scalars['String']>;
};

export type FeedbackUpsertWithoutMessageInput = {
  create: FeedbackCreateWithoutMessageInput;
  update: FeedbackUpdateWithoutMessageInput;
};

export type FeedbackWhereInput = {
  AND?: InputMaybe<Array<FeedbackWhereInput>>;
  NOT?: InputMaybe<Array<FeedbackWhereInput>>;
  OR?: InputMaybe<Array<FeedbackWhereInput>>;
  additionalInfo?: InputMaybe<JsonNullableFilter>;
  id?: InputMaybe<StringFilter>;
  message?: InputMaybe<MessageRelationFilter>;
  messageId?: InputMaybe<StringFilter>;
  positive?: InputMaybe<BoolFilter>;
  text?: InputMaybe<StringNullableFilter>;
};

export type FeedbackWhereUniqueInput = {
  id?: InputMaybe<Scalars['String']>;
  messageId?: InputMaybe<Scalars['String']>;
};

export type Font = {
  __typename?: 'Font';
  createdAt?: Maybe<Scalars['DateTime']>;
  family: Scalars['String'];
  fileTypes?: Maybe<Array<Scalars['String']>>;
  files?: Maybe<Array<Scalars['String']>>;
  id: Scalars['ID'];
  style: Scalars['String'];
  theme?: Maybe<Theme>;
  themeId?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  weight: Scalars['String'];
};

export type FontCreateManyThemeInput = {
  createdAt?: InputMaybe<Scalars['DateTime']>;
  family: Scalars['String'];
  fileTypes?: InputMaybe<Array<Scalars['String']>>;
  files?: InputMaybe<Array<Scalars['String']>>;
  id?: InputMaybe<Scalars['String']>;
  style: Scalars['String'];
  updatedAt?: InputMaybe<Scalars['DateTime']>;
  weight: Scalars['String'];
};

export type FontCreateManyThemeInputEnvelope = {
  data: Array<FontCreateManyThemeInput>;
  skipDuplicates?: InputMaybe<Scalars['Boolean']>;
};

export type FontCreateNestedManyWithoutThemeInput = {
  connect?: InputMaybe<Array<FontWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<FontCreateOrConnectWithoutThemeInput>>;
  create?: InputMaybe<Array<FontCreateWithoutThemeInput>>;
  createMany?: InputMaybe<FontCreateManyThemeInputEnvelope>;
};

export type FontCreateOrConnectWithoutThemeInput = {
  create: FontCreateWithoutThemeInput;
  where: FontWhereUniqueInput;
};

export type FontCreateWithoutThemeInput = {
  createdAt?: InputMaybe<Scalars['DateTime']>;
  family: Scalars['String'];
  fileTypes?: InputMaybe<Array<Scalars['String']>>;
  files?: InputMaybe<Array<Scalars['String']>>;
  id?: InputMaybe<Scalars['String']>;
  style: Scalars['String'];
  updatedAt?: InputMaybe<Scalars['DateTime']>;
  weight: Scalars['String'];
};

export type FontFamily = {
  __typename?: 'FontFamily';
  createdAt?: Maybe<Scalars['DateTime']>;
  family: Scalars['String'];
  id: Scalars['ID'];
  name: Scalars['String'];
  theme?: Maybe<Theme>;
  themeId?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type FontFamilyCreateManyThemeInput = {
  createdAt?: InputMaybe<Scalars['DateTime']>;
  family: Scalars['String'];
  id?: InputMaybe<Scalars['String']>;
  name: Scalars['String'];
  updatedAt?: InputMaybe<Scalars['DateTime']>;
};

export type FontFamilyCreateManyThemeInputEnvelope = {
  data: Array<FontFamilyCreateManyThemeInput>;
  skipDuplicates?: InputMaybe<Scalars['Boolean']>;
};

export type FontFamilyCreateNestedManyWithoutThemeInput = {
  connect?: InputMaybe<Array<FontFamilyWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<FontFamilyCreateOrConnectWithoutThemeInput>>;
  create?: InputMaybe<Array<FontFamilyCreateWithoutThemeInput>>;
  createMany?: InputMaybe<FontFamilyCreateManyThemeInputEnvelope>;
};

export type FontFamilyCreateOrConnectWithoutThemeInput = {
  create: FontFamilyCreateWithoutThemeInput;
  where: FontFamilyWhereUniqueInput;
};

export type FontFamilyCreateWithoutThemeInput = {
  createdAt?: InputMaybe<Scalars['DateTime']>;
  family: Scalars['String'];
  id?: InputMaybe<Scalars['String']>;
  name: Scalars['String'];
  updatedAt?: InputMaybe<Scalars['DateTime']>;
};

export type FontFamilyScalarWhereInput = {
  AND?: InputMaybe<Array<FontFamilyScalarWhereInput>>;
  NOT?: InputMaybe<Array<FontFamilyScalarWhereInput>>;
  OR?: InputMaybe<Array<FontFamilyScalarWhereInput>>;
  createdAt?: InputMaybe<DateTimeNullableFilter>;
  family?: InputMaybe<StringFilter>;
  id?: InputMaybe<StringFilter>;
  name?: InputMaybe<StringFilter>;
  themeId?: InputMaybe<StringNullableFilter>;
  updatedAt?: InputMaybe<DateTimeNullableFilter>;
};

export type FontFamilyUpdateManyMutationInput = {
  createdAt?: InputMaybe<Scalars['DateTime']>;
  family?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
};

export type FontFamilyUpdateManyWithWhereWithoutThemeInput = {
  data: FontFamilyUpdateManyMutationInput;
  where: FontFamilyScalarWhereInput;
};

export type FontFamilyUpdateManyWithoutThemeNestedInput = {
  connect?: InputMaybe<Array<FontFamilyWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<FontFamilyCreateOrConnectWithoutThemeInput>>;
  create?: InputMaybe<Array<FontFamilyCreateWithoutThemeInput>>;
  createMany?: InputMaybe<FontFamilyCreateManyThemeInputEnvelope>;
  delete?: InputMaybe<Array<FontFamilyWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<FontFamilyScalarWhereInput>>;
  disconnect?: InputMaybe<Array<FontFamilyWhereUniqueInput>>;
  set?: InputMaybe<Array<FontFamilyWhereUniqueInput>>;
  update?: InputMaybe<Array<FontFamilyUpdateWithWhereUniqueWithoutThemeInput>>;
  updateMany?: InputMaybe<Array<FontFamilyUpdateManyWithWhereWithoutThemeInput>>;
  upsert?: InputMaybe<Array<FontFamilyUpsertWithWhereUniqueWithoutThemeInput>>;
};

export type FontFamilyUpdateWithWhereUniqueWithoutThemeInput = {
  data: FontFamilyUpdateWithoutThemeInput;
  where: FontFamilyWhereUniqueInput;
};

export type FontFamilyUpdateWithoutThemeInput = {
  createdAt?: InputMaybe<Scalars['DateTime']>;
  family?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
};

export type FontFamilyUpsertWithWhereUniqueWithoutThemeInput = {
  create: FontFamilyCreateWithoutThemeInput;
  update: FontFamilyUpdateWithoutThemeInput;
  where: FontFamilyWhereUniqueInput;
};

export type FontFamilyWhereUniqueInput = {
  id?: InputMaybe<Scalars['String']>;
};

export type FontScalarWhereInput = {
  AND?: InputMaybe<Array<FontScalarWhereInput>>;
  NOT?: InputMaybe<Array<FontScalarWhereInput>>;
  OR?: InputMaybe<Array<FontScalarWhereInput>>;
  createdAt?: InputMaybe<DateTimeNullableFilter>;
  family?: InputMaybe<StringFilter>;
  fileTypes?: InputMaybe<StringNullableListFilter>;
  files?: InputMaybe<StringNullableListFilter>;
  id?: InputMaybe<StringFilter>;
  style?: InputMaybe<StringFilter>;
  themeId?: InputMaybe<StringNullableFilter>;
  updatedAt?: InputMaybe<DateTimeNullableFilter>;
  weight?: InputMaybe<StringFilter>;
};

export type FontUpdateManyMutationInput = {
  createdAt?: InputMaybe<Scalars['DateTime']>;
  family?: InputMaybe<Scalars['String']>;
  fileTypes?: InputMaybe<Array<Scalars['String']>>;
  files?: InputMaybe<Array<Scalars['String']>>;
  id?: InputMaybe<Scalars['String']>;
  style?: InputMaybe<Scalars['String']>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
  weight?: InputMaybe<Scalars['String']>;
};

export type FontUpdateManyWithWhereWithoutThemeInput = {
  data: FontUpdateManyMutationInput;
  where: FontScalarWhereInput;
};

export type FontUpdateManyWithoutThemeNestedInput = {
  connect?: InputMaybe<Array<FontWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<FontCreateOrConnectWithoutThemeInput>>;
  create?: InputMaybe<Array<FontCreateWithoutThemeInput>>;
  createMany?: InputMaybe<FontCreateManyThemeInputEnvelope>;
  delete?: InputMaybe<Array<FontWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<FontScalarWhereInput>>;
  disconnect?: InputMaybe<Array<FontWhereUniqueInput>>;
  set?: InputMaybe<Array<FontWhereUniqueInput>>;
  update?: InputMaybe<Array<FontUpdateWithWhereUniqueWithoutThemeInput>>;
  updateMany?: InputMaybe<Array<FontUpdateManyWithWhereWithoutThemeInput>>;
  upsert?: InputMaybe<Array<FontUpsertWithWhereUniqueWithoutThemeInput>>;
};

export type FontUpdateWithWhereUniqueWithoutThemeInput = {
  data: FontUpdateWithoutThemeInput;
  where: FontWhereUniqueInput;
};

export type FontUpdateWithoutThemeInput = {
  createdAt?: InputMaybe<Scalars['DateTime']>;
  family?: InputMaybe<Scalars['String']>;
  fileTypes?: InputMaybe<Array<Scalars['String']>>;
  files?: InputMaybe<Array<Scalars['String']>>;
  id?: InputMaybe<Scalars['String']>;
  style?: InputMaybe<Scalars['String']>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
  weight?: InputMaybe<Scalars['String']>;
};

export type FontUpsertWithWhereUniqueWithoutThemeInput = {
  create: FontCreateWithoutThemeInput;
  update: FontUpdateWithoutThemeInput;
  where: FontWhereUniqueInput;
};

export type FontWhereUniqueInput = {
  id?: InputMaybe<Scalars['String']>;
};

export type Group = {
  __typename?: 'Group';
  _count: GroupCount;
  children?: Maybe<Array<Group>>;
  companyId: Scalars['String'];
  configuration: Scalars['JSON'];
  createdAt: Scalars['DateTime'];
  externalId?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  members?: Maybe<Array<Membership>>;
  name: Scalars['String'];
  parent?: Maybe<Group>;
  parentId?: Maybe<Scalars['String']>;
  updatedAt: Scalars['DateTime'];
};

export type GroupCount = {
  __typename?: 'GroupCount';
  children: Scalars['Int'];
  members: Scalars['Int'];
};

export type GroupCreateInput = {
  children?: InputMaybe<GroupCreateNestedManyWithoutParentInput>;
  configuration?: InputMaybe<Scalars['JSON']>;
  externalId?: InputMaybe<Scalars['String']>;
  members?: InputMaybe<MembershipCreateNestedManyWithoutGroupInput>;
  name: Scalars['String'];
  parent?: InputMaybe<GroupCreateNestedOneWithoutChildrenInput>;
};

export type GroupCreateManyParentInput = {
  configuration?: InputMaybe<Scalars['JSON']>;
  externalId?: InputMaybe<Scalars['String']>;
  name: Scalars['String'];
};

export type GroupCreateManyParentInputEnvelope = {
  data: Array<GroupCreateManyParentInput>;
  skipDuplicates?: InputMaybe<Scalars['Boolean']>;
};

export type GroupCreateNestedManyWithoutParentInput = {
  connect?: InputMaybe<Array<GroupWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<GroupCreateOrConnectWithoutParentInput>>;
  create?: InputMaybe<Array<GroupCreateWithoutParentInput>>;
  createMany?: InputMaybe<GroupCreateManyParentInputEnvelope>;
};

export type GroupCreateNestedOneWithoutChildrenInput = {
  connect?: InputMaybe<GroupWhereUniqueInput>;
  connectOrCreate?: InputMaybe<GroupCreateOrConnectWithoutChildrenInput>;
  create?: InputMaybe<GroupCreateWithoutChildrenInput>;
};

export type GroupCreateOrConnectWithoutChildrenInput = {
  create: GroupCreateWithoutChildrenInput;
  where: GroupWhereUniqueInput;
};

export type GroupCreateOrConnectWithoutParentInput = {
  create: GroupCreateWithoutParentInput;
  where: GroupWhereUniqueInput;
};

export type GroupCreateWithoutChildrenInput = {
  configuration?: InputMaybe<Scalars['JSON']>;
  externalId?: InputMaybe<Scalars['String']>;
  members?: InputMaybe<MembershipCreateNestedManyWithoutGroupInput>;
  name: Scalars['String'];
  parent?: InputMaybe<GroupCreateNestedOneWithoutChildrenInput>;
};

export type GroupCreateWithoutParentInput = {
  children?: InputMaybe<GroupCreateNestedManyWithoutParentInput>;
  configuration?: InputMaybe<Scalars['JSON']>;
  externalId?: InputMaybe<Scalars['String']>;
  members?: InputMaybe<MembershipCreateNestedManyWithoutGroupInput>;
  name: Scalars['String'];
};

export type GroupListRelationFilter = {
  every?: InputMaybe<GroupWhereInput>;
  none?: InputMaybe<GroupWhereInput>;
  some?: InputMaybe<GroupWhereInput>;
};

export type GroupOrderByRelationAggregateInput = {
  _count?: InputMaybe<SortOrder>;
};

export type GroupOrderByWithRelationInput = {
  children?: InputMaybe<GroupOrderByRelationAggregateInput>;
  configuration?: InputMaybe<SortOrder>;
  externalId?: InputMaybe<SortOrder>;
  members?: InputMaybe<MembershipOrderByRelationAggregateInput>;
  name?: InputMaybe<SortOrder>;
  parent?: InputMaybe<GroupOrderByWithRelationInput>;
  parentId?: InputMaybe<SortOrder>;
};

export type GroupRelationFilter = {
  is?: InputMaybe<GroupWhereInput>;
  isNot?: InputMaybe<GroupWhereInput>;
};

export enum GroupScalarFieldEnum {
  CompanyId = 'companyId',
  Configuration = 'configuration',
  CreatedAt = 'createdAt',
  ExternalId = 'externalId',
  Id = 'id',
  Name = 'name',
  ParentId = 'parentId',
  UpdatedAt = 'updatedAt'
}

export type GroupScalarWhereInput = {
  AND?: InputMaybe<Array<GroupScalarWhereInput>>;
  NOT?: InputMaybe<Array<GroupScalarWhereInput>>;
  OR?: InputMaybe<Array<GroupScalarWhereInput>>;
  configuration?: InputMaybe<JsonFilter>;
  externalId?: InputMaybe<StringNullableFilter>;
  name?: InputMaybe<StringFilter>;
  parentId?: InputMaybe<StringNullableFilter>;
};

export type GroupUpdateInput = {
  children?: InputMaybe<GroupUpdateManyWithoutParentNestedInput>;
  configuration?: InputMaybe<Scalars['JSON']>;
  externalId?: InputMaybe<Scalars['String']>;
  members?: InputMaybe<MembershipUpdateManyWithoutGroupNestedInput>;
  name?: InputMaybe<Scalars['String']>;
  parent?: InputMaybe<GroupUpdateOneWithoutChildrenNestedInput>;
};

export type GroupUpdateManyMutationInput = {
  configuration?: InputMaybe<Scalars['JSON']>;
  externalId?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
};

export type GroupUpdateManyWithWhereWithoutParentInput = {
  data: GroupUpdateManyMutationInput;
  where: GroupScalarWhereInput;
};

export type GroupUpdateManyWithoutParentNestedInput = {
  connect?: InputMaybe<Array<GroupWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<GroupCreateOrConnectWithoutParentInput>>;
  create?: InputMaybe<Array<GroupCreateWithoutParentInput>>;
  createMany?: InputMaybe<GroupCreateManyParentInputEnvelope>;
  delete?: InputMaybe<Array<GroupWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<GroupScalarWhereInput>>;
  disconnect?: InputMaybe<Array<GroupWhereUniqueInput>>;
  set?: InputMaybe<Array<GroupWhereUniqueInput>>;
  update?: InputMaybe<Array<GroupUpdateWithWhereUniqueWithoutParentInput>>;
  updateMany?: InputMaybe<Array<GroupUpdateManyWithWhereWithoutParentInput>>;
  upsert?: InputMaybe<Array<GroupUpsertWithWhereUniqueWithoutParentInput>>;
};

export type GroupUpdateOneWithoutChildrenNestedInput = {
  connect?: InputMaybe<GroupWhereUniqueInput>;
  connectOrCreate?: InputMaybe<GroupCreateOrConnectWithoutChildrenInput>;
  create?: InputMaybe<GroupCreateWithoutChildrenInput>;
  delete?: InputMaybe<Scalars['Boolean']>;
  disconnect?: InputMaybe<Scalars['Boolean']>;
  update?: InputMaybe<GroupUpdateWithoutChildrenInput>;
  upsert?: InputMaybe<GroupUpsertWithoutChildrenInput>;
};

export type GroupUpdateWithWhereUniqueWithoutParentInput = {
  data: GroupUpdateWithoutParentInput;
  where: GroupWhereUniqueInput;
};

export type GroupUpdateWithoutChildrenInput = {
  configuration?: InputMaybe<Scalars['JSON']>;
  externalId?: InputMaybe<Scalars['String']>;
  members?: InputMaybe<MembershipUpdateManyWithoutGroupNestedInput>;
  name?: InputMaybe<Scalars['String']>;
  parent?: InputMaybe<GroupUpdateOneWithoutChildrenNestedInput>;
};

export type GroupUpdateWithoutParentInput = {
  children?: InputMaybe<GroupUpdateManyWithoutParentNestedInput>;
  configuration?: InputMaybe<Scalars['JSON']>;
  externalId?: InputMaybe<Scalars['String']>;
  members?: InputMaybe<MembershipUpdateManyWithoutGroupNestedInput>;
  name?: InputMaybe<Scalars['String']>;
};

export type GroupUpsertWithWhereUniqueWithoutParentInput = {
  create: GroupCreateWithoutParentInput;
  update: GroupUpdateWithoutParentInput;
  where: GroupWhereUniqueInput;
};

export type GroupUpsertWithoutChildrenInput = {
  create: GroupCreateWithoutChildrenInput;
  update: GroupUpdateWithoutChildrenInput;
};

export type GroupWhereInput = {
  AND?: InputMaybe<Array<GroupWhereInput>>;
  NOT?: InputMaybe<Array<GroupWhereInput>>;
  OR?: InputMaybe<Array<GroupWhereInput>>;
  children?: InputMaybe<GroupListRelationFilter>;
  companyId?: InputMaybe<StringFilter>;
  configuration?: InputMaybe<JsonFilter>;
  externalId?: InputMaybe<StringNullableFilter>;
  id?: InputMaybe<StringFilter>;
  members?: InputMaybe<MembershipListRelationFilter>;
  name?: InputMaybe<StringFilter>;
  parent?: InputMaybe<GroupRelationFilter>;
  parentId?: InputMaybe<StringNullableFilter>;
};

export type GroupWhereUniqueInput = {
  id?: InputMaybe<Scalars['String']>;
};

export type IngestionConfig = {
  chunkMaxTokens?: InputMaybe<Scalars['Int']>;
  chunkMaxTokensOnePager?: InputMaybe<Scalars['Int']>;
  chunkMinTokens?: InputMaybe<Scalars['Int']>;
  chunkStrategy?: InputMaybe<Scalars['String']>;
  customApiIdentifier?: InputMaybe<Scalars['String']>;
  customApiPayload?: InputMaybe<Scalars['String']>;
  documentMinTokens?: InputMaybe<Scalars['Int']>;
  jpgReadMode?: InputMaybe<Scalars['String']>;
  pdfReadMode?: InputMaybe<Scalars['String']>;
  uniqueIngestionMode?: InputMaybe<Scalars['String']>;
  wordReadMode?: InputMaybe<Scalars['String']>;
};

export enum IngestionState {
  CheckingIntegrity = 'CHECKING_INTEGRITY',
  Failed = 'FAILED',
  FailedCreatingChunks = 'FAILED_CREATING_CHUNKS',
  FailedEmbedding = 'FAILED_EMBEDDING',
  FailedGettingFile = 'FAILED_GETTING_FILE',
  FailedImage = 'FAILED_IMAGE',
  FailedMalwareFound = 'FAILED_MALWARE_FOUND',
  FailedParsing = 'FAILED_PARSING',
  FailedRedelivered = 'FAILED_REDELIVERED',
  FailedTooLessContent = 'FAILED_TOO_LESS_CONTENT',
  Finished = 'FINISHED',
  IngestionChunking = 'INGESTION_CHUNKING',
  IngestionEmbedding = 'INGESTION_EMBEDDING',
  IngestionReading = 'INGESTION_READING',
  MalwareScanning = 'MALWARE_SCANNING',
  Queued = 'QUEUED',
  RebuildingMetadata = 'REBUILDING_METADATA',
  RecreatingVecetordbIndex = 'RECREATING_VECETORDB_INDEX',
  Retrying = 'RETRYING',
  ReEmbedding = 'RE_EMBEDDING',
  ReIngesting = 'RE_INGESTING'
}

export type IntFilter = {
  equals?: InputMaybe<Scalars['Int']>;
  gt?: InputMaybe<Scalars['Int']>;
  gte?: InputMaybe<Scalars['Int']>;
  in?: InputMaybe<Array<Scalars['Int']>>;
  lt?: InputMaybe<Scalars['Int']>;
  lte?: InputMaybe<Scalars['Int']>;
  not?: InputMaybe<NestedIntFilter>;
  notIn?: InputMaybe<Array<Scalars['Int']>>;
};

export type IntNullableFilter = {
  equals?: InputMaybe<Scalars['Int']>;
  gt?: InputMaybe<Scalars['Int']>;
  gte?: InputMaybe<Scalars['Int']>;
  in?: InputMaybe<Array<Scalars['Int']>>;
  lt?: InputMaybe<Scalars['Int']>;
  lte?: InputMaybe<Scalars['Int']>;
  not?: InputMaybe<NestedIntNullableFilter>;
  notIn?: InputMaybe<Array<Scalars['Int']>>;
};

export type JsonFilter = {
  array_contains?: InputMaybe<Scalars['JSON']>;
  array_ends_with?: InputMaybe<Scalars['JSON']>;
  array_starts_with?: InputMaybe<Scalars['JSON']>;
  equals?: InputMaybe<Scalars['JSON']>;
  gt?: InputMaybe<Scalars['JSON']>;
  gte?: InputMaybe<Scalars['JSON']>;
  lt?: InputMaybe<Scalars['JSON']>;
  lte?: InputMaybe<Scalars['JSON']>;
  not?: InputMaybe<Scalars['JSON']>;
  path?: InputMaybe<Array<Scalars['String']>>;
  string_contains?: InputMaybe<Scalars['String']>;
  string_ends_with?: InputMaybe<Scalars['String']>;
  string_starts_with?: InputMaybe<Scalars['String']>;
};

export type JsonNullableFilter = {
  array_contains?: InputMaybe<Scalars['JSON']>;
  array_ends_with?: InputMaybe<Scalars['JSON']>;
  array_starts_with?: InputMaybe<Scalars['JSON']>;
  equals?: InputMaybe<Scalars['JSON']>;
  gt?: InputMaybe<Scalars['JSON']>;
  gte?: InputMaybe<Scalars['JSON']>;
  lt?: InputMaybe<Scalars['JSON']>;
  lte?: InputMaybe<Scalars['JSON']>;
  not?: InputMaybe<Scalars['JSON']>;
  path?: InputMaybe<Array<Scalars['String']>>;
  string_contains?: InputMaybe<Scalars['String']>;
  string_ends_with?: InputMaybe<Scalars['String']>;
  string_starts_with?: InputMaybe<Scalars['String']>;
};

export type LanguageModelGroup = {
  __typename?: 'LanguageModelGroup';
  _count: LanguageModelGroupCount;
  companyId: Scalars['String'];
  createdAt: Scalars['DateTime'];
  groupName: Scalars['String'];
  id: Scalars['ID'];
  languageModels?: Maybe<Array<ExternalLanguageModel>>;
  updatedAt: Scalars['DateTime'];
};

export type LanguageModelGroupCompanyIdGroupNameCompoundUniqueInput = {
  groupName: Scalars['String'];
};

export type LanguageModelGroupCount = {
  __typename?: 'LanguageModelGroupCount';
  languageModels: Scalars['Int'];
};

export type LanguageModelGroupCreateInput = {
  groupName: Scalars['String'];
  id?: InputMaybe<Scalars['String']>;
};

export type LanguageModelGroupOrderByWithRelationInput = {
  createdAt?: InputMaybe<SortOrder>;
  groupName?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  updatedAt?: InputMaybe<SortOrder>;
};

export enum LanguageModelGroupScalarFieldEnum {
  CompanyId = 'companyId',
  CreatedAt = 'createdAt',
  GroupName = 'groupName',
  Id = 'id',
  UpdatedAt = 'updatedAt'
}

export type LanguageModelGroupUpdateInput = {
  groupName?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['String']>;
};

export type LanguageModelGroupWhereInput = {
  AND?: InputMaybe<Array<LanguageModelGroupWhereInput>>;
  NOT?: InputMaybe<Array<LanguageModelGroupWhereInput>>;
  OR?: InputMaybe<Array<LanguageModelGroupWhereInput>>;
  createdAt?: InputMaybe<DateTimeFilter>;
  groupName?: InputMaybe<StringFilter>;
  id?: InputMaybe<StringFilter>;
  updatedAt?: InputMaybe<DateTimeFilter>;
};

export type LanguageModelGroupWhereUniqueInput = {
  companyId_groupName?: InputMaybe<LanguageModelGroupCompanyIdGroupNameCompoundUniqueInput>;
  id?: InputMaybe<Scalars['String']>;
};

export type MagicTableActionResponse = {
  __typename?: 'MagicTableActionResponse';
  message?: Maybe<Scalars['String']>;
  status: Scalars['Boolean'];
};

export type MagicTableArtifact = {
  __typename?: 'MagicTableArtifact';
  companyId: Scalars['String'];
  contentId: Scalars['String'];
  createdAt: Scalars['DateTime'];
  createdBy: Scalars['String'];
  /** @DtoReadOnly */
  deletedAt?: Maybe<Scalars['DateTime']>;
  id: Scalars['ID'];
  magicTableSheet: MagicTableSheet;
  magicTableSheetId: Scalars['String'];
  mimeType: Scalars['String'];
  name: Scalars['String'];
  updatedAt: Scalars['DateTime'];
};

export type MagicTableArtifactListRelationFilter = {
  every?: InputMaybe<MagicTableArtifactWhereInput>;
  none?: InputMaybe<MagicTableArtifactWhereInput>;
  some?: InputMaybe<MagicTableArtifactWhereInput>;
};

export type MagicTableArtifactOrderByRelationAggregateInput = {
  _count?: InputMaybe<SortOrder>;
};

export type MagicTableArtifactWhereInput = {
  AND?: InputMaybe<Array<MagicTableArtifactWhereInput>>;
  NOT?: InputMaybe<Array<MagicTableArtifactWhereInput>>;
  OR?: InputMaybe<Array<MagicTableArtifactWhereInput>>;
  companyId?: InputMaybe<StringFilter>;
  contentId?: InputMaybe<StringFilter>;
  createdAt?: InputMaybe<DateTimeFilter>;
  createdBy?: InputMaybe<StringFilter>;
  deletedAt?: InputMaybe<DateTimeNullableFilter>;
  id?: InputMaybe<StringFilter>;
  magicTableSheetId?: InputMaybe<StringFilter>;
  mimeType?: InputMaybe<StringFilter>;
  name?: InputMaybe<StringFilter>;
  updatedAt?: InputMaybe<DateTimeFilter>;
};

export type MagicTableCell = {
  __typename?: 'MagicTableCell';
  companyId: Scalars['String'];
  createdAt: Scalars['DateTime'];
  createdBy: Scalars['String'];
  data: Scalars['String'];
  /** @DtoReadOnly */
  deletedAt?: Maybe<Scalars['DateTime']>;
  id: Scalars['ID'];
  magicTableColumn: MagicTableColumn;
  magicTableColumnId: Scalars['String'];
  magicTableRow: MagicTableRow;
  magicTableRowId: Scalars['String'];
  magicTableSheet: MagicTableSheet;
  magicTableSheetId: Scalars['String'];
  updatedAt: Scalars['DateTime'];
};

export type MagicTableColumn = {
  __typename?: 'MagicTableColumn';
  _count: MagicTableColumnCount;
  columnOrder: Scalars['Int'];
  companyId: Scalars['String'];
  createdAt: Scalars['DateTime'];
  createdBy: Scalars['String'];
  /** @DtoReadOnly */
  deletedAt?: Maybe<Scalars['DateTime']>;
  id: Scalars['ID'];
  magicTableCell?: Maybe<Array<MagicTableCell>>;
  magicTableSheet: MagicTableSheet;
  magicTableSheetId: Scalars['String'];
  updatedAt: Scalars['DateTime'];
};

export type MagicTableColumnCount = {
  __typename?: 'MagicTableColumnCount';
  magicTableCell: Scalars['Int'];
};

export type MagicTableImportColumnResponse = {
  __typename?: 'MagicTableImportColumnResponse';
  magicTableSheet?: Maybe<MagicTableSheet>;
  message?: Maybe<Scalars['String']>;
  status: Scalars['Boolean'];
};

export type MagicTableRow = {
  __typename?: 'MagicTableRow';
  _count: MagicTableRowCount;
  companyId: Scalars['String'];
  createdAt: Scalars['DateTime'];
  createdBy: Scalars['String'];
  /** @DtoReadOnly */
  deletedAt?: Maybe<Scalars['DateTime']>;
  id: Scalars['ID'];
  magicTableCell?: Maybe<Array<MagicTableCell>>;
  magicTableSheet: MagicTableSheet;
  magicTableSheetId: Scalars['String'];
  rowOrder: Scalars['Int'];
  updatedAt: Scalars['DateTime'];
};

export type MagicTableRowCount = {
  __typename?: 'MagicTableRowCount';
  magicTableCell: Scalars['Int'];
};

export type MagicTableSheet = {
  __typename?: 'MagicTableSheet';
  _count: MagicTableSheetCount;
  chat?: Maybe<Chat>;
  chatId?: Maybe<Scalars['String']>;
  companyId: Scalars['String'];
  createdAt: Scalars['DateTime'];
  createdBy: Scalars['String'];
  /** @DtoReadOnly */
  deletedAt?: Maybe<Scalars['DateTime']>;
  dueDiligence?: Maybe<DueDiligence>;
  id: Scalars['ID'];
  magicTableArtifact?: Maybe<Array<MagicTableArtifact>>;
  magicTableCell?: Maybe<Array<MagicTableCell>>;
  magicTableColumn?: Maybe<Array<MagicTableColumn>>;
  magicTableRow?: Maybe<Array<MagicTableRow>>;
  name: Scalars['String'];
  updatedAt: Scalars['DateTime'];
};

export type MagicTableSheetCount = {
  __typename?: 'MagicTableSheetCount';
  magicTableArtifact: Scalars['Int'];
  magicTableCell: Scalars['Int'];
  magicTableColumn: Scalars['Int'];
  magicTableRow: Scalars['Int'];
};

export type MagicTableSheetListRelationFilter = {
  every?: InputMaybe<MagicTableSheetWhereInput>;
  none?: InputMaybe<MagicTableSheetWhereInput>;
  some?: InputMaybe<MagicTableSheetWhereInput>;
};

export type MagicTableSheetOrderByRelationAggregateInput = {
  _count?: InputMaybe<SortOrder>;
};

export type MagicTableSheetOrderByWithRelationInput = {
  chat?: InputMaybe<ChatOrderByWithRelationInput>;
  chatId?: InputMaybe<SortOrder>;
  companyId?: InputMaybe<SortOrder>;
  createdAt?: InputMaybe<SortOrder>;
  createdBy?: InputMaybe<SortOrder>;
  deletedAt?: InputMaybe<SortOrder>;
  dueDiligence?: InputMaybe<DueDiligenceOrderByWithRelationInput>;
  id?: InputMaybe<SortOrder>;
  magicTableArtifact?: InputMaybe<MagicTableArtifactOrderByRelationAggregateInput>;
  name?: InputMaybe<SortOrder>;
  updatedAt?: InputMaybe<SortOrder>;
};

export type MagicTableSheetRelationFilter = {
  is?: InputMaybe<MagicTableSheetWhereInput>;
  isNot?: InputMaybe<MagicTableSheetWhereInput>;
};

export enum MagicTableSheetScalarFieldEnum {
  ChatId = 'chatId',
  CompanyId = 'companyId',
  CreatedAt = 'createdAt',
  CreatedBy = 'createdBy',
  DeletedAt = 'deletedAt',
  Id = 'id',
  Name = 'name',
  UpdatedAt = 'updatedAt'
}

export type MagicTableSheetWhereInput = {
  AND?: InputMaybe<Array<MagicTableSheetWhereInput>>;
  NOT?: InputMaybe<Array<MagicTableSheetWhereInput>>;
  OR?: InputMaybe<Array<MagicTableSheetWhereInput>>;
  chat?: InputMaybe<ChatRelationFilter>;
  chatId?: InputMaybe<StringNullableFilter>;
  companyId?: InputMaybe<StringFilter>;
  createdAt?: InputMaybe<DateTimeFilter>;
  createdBy?: InputMaybe<StringFilter>;
  deletedAt?: InputMaybe<DateTimeNullableFilter>;
  dueDiligence?: InputMaybe<DueDiligenceRelationFilter>;
  id?: InputMaybe<StringFilter>;
  magicTableArtifact?: InputMaybe<MagicTableArtifactListRelationFilter>;
  name?: InputMaybe<StringFilter>;
  updatedAt?: InputMaybe<DateTimeFilter>;
};

export type MagicTableSheetWhereUniqueInput = {
  id?: InputMaybe<Scalars['String']>;
};

export type Me = {
  __typename?: 'Me';
  assignments: Array<Assignments>;
  user: User;
};

export type MemberSearchResult = {
  __typename?: 'MemberSearchResult';
  groupMembersCount?: Maybe<Scalars['Float']>;
  id: Scalars['ID'];
  name: Scalars['String'];
  type: MemberSearchResultType;
};

export enum MemberSearchResultType {
  Group = 'GROUP',
  User = 'USER'
}

export type Membership = {
  __typename?: 'Membership';
  createdAt: Scalars['DateTime'];
  entityId: Scalars['String'];
  group: Group;
  groupId: Scalars['String'];
  updatedAt: Scalars['DateTime'];
};

export type MembershipCreateManyGroupInput = {
  entityId: Scalars['String'];
};

export type MembershipCreateManyGroupInputEnvelope = {
  data: Array<MembershipCreateManyGroupInput>;
  skipDuplicates?: InputMaybe<Scalars['Boolean']>;
};

export type MembershipCreateNestedManyWithoutGroupInput = {
  connect?: InputMaybe<Array<MembershipWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<MembershipCreateOrConnectWithoutGroupInput>>;
  create?: InputMaybe<Array<MembershipCreateWithoutGroupInput>>;
  createMany?: InputMaybe<MembershipCreateManyGroupInputEnvelope>;
};

export type MembershipCreateOrConnectWithoutGroupInput = {
  create: MembershipCreateWithoutGroupInput;
  where: MembershipWhereUniqueInput;
};

export type MembershipCreateWithoutGroupInput = {
  entityId: Scalars['String'];
};

export type MembershipEntityIdGroupIdCompoundUniqueInput = {
  entityId: Scalars['String'];
  groupId: Scalars['String'];
};

export type MembershipListRelationFilter = {
  every?: InputMaybe<MembershipWhereInput>;
  none?: InputMaybe<MembershipWhereInput>;
  some?: InputMaybe<MembershipWhereInput>;
};

export type MembershipOrderByRelationAggregateInput = {
  _count?: InputMaybe<SortOrder>;
};

export type MembershipOrderByWithRelationInput = {
  entityId?: InputMaybe<SortOrder>;
  group?: InputMaybe<GroupOrderByWithRelationInput>;
  groupId?: InputMaybe<SortOrder>;
};

export enum MembershipScalarFieldEnum {
  CreatedAt = 'createdAt',
  EntityId = 'entityId',
  GroupId = 'groupId',
  UpdatedAt = 'updatedAt'
}

export type MembershipScalarWhereInput = {
  AND?: InputMaybe<Array<MembershipScalarWhereInput>>;
  NOT?: InputMaybe<Array<MembershipScalarWhereInput>>;
  OR?: InputMaybe<Array<MembershipScalarWhereInput>>;
  entityId?: InputMaybe<StringFilter>;
  groupId?: InputMaybe<StringFilter>;
};

export type MembershipUpdateManyMutationInput = {
  entityId?: InputMaybe<Scalars['String']>;
};

export type MembershipUpdateManyWithWhereWithoutGroupInput = {
  data: MembershipUpdateManyMutationInput;
  where: MembershipScalarWhereInput;
};

export type MembershipUpdateManyWithoutGroupNestedInput = {
  connect?: InputMaybe<Array<MembershipWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<MembershipCreateOrConnectWithoutGroupInput>>;
  create?: InputMaybe<Array<MembershipCreateWithoutGroupInput>>;
  createMany?: InputMaybe<MembershipCreateManyGroupInputEnvelope>;
  delete?: InputMaybe<Array<MembershipWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<MembershipScalarWhereInput>>;
  disconnect?: InputMaybe<Array<MembershipWhereUniqueInput>>;
  set?: InputMaybe<Array<MembershipWhereUniqueInput>>;
  update?: InputMaybe<Array<MembershipUpdateWithWhereUniqueWithoutGroupInput>>;
  updateMany?: InputMaybe<Array<MembershipUpdateManyWithWhereWithoutGroupInput>>;
  upsert?: InputMaybe<Array<MembershipUpsertWithWhereUniqueWithoutGroupInput>>;
};

export type MembershipUpdateWithWhereUniqueWithoutGroupInput = {
  data: MembershipUpdateWithoutGroupInput;
  where: MembershipWhereUniqueInput;
};

export type MembershipUpdateWithoutGroupInput = {
  entityId?: InputMaybe<Scalars['String']>;
};

export type MembershipUpsertWithWhereUniqueWithoutGroupInput = {
  create: MembershipCreateWithoutGroupInput;
  update: MembershipUpdateWithoutGroupInput;
  where: MembershipWhereUniqueInput;
};

export type MembershipWhereInput = {
  AND?: InputMaybe<Array<MembershipWhereInput>>;
  NOT?: InputMaybe<Array<MembershipWhereInput>>;
  OR?: InputMaybe<Array<MembershipWhereInput>>;
  entityId?: InputMaybe<StringFilter>;
  group?: InputMaybe<GroupRelationFilter>;
  groupId?: InputMaybe<StringFilter>;
};

export type MembershipWhereUniqueInput = {
  entityId_groupId?: InputMaybe<MembershipEntityIdGroupIdCompoundUniqueInput>;
};

export type Message = {
  __typename?: 'Message';
  ShortTermMemory?: Maybe<Array<ShortTermMemory>>;
  _count: MessageCount;
  chat?: Maybe<Chat>;
  chatId: Scalars['String'];
  completedAt?: Maybe<Scalars['DateTime']>;
  createdAt: Scalars['DateTime'];
  debugInfo?: Maybe<Scalars['JSON']>;
  /** @DtoReadOnly */
  deletedAt?: Maybe<Scalars['DateTime']>;
  feedback?: Maybe<Feedback>;
  gptRequest?: Maybe<Scalars['JSON']>;
  id: Scalars['ID'];
  isBenchmarkingMessage: Scalars['Boolean'];
  messages?: Maybe<Array<Message>>;
  originalText?: Maybe<Scalars['String']>;
  previousMessage?: Maybe<Message>;
  references?: Maybe<Array<Reference>>;
  role: Role;
  startedStreamingAt?: Maybe<Scalars['DateTime']>;
  stoppedStreamingAt?: Maybe<Scalars['DateTime']>;
  text?: Maybe<Scalars['String']>;
  translateToLanguage?: Maybe<Scalars['String']>;
  updatedAt: Scalars['DateTime'];
  usedForLastPrompt: Scalars['Boolean'];
  usedTokens: Scalars['Int'];
  userId?: Maybe<Scalars['String']>;
};

export type MessageCount = {
  __typename?: 'MessageCount';
  ShortTermMemory: Scalars['Int'];
  messages: Scalars['Int'];
  references: Scalars['Int'];
};

export type MessageCreateInput = {
  completedAt?: InputMaybe<Scalars['DateTime']>;
  createdAt?: InputMaybe<Scalars['DateTime']>;
  deletedAt?: InputMaybe<Scalars['DateTime']>;
  feedback?: InputMaybe<FeedbackCreateNestedOneWithoutMessageInput>;
  id?: InputMaybe<Scalars['String']>;
  isBenchmarkingMessage?: InputMaybe<Scalars['Boolean']>;
  messages?: InputMaybe<MessageCreateNestedManyWithoutPreviousMessageInput>;
  originalText?: InputMaybe<Scalars['String']>;
  previousMessage?: InputMaybe<MessageCreateNestedOneWithoutMessagesInput>;
  role: Role;
  text?: InputMaybe<Scalars['String']>;
  translateToLanguage?: InputMaybe<Scalars['String']>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
  userId?: InputMaybe<Scalars['String']>;
};

export type MessageCreateManyPreviousMessageInput = {
  createdAt?: InputMaybe<Scalars['DateTime']>;
  deletedAt?: InputMaybe<Scalars['DateTime']>;
  id?: InputMaybe<Scalars['String']>;
  isBenchmarkingMessage?: InputMaybe<Scalars['Boolean']>;
  originalText?: InputMaybe<Scalars['String']>;
  role: Role;
  text?: InputMaybe<Scalars['String']>;
  translateToLanguage?: InputMaybe<Scalars['String']>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
  userId?: InputMaybe<Scalars['String']>;
};

export type MessageCreateManyPreviousMessageInputEnvelope = {
  data: Array<MessageCreateManyPreviousMessageInput>;
  skipDuplicates?: InputMaybe<Scalars['Boolean']>;
};

export type MessageCreateNestedManyWithoutPreviousMessageInput = {
  connect?: InputMaybe<Array<MessageWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<MessageCreateOrConnectWithoutPreviousMessageInput>>;
  create?: InputMaybe<Array<MessageCreateWithoutPreviousMessageInput>>;
  createMany?: InputMaybe<MessageCreateManyPreviousMessageInputEnvelope>;
};

export type MessageCreateNestedOneWithoutMessagesInput = {
  connect?: InputMaybe<MessageWhereUniqueInput>;
  connectOrCreate?: InputMaybe<MessageCreateOrConnectWithoutMessagesInput>;
  create?: InputMaybe<MessageCreateWithoutMessagesInput>;
};

export type MessageCreateOrConnectWithoutMessagesInput = {
  create: MessageCreateWithoutMessagesInput;
  where: MessageWhereUniqueInput;
};

export type MessageCreateOrConnectWithoutPreviousMessageInput = {
  create: MessageCreateWithoutPreviousMessageInput;
  where: MessageWhereUniqueInput;
};

export type MessageCreateWithoutMessagesInput = {
  createdAt?: InputMaybe<Scalars['DateTime']>;
  deletedAt?: InputMaybe<Scalars['DateTime']>;
  feedback?: InputMaybe<FeedbackCreateNestedOneWithoutMessageInput>;
  id?: InputMaybe<Scalars['String']>;
  isBenchmarkingMessage?: InputMaybe<Scalars['Boolean']>;
  originalText?: InputMaybe<Scalars['String']>;
  previousMessage?: InputMaybe<MessageCreateNestedOneWithoutMessagesInput>;
  role: Role;
  text?: InputMaybe<Scalars['String']>;
  translateToLanguage?: InputMaybe<Scalars['String']>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
  userId?: InputMaybe<Scalars['String']>;
};

export type MessageCreateWithoutPreviousMessageInput = {
  createdAt?: InputMaybe<Scalars['DateTime']>;
  deletedAt?: InputMaybe<Scalars['DateTime']>;
  feedback?: InputMaybe<FeedbackCreateNestedOneWithoutMessageInput>;
  id?: InputMaybe<Scalars['String']>;
  isBenchmarkingMessage?: InputMaybe<Scalars['Boolean']>;
  messages?: InputMaybe<MessageCreateNestedManyWithoutPreviousMessageInput>;
  originalText?: InputMaybe<Scalars['String']>;
  role: Role;
  text?: InputMaybe<Scalars['String']>;
  translateToLanguage?: InputMaybe<Scalars['String']>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
  userId?: InputMaybe<Scalars['String']>;
};

export type MessageListRelationFilter = {
  every?: InputMaybe<MessageWhereInput>;
  none?: InputMaybe<MessageWhereInput>;
  some?: InputMaybe<MessageWhereInput>;
};

export type MessageOrderByRelationAggregateInput = {
  _count?: InputMaybe<SortOrder>;
};

export type MessageOrderByWithRelationInput = {
  createdAt?: InputMaybe<SortOrder>;
  deletedAt?: InputMaybe<SortOrder>;
  feedback?: InputMaybe<FeedbackOrderByWithRelationInput>;
  id?: InputMaybe<SortOrder>;
  isBenchmarkingMessage?: InputMaybe<SortOrder>;
  messages?: InputMaybe<MessageOrderByRelationAggregateInput>;
  originalText?: InputMaybe<SortOrder>;
  previousMessage?: InputMaybe<MessageOrderByWithRelationInput>;
  role?: InputMaybe<SortOrder>;
  text?: InputMaybe<SortOrder>;
  translateToLanguage?: InputMaybe<SortOrder>;
  updatedAt?: InputMaybe<SortOrder>;
  userId?: InputMaybe<SortOrder>;
};

export type MessageRelationFilter = {
  is?: InputMaybe<MessageWhereInput>;
  isNot?: InputMaybe<MessageWhereInput>;
};

export enum MessageScalarFieldEnum {
  ChatId = 'chatId',
  CompletedAt = 'completedAt',
  CreatedAt = 'createdAt',
  DebugInfo = 'debugInfo',
  DeletedAt = 'deletedAt',
  GptRequest = 'gptRequest',
  Id = 'id',
  IsBenchmarkingMessage = 'isBenchmarkingMessage',
  OriginalText = 'originalText',
  PreviousMessageId = 'previousMessageId',
  Role = 'role',
  StartedStreamingAt = 'startedStreamingAt',
  StoppedStreamingAt = 'stoppedStreamingAt',
  Text = 'text',
  TranslateToLanguage = 'translateToLanguage',
  UpdatedAt = 'updatedAt',
  UsedForLastPrompt = 'usedForLastPrompt',
  UsedTokens = 'usedTokens',
  UserId = 'userId'
}

export type MessageScalarWhereInput = {
  AND?: InputMaybe<Array<MessageScalarWhereInput>>;
  NOT?: InputMaybe<Array<MessageScalarWhereInput>>;
  OR?: InputMaybe<Array<MessageScalarWhereInput>>;
  createdAt?: InputMaybe<DateTimeFilter>;
  deletedAt?: InputMaybe<DateTimeNullableFilter>;
  id?: InputMaybe<StringFilter>;
  isBenchmarkingMessage?: InputMaybe<BoolFilter>;
  originalText?: InputMaybe<StringNullableFilter>;
  role?: InputMaybe<EnumRoleFilter>;
  text?: InputMaybe<StringNullableFilter>;
  translateToLanguage?: InputMaybe<StringNullableFilter>;
  updatedAt?: InputMaybe<DateTimeFilter>;
  userId?: InputMaybe<StringNullableFilter>;
};

export type MessageUpdateInput = {
  completedAt?: InputMaybe<Scalars['DateTime']>;
  createdAt?: InputMaybe<Scalars['DateTime']>;
  deletedAt?: InputMaybe<Scalars['DateTime']>;
  feedback?: InputMaybe<FeedbackUpdateOneWithoutMessageNestedInput>;
  id?: InputMaybe<Scalars['String']>;
  isBenchmarkingMessage?: InputMaybe<Scalars['Boolean']>;
  messages?: InputMaybe<MessageUpdateManyWithoutPreviousMessageNestedInput>;
  originalText?: InputMaybe<Scalars['String']>;
  previousMessage?: InputMaybe<MessageUpdateOneWithoutMessagesNestedInput>;
  role?: InputMaybe<Role>;
  stoppedStreamingAt?: InputMaybe<Scalars['DateTime']>;
  text?: InputMaybe<Scalars['String']>;
  translateToLanguage?: InputMaybe<Scalars['String']>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
  userId?: InputMaybe<Scalars['String']>;
};

export type MessageUpdateManyMutationInput = {
  createdAt?: InputMaybe<Scalars['DateTime']>;
  deletedAt?: InputMaybe<Scalars['DateTime']>;
  id?: InputMaybe<Scalars['String']>;
  isBenchmarkingMessage?: InputMaybe<Scalars['Boolean']>;
  originalText?: InputMaybe<Scalars['String']>;
  role?: InputMaybe<Role>;
  text?: InputMaybe<Scalars['String']>;
  translateToLanguage?: InputMaybe<Scalars['String']>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
  userId?: InputMaybe<Scalars['String']>;
};

export type MessageUpdateManyWithWhereWithoutPreviousMessageInput = {
  data: MessageUpdateManyMutationInput;
  where: MessageScalarWhereInput;
};

export type MessageUpdateManyWithoutPreviousMessageNestedInput = {
  connect?: InputMaybe<Array<MessageWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<MessageCreateOrConnectWithoutPreviousMessageInput>>;
  create?: InputMaybe<Array<MessageCreateWithoutPreviousMessageInput>>;
  createMany?: InputMaybe<MessageCreateManyPreviousMessageInputEnvelope>;
  delete?: InputMaybe<Array<MessageWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<MessageScalarWhereInput>>;
  disconnect?: InputMaybe<Array<MessageWhereUniqueInput>>;
  set?: InputMaybe<Array<MessageWhereUniqueInput>>;
  update?: InputMaybe<Array<MessageUpdateWithWhereUniqueWithoutPreviousMessageInput>>;
  updateMany?: InputMaybe<Array<MessageUpdateManyWithWhereWithoutPreviousMessageInput>>;
  upsert?: InputMaybe<Array<MessageUpsertWithWhereUniqueWithoutPreviousMessageInput>>;
};

export type MessageUpdateOneWithoutMessagesNestedInput = {
  connect?: InputMaybe<MessageWhereUniqueInput>;
  connectOrCreate?: InputMaybe<MessageCreateOrConnectWithoutMessagesInput>;
  create?: InputMaybe<MessageCreateWithoutMessagesInput>;
  delete?: InputMaybe<Scalars['Boolean']>;
  disconnect?: InputMaybe<Scalars['Boolean']>;
  update?: InputMaybe<MessageUpdateWithoutMessagesInput>;
  upsert?: InputMaybe<MessageUpsertWithoutMessagesInput>;
};

export type MessageUpdateWithWhereUniqueWithoutPreviousMessageInput = {
  data: MessageUpdateWithoutPreviousMessageInput;
  where: MessageWhereUniqueInput;
};

export type MessageUpdateWithoutMessagesInput = {
  createdAt?: InputMaybe<Scalars['DateTime']>;
  deletedAt?: InputMaybe<Scalars['DateTime']>;
  feedback?: InputMaybe<FeedbackUpdateOneWithoutMessageNestedInput>;
  id?: InputMaybe<Scalars['String']>;
  isBenchmarkingMessage?: InputMaybe<Scalars['Boolean']>;
  originalText?: InputMaybe<Scalars['String']>;
  previousMessage?: InputMaybe<MessageUpdateOneWithoutMessagesNestedInput>;
  role?: InputMaybe<Role>;
  text?: InputMaybe<Scalars['String']>;
  translateToLanguage?: InputMaybe<Scalars['String']>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
  userId?: InputMaybe<Scalars['String']>;
};

export type MessageUpdateWithoutPreviousMessageInput = {
  createdAt?: InputMaybe<Scalars['DateTime']>;
  deletedAt?: InputMaybe<Scalars['DateTime']>;
  feedback?: InputMaybe<FeedbackUpdateOneWithoutMessageNestedInput>;
  id?: InputMaybe<Scalars['String']>;
  isBenchmarkingMessage?: InputMaybe<Scalars['Boolean']>;
  messages?: InputMaybe<MessageUpdateManyWithoutPreviousMessageNestedInput>;
  originalText?: InputMaybe<Scalars['String']>;
  role?: InputMaybe<Role>;
  text?: InputMaybe<Scalars['String']>;
  translateToLanguage?: InputMaybe<Scalars['String']>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
  userId?: InputMaybe<Scalars['String']>;
};

export type MessageUpsertWithWhereUniqueWithoutPreviousMessageInput = {
  create: MessageCreateWithoutPreviousMessageInput;
  update: MessageUpdateWithoutPreviousMessageInput;
  where: MessageWhereUniqueInput;
};

export type MessageUpsertWithoutMessagesInput = {
  create: MessageCreateWithoutMessagesInput;
  update: MessageUpdateWithoutMessagesInput;
};

export type MessageWhereInput = {
  AND?: InputMaybe<Array<MessageWhereInput>>;
  NOT?: InputMaybe<Array<MessageWhereInput>>;
  OR?: InputMaybe<Array<MessageWhereInput>>;
  completedAt?: InputMaybe<DateTimeNullableFilter>;
  createdAt?: InputMaybe<DateTimeFilter>;
  deletedAt?: InputMaybe<DateTimeNullableFilter>;
  feedback?: InputMaybe<FeedbackRelationFilter>;
  id?: InputMaybe<StringFilter>;
  isBenchmarkingMessage?: InputMaybe<BoolFilter>;
  messages?: InputMaybe<MessageListRelationFilter>;
  originalText?: InputMaybe<StringNullableFilter>;
  previousMessage?: InputMaybe<MessageRelationFilter>;
  role?: InputMaybe<EnumRoleFilter>;
  startedStreamingAt?: InputMaybe<DateTimeNullableFilter>;
  stoppedStreamingAt?: InputMaybe<DateTimeNullableFilter>;
  text?: InputMaybe<StringNullableFilter>;
  translateToLanguage?: InputMaybe<StringNullableFilter>;
  updatedAt?: InputMaybe<DateTimeFilter>;
  userId?: InputMaybe<StringNullableFilter>;
};

export type MessageWhereUniqueInput = {
  id?: InputMaybe<Scalars['String']>;
};

export type Module = {
  __typename?: 'Module';
  assistant: Assistant;
  assistantId: Scalars['String'];
  configuration: Scalars['JSON'];
  createdAt: Scalars['DateTime'];
  description?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  isExternal: Scalars['Boolean'];
  moduleTemplate?: Maybe<ModuleTemplate>;
  moduleTemplateId?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  toolDefinition?: Maybe<Scalars['JSON']>;
  updatedAt: Scalars['DateTime'];
  weight: Scalars['Int'];
};

export type ModuleCreateInput = {
  configuration?: InputMaybe<Scalars['JSON']>;
  description?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['String']>;
  isExternal?: InputMaybe<Scalars['Boolean']>;
  moduleTemplate?: InputMaybe<ModuleTemplateCreateNestedOneWithoutModulesInput>;
  name: Scalars['String'];
  toolDefinition?: InputMaybe<Scalars['JSON']>;
  weight?: InputMaybe<Scalars['Int']>;
};

export type ModuleCreateManyAssistantInput = {
  configuration?: InputMaybe<Scalars['JSON']>;
  description?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['String']>;
  isExternal?: InputMaybe<Scalars['Boolean']>;
  moduleTemplateId?: InputMaybe<Scalars['String']>;
  name: Scalars['String'];
  toolDefinition?: InputMaybe<Scalars['JSON']>;
  weight?: InputMaybe<Scalars['Int']>;
};

export type ModuleCreateManyAssistantInputEnvelope = {
  data: Array<ModuleCreateManyAssistantInput>;
  skipDuplicates?: InputMaybe<Scalars['Boolean']>;
};

export type ModuleCreateManyModuleTemplateInput = {
  configuration?: InputMaybe<Scalars['JSON']>;
  description?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['String']>;
  isExternal?: InputMaybe<Scalars['Boolean']>;
  name: Scalars['String'];
  toolDefinition?: InputMaybe<Scalars['JSON']>;
  weight?: InputMaybe<Scalars['Int']>;
};

export type ModuleCreateManyModuleTemplateInputEnvelope = {
  data: Array<ModuleCreateManyModuleTemplateInput>;
  skipDuplicates?: InputMaybe<Scalars['Boolean']>;
};

export type ModuleCreateNestedManyWithoutAssistantInput = {
  connect?: InputMaybe<Array<ModuleWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<ModuleCreateOrConnectWithoutAssistantInput>>;
  create?: InputMaybe<Array<ModuleCreateWithoutAssistantInput>>;
  createMany?: InputMaybe<ModuleCreateManyAssistantInputEnvelope>;
};

export type ModuleCreateNestedManyWithoutModuleTemplateInput = {
  connect?: InputMaybe<Array<ModuleWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<ModuleCreateOrConnectWithoutModuleTemplateInput>>;
  create?: InputMaybe<Array<ModuleCreateWithoutModuleTemplateInput>>;
  createMany?: InputMaybe<ModuleCreateManyModuleTemplateInputEnvelope>;
};

export type ModuleCreateOrConnectWithoutAssistantInput = {
  create: ModuleCreateWithoutAssistantInput;
  where: ModuleWhereUniqueInput;
};

export type ModuleCreateOrConnectWithoutModuleTemplateInput = {
  create: ModuleCreateWithoutModuleTemplateInput;
  where: ModuleWhereUniqueInput;
};

export type ModuleCreateWithoutAssistantInput = {
  configuration?: InputMaybe<Scalars['JSON']>;
  description?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['String']>;
  isExternal?: InputMaybe<Scalars['Boolean']>;
  moduleTemplate?: InputMaybe<ModuleTemplateCreateNestedOneWithoutModulesInput>;
  name: Scalars['String'];
  toolDefinition?: InputMaybe<Scalars['JSON']>;
  weight?: InputMaybe<Scalars['Int']>;
};

export type ModuleCreateWithoutModuleTemplateInput = {
  configuration?: InputMaybe<Scalars['JSON']>;
  description?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['String']>;
  isExternal?: InputMaybe<Scalars['Boolean']>;
  name: Scalars['String'];
  toolDefinition?: InputMaybe<Scalars['JSON']>;
  weight?: InputMaybe<Scalars['Int']>;
};

export type ModuleListRelationFilter = {
  every?: InputMaybe<ModuleWhereInput>;
  none?: InputMaybe<ModuleWhereInput>;
  some?: InputMaybe<ModuleWhereInput>;
};

export type ModuleOrderByRelationAggregateInput = {
  _count?: InputMaybe<SortOrder>;
};

export type ModuleOrderByWithRelationInput = {
  configuration?: InputMaybe<SortOrder>;
  description?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  isExternal?: InputMaybe<SortOrder>;
  moduleTemplate?: InputMaybe<ModuleTemplateOrderByWithRelationInput>;
  moduleTemplateId?: InputMaybe<SortOrder>;
  name?: InputMaybe<SortOrder>;
  toolDefinition?: InputMaybe<SortOrder>;
  weight?: InputMaybe<SortOrder>;
};

export enum ModuleScalarFieldEnum {
  AssistantId = 'assistantId',
  Configuration = 'configuration',
  CreatedAt = 'createdAt',
  Description = 'description',
  Id = 'id',
  IsExternal = 'isExternal',
  ModuleTemplateId = 'moduleTemplateId',
  Name = 'name',
  ToolDefinition = 'toolDefinition',
  UpdatedAt = 'updatedAt',
  Weight = 'weight'
}

export type ModuleScalarWhereInput = {
  AND?: InputMaybe<Array<ModuleScalarWhereInput>>;
  NOT?: InputMaybe<Array<ModuleScalarWhereInput>>;
  OR?: InputMaybe<Array<ModuleScalarWhereInput>>;
  configuration?: InputMaybe<JsonFilter>;
  description?: InputMaybe<StringNullableFilter>;
  id?: InputMaybe<StringFilter>;
  isExternal?: InputMaybe<BoolFilter>;
  moduleTemplateId?: InputMaybe<StringNullableFilter>;
  name?: InputMaybe<StringFilter>;
  toolDefinition?: InputMaybe<JsonNullableFilter>;
  weight?: InputMaybe<IntFilter>;
};

export type ModuleTemplate = {
  __typename?: 'ModuleTemplate';
  _count: ModuleTemplateCount;
  company: Company;
  companyId: Scalars['String'];
  configuration: Scalars['JSON'];
  createdAt: Scalars['DateTime'];
  id: Scalars['ID'];
  isExternal: Scalars['Boolean'];
  modules?: Maybe<Array<Module>>;
  name: Scalars['String'];
  templateName: Scalars['String'];
  toolDefinition: Scalars['JSON'];
  updatedAt: Scalars['DateTime'];
  weight: Scalars['Int'];
};

export type ModuleTemplateCount = {
  __typename?: 'ModuleTemplateCount';
  modules: Scalars['Int'];
};

export type ModuleTemplateCreateManyCompanyInput = {
  configuration?: InputMaybe<Scalars['JSON']>;
  id?: InputMaybe<Scalars['String']>;
  isExternal?: InputMaybe<Scalars['Boolean']>;
  name: Scalars['String'];
  templateName: Scalars['String'];
  toolDefinition?: InputMaybe<Scalars['JSON']>;
  weight?: InputMaybe<Scalars['Int']>;
};

export type ModuleTemplateCreateManyCompanyInputEnvelope = {
  data: Array<ModuleTemplateCreateManyCompanyInput>;
  skipDuplicates?: InputMaybe<Scalars['Boolean']>;
};

export type ModuleTemplateCreateNestedOneWithoutModulesInput = {
  connect?: InputMaybe<ModuleTemplateWhereUniqueInput>;
  connectOrCreate?: InputMaybe<ModuleTemplateCreateOrConnectWithoutModulesInput>;
  create?: InputMaybe<ModuleTemplateCreateWithoutModulesInput>;
};

export type ModuleTemplateCreateOrConnectWithoutCompanyInput = {
  create: ModuleTemplateCreateWithoutCompanyInput;
  where: ModuleTemplateWhereUniqueInput;
};

export type ModuleTemplateCreateOrConnectWithoutModulesInput = {
  create: ModuleTemplateCreateWithoutModulesInput;
  where: ModuleTemplateWhereUniqueInput;
};

export type ModuleTemplateCreateWithoutCompanyInput = {
  configuration?: InputMaybe<Scalars['JSON']>;
  id?: InputMaybe<Scalars['String']>;
  isExternal?: InputMaybe<Scalars['Boolean']>;
  modules?: InputMaybe<ModuleCreateNestedManyWithoutModuleTemplateInput>;
  name: Scalars['String'];
  templateName: Scalars['String'];
  toolDefinition?: InputMaybe<Scalars['JSON']>;
  weight?: InputMaybe<Scalars['Int']>;
};

export type ModuleTemplateCreateWithoutModulesInput = {
  company: CompanyCreateNestedOneWithoutModuleTemplateInput;
  configuration?: InputMaybe<Scalars['JSON']>;
  id?: InputMaybe<Scalars['String']>;
  isExternal?: InputMaybe<Scalars['Boolean']>;
  name: Scalars['String'];
  templateName: Scalars['String'];
  toolDefinition?: InputMaybe<Scalars['JSON']>;
  weight?: InputMaybe<Scalars['Int']>;
};

export type ModuleTemplateListRelationFilter = {
  every?: InputMaybe<ModuleTemplateWhereInput>;
  none?: InputMaybe<ModuleTemplateWhereInput>;
  some?: InputMaybe<ModuleTemplateWhereInput>;
};

export type ModuleTemplateOrderByRelationAggregateInput = {
  _count?: InputMaybe<SortOrder>;
};

export type ModuleTemplateOrderByWithRelationInput = {
  company?: InputMaybe<CompanyOrderByWithRelationInput>;
  configuration?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  isExternal?: InputMaybe<SortOrder>;
  modules?: InputMaybe<ModuleOrderByRelationAggregateInput>;
  name?: InputMaybe<SortOrder>;
  templateName?: InputMaybe<SortOrder>;
  toolDefinition?: InputMaybe<SortOrder>;
  weight?: InputMaybe<SortOrder>;
};

export type ModuleTemplateRelationFilter = {
  is?: InputMaybe<ModuleTemplateWhereInput>;
  isNot?: InputMaybe<ModuleTemplateWhereInput>;
};

export enum ModuleTemplateScalarFieldEnum {
  CompanyId = 'companyId',
  Configuration = 'configuration',
  CreatedAt = 'createdAt',
  Id = 'id',
  IsExternal = 'isExternal',
  Name = 'name',
  TemplateName = 'templateName',
  ToolDefinition = 'toolDefinition',
  UpdatedAt = 'updatedAt',
  Weight = 'weight'
}

export type ModuleTemplateScalarWhereInput = {
  AND?: InputMaybe<Array<ModuleTemplateScalarWhereInput>>;
  NOT?: InputMaybe<Array<ModuleTemplateScalarWhereInput>>;
  OR?: InputMaybe<Array<ModuleTemplateScalarWhereInput>>;
  configuration?: InputMaybe<JsonFilter>;
  id?: InputMaybe<StringFilter>;
  isExternal?: InputMaybe<BoolFilter>;
  name?: InputMaybe<StringFilter>;
  templateName?: InputMaybe<StringFilter>;
  toolDefinition?: InputMaybe<JsonFilter>;
  weight?: InputMaybe<IntFilter>;
};

export type ModuleTemplateUpdateInput = {
  company?: InputMaybe<CompanyUpdateOneRequiredWithoutModuleTemplateNestedInput>;
  configuration?: InputMaybe<Scalars['JSON']>;
  id?: InputMaybe<Scalars['String']>;
  isExternal?: InputMaybe<Scalars['Boolean']>;
  modules?: InputMaybe<ModuleUpdateManyWithoutModuleTemplateNestedInput>;
  name?: InputMaybe<Scalars['String']>;
  templateName?: InputMaybe<Scalars['String']>;
  toolDefinition?: InputMaybe<Scalars['JSON']>;
  weight?: InputMaybe<Scalars['Int']>;
};

export type ModuleTemplateUpdateManyMutationInput = {
  configuration?: InputMaybe<Scalars['JSON']>;
  id?: InputMaybe<Scalars['String']>;
  isExternal?: InputMaybe<Scalars['Boolean']>;
  name?: InputMaybe<Scalars['String']>;
  templateName?: InputMaybe<Scalars['String']>;
  toolDefinition?: InputMaybe<Scalars['JSON']>;
  weight?: InputMaybe<Scalars['Int']>;
};

export type ModuleTemplateUpdateManyWithWhereWithoutCompanyInput = {
  data: ModuleTemplateUpdateManyMutationInput;
  where: ModuleTemplateScalarWhereInput;
};

export type ModuleTemplateUpdateManyWithoutCompanyNestedInput = {
  connect?: InputMaybe<Array<ModuleTemplateWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<ModuleTemplateCreateOrConnectWithoutCompanyInput>>;
  create?: InputMaybe<Array<ModuleTemplateCreateWithoutCompanyInput>>;
  createMany?: InputMaybe<ModuleTemplateCreateManyCompanyInputEnvelope>;
  delete?: InputMaybe<Array<ModuleTemplateWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<ModuleTemplateScalarWhereInput>>;
  disconnect?: InputMaybe<Array<ModuleTemplateWhereUniqueInput>>;
  set?: InputMaybe<Array<ModuleTemplateWhereUniqueInput>>;
  update?: InputMaybe<Array<ModuleTemplateUpdateWithWhereUniqueWithoutCompanyInput>>;
  updateMany?: InputMaybe<Array<ModuleTemplateUpdateManyWithWhereWithoutCompanyInput>>;
  upsert?: InputMaybe<Array<ModuleTemplateUpsertWithWhereUniqueWithoutCompanyInput>>;
};

export type ModuleTemplateUpdateOneWithoutModulesNestedInput = {
  connect?: InputMaybe<ModuleTemplateWhereUniqueInput>;
  connectOrCreate?: InputMaybe<ModuleTemplateCreateOrConnectWithoutModulesInput>;
  create?: InputMaybe<ModuleTemplateCreateWithoutModulesInput>;
  delete?: InputMaybe<Scalars['Boolean']>;
  disconnect?: InputMaybe<Scalars['Boolean']>;
  update?: InputMaybe<ModuleTemplateUpdateWithoutModulesInput>;
  upsert?: InputMaybe<ModuleTemplateUpsertWithoutModulesInput>;
};

export type ModuleTemplateUpdateWithWhereUniqueWithoutCompanyInput = {
  data: ModuleTemplateUpdateWithoutCompanyInput;
  where: ModuleTemplateWhereUniqueInput;
};

export type ModuleTemplateUpdateWithoutCompanyInput = {
  configuration?: InputMaybe<Scalars['JSON']>;
  id?: InputMaybe<Scalars['String']>;
  isExternal?: InputMaybe<Scalars['Boolean']>;
  modules?: InputMaybe<ModuleUpdateManyWithoutModuleTemplateNestedInput>;
  name?: InputMaybe<Scalars['String']>;
  templateName?: InputMaybe<Scalars['String']>;
  toolDefinition?: InputMaybe<Scalars['JSON']>;
  weight?: InputMaybe<Scalars['Int']>;
};

export type ModuleTemplateUpdateWithoutModulesInput = {
  company?: InputMaybe<CompanyUpdateOneRequiredWithoutModuleTemplateNestedInput>;
  configuration?: InputMaybe<Scalars['JSON']>;
  id?: InputMaybe<Scalars['String']>;
  isExternal?: InputMaybe<Scalars['Boolean']>;
  name?: InputMaybe<Scalars['String']>;
  templateName?: InputMaybe<Scalars['String']>;
  toolDefinition?: InputMaybe<Scalars['JSON']>;
  weight?: InputMaybe<Scalars['Int']>;
};

export type ModuleTemplateUpsertWithWhereUniqueWithoutCompanyInput = {
  create: ModuleTemplateCreateWithoutCompanyInput;
  update: ModuleTemplateUpdateWithoutCompanyInput;
  where: ModuleTemplateWhereUniqueInput;
};

export type ModuleTemplateUpsertWithoutModulesInput = {
  create: ModuleTemplateCreateWithoutModulesInput;
  update: ModuleTemplateUpdateWithoutModulesInput;
};

export type ModuleTemplateWhereInput = {
  AND?: InputMaybe<Array<ModuleTemplateWhereInput>>;
  NOT?: InputMaybe<Array<ModuleTemplateWhereInput>>;
  OR?: InputMaybe<Array<ModuleTemplateWhereInput>>;
  company?: InputMaybe<CompanyRelationFilter>;
  configuration?: InputMaybe<JsonFilter>;
  id?: InputMaybe<StringFilter>;
  isExternal?: InputMaybe<BoolFilter>;
  modules?: InputMaybe<ModuleListRelationFilter>;
  name?: InputMaybe<StringFilter>;
  templateName?: InputMaybe<StringFilter>;
  toolDefinition?: InputMaybe<JsonFilter>;
  weight?: InputMaybe<IntFilter>;
};

export type ModuleTemplateWhereUniqueInput = {
  id?: InputMaybe<Scalars['String']>;
};

export type ModuleUpdateInput = {
  configuration?: InputMaybe<Scalars['JSON']>;
  description?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['String']>;
  isExternal?: InputMaybe<Scalars['Boolean']>;
  moduleTemplate?: InputMaybe<ModuleTemplateUpdateOneWithoutModulesNestedInput>;
  name?: InputMaybe<Scalars['String']>;
  toolDefinition?: InputMaybe<Scalars['JSON']>;
  weight?: InputMaybe<Scalars['Int']>;
};

export type ModuleUpdateManyMutationInput = {
  configuration?: InputMaybe<Scalars['JSON']>;
  description?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['String']>;
  isExternal?: InputMaybe<Scalars['Boolean']>;
  name?: InputMaybe<Scalars['String']>;
  toolDefinition?: InputMaybe<Scalars['JSON']>;
  weight?: InputMaybe<Scalars['Int']>;
};

export type ModuleUpdateManyWithWhereWithoutAssistantInput = {
  data: ModuleUpdateManyMutationInput;
  where: ModuleScalarWhereInput;
};

export type ModuleUpdateManyWithWhereWithoutModuleTemplateInput = {
  data: ModuleUpdateManyMutationInput;
  where: ModuleScalarWhereInput;
};

export type ModuleUpdateManyWithoutAssistantNestedInput = {
  connect?: InputMaybe<Array<ModuleWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<ModuleCreateOrConnectWithoutAssistantInput>>;
  create?: InputMaybe<Array<ModuleCreateWithoutAssistantInput>>;
  createMany?: InputMaybe<ModuleCreateManyAssistantInputEnvelope>;
  delete?: InputMaybe<Array<ModuleWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<ModuleScalarWhereInput>>;
  disconnect?: InputMaybe<Array<ModuleWhereUniqueInput>>;
  set?: InputMaybe<Array<ModuleWhereUniqueInput>>;
  update?: InputMaybe<Array<ModuleUpdateWithWhereUniqueWithoutAssistantInput>>;
  updateMany?: InputMaybe<Array<ModuleUpdateManyWithWhereWithoutAssistantInput>>;
  upsert?: InputMaybe<Array<ModuleUpsertWithWhereUniqueWithoutAssistantInput>>;
};

export type ModuleUpdateManyWithoutModuleTemplateNestedInput = {
  connect?: InputMaybe<Array<ModuleWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<ModuleCreateOrConnectWithoutModuleTemplateInput>>;
  create?: InputMaybe<Array<ModuleCreateWithoutModuleTemplateInput>>;
  createMany?: InputMaybe<ModuleCreateManyModuleTemplateInputEnvelope>;
  delete?: InputMaybe<Array<ModuleWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<ModuleScalarWhereInput>>;
  disconnect?: InputMaybe<Array<ModuleWhereUniqueInput>>;
  set?: InputMaybe<Array<ModuleWhereUniqueInput>>;
  update?: InputMaybe<Array<ModuleUpdateWithWhereUniqueWithoutModuleTemplateInput>>;
  updateMany?: InputMaybe<Array<ModuleUpdateManyWithWhereWithoutModuleTemplateInput>>;
  upsert?: InputMaybe<Array<ModuleUpsertWithWhereUniqueWithoutModuleTemplateInput>>;
};

export type ModuleUpdateWithWhereUniqueWithoutAssistantInput = {
  data: ModuleUpdateWithoutAssistantInput;
  where: ModuleWhereUniqueInput;
};

export type ModuleUpdateWithWhereUniqueWithoutModuleTemplateInput = {
  data: ModuleUpdateWithoutModuleTemplateInput;
  where: ModuleWhereUniqueInput;
};

export type ModuleUpdateWithoutAssistantInput = {
  configuration?: InputMaybe<Scalars['JSON']>;
  description?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['String']>;
  isExternal?: InputMaybe<Scalars['Boolean']>;
  moduleTemplate?: InputMaybe<ModuleTemplateUpdateOneWithoutModulesNestedInput>;
  name?: InputMaybe<Scalars['String']>;
  toolDefinition?: InputMaybe<Scalars['JSON']>;
  weight?: InputMaybe<Scalars['Int']>;
};

export type ModuleUpdateWithoutModuleTemplateInput = {
  configuration?: InputMaybe<Scalars['JSON']>;
  description?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['String']>;
  isExternal?: InputMaybe<Scalars['Boolean']>;
  name?: InputMaybe<Scalars['String']>;
  toolDefinition?: InputMaybe<Scalars['JSON']>;
  weight?: InputMaybe<Scalars['Int']>;
};

export type ModuleUpsertWithWhereUniqueWithoutAssistantInput = {
  create: ModuleCreateWithoutAssistantInput;
  update: ModuleUpdateWithoutAssistantInput;
  where: ModuleWhereUniqueInput;
};

export type ModuleUpsertWithWhereUniqueWithoutModuleTemplateInput = {
  create: ModuleCreateWithoutModuleTemplateInput;
  update: ModuleUpdateWithoutModuleTemplateInput;
  where: ModuleWhereUniqueInput;
};

export type ModuleWhereInput = {
  AND?: InputMaybe<Array<ModuleWhereInput>>;
  NOT?: InputMaybe<Array<ModuleWhereInput>>;
  OR?: InputMaybe<Array<ModuleWhereInput>>;
  assistant?: InputMaybe<AssistantRelationFilter>;
  configuration?: InputMaybe<JsonFilter>;
  description?: InputMaybe<StringNullableFilter>;
  id?: InputMaybe<StringFilter>;
  isExternal?: InputMaybe<BoolFilter>;
  moduleTemplate?: InputMaybe<ModuleTemplateRelationFilter>;
  moduleTemplateId?: InputMaybe<StringNullableFilter>;
  name?: InputMaybe<StringFilter>;
  toolDefinition?: InputMaybe<JsonNullableFilter>;
  weight?: InputMaybe<IntFilter>;
};

export type ModuleWhereUniqueInput = {
  id?: InputMaybe<Scalars['String']>;
};

export type Mutation = {
  __typename?: 'Mutation';
  abortBenchmark: BenchmarkStatusResponse;
  addMagicTableDocuments: MagicTableActionResponse;
  addScopeAcesses: Scope;
  agreedToTermsAndConditions: TermsAndConditionsAgreement;
  analyticsOrderDelete: AnalyticsOrder;
  analyticsOrderStart: AnalyticsOrder;
  chatCreate: Chat;
  chatUpdateTitle: Chat;
  checkVectorDataIntegrity: Scalars['Boolean'];
  chunkCreate: Chunk;
  chunkCreateMany: Array<Chunk>;
  chunkDeleteByContent: Scalars['Boolean'];
  chunkUpdate: Chunk;
  companyMetaUpdate: CompanyMeta;
  companyUpdateData: Company;
  contentDelete: Scalars['Boolean'];
  contentDeleteByContentIds: Array<Content>;
  contentDeleteByKey: Scalars['Boolean'];
  contentDeleteByOwnerId: Scalars['Boolean'];
  contentServiceUpdate: Content;
  contentUpdate: Content;
  contentUpsert: Content;
  contentUpsertByChat: Content;
  createAssistant: Assistant;
  createAssistantAccess: AssistantAccess;
  createDefaultModuleTemplate: Scalars['Boolean'];
  createGroup: Group;
  createMagicTableSheet: MagicTableSheet;
  createManyAssistantAccessByAssistantId: Scalars['Boolean'];
  createMemberships: Array<Membership>;
  createModule: Module;
  createModuleFromTemplate: Module;
  createModuleTemplate: ModuleTemplate;
  createPrompt: Prompt;
  createScope: Scope;
  createScopeAccess: ScopeAccess;
  createSubScope: Scope;
  createTermsAndConditions: TermsAndConditions;
  createTheme: Theme;
  deleteAssistant: Assistant;
  deleteAssistantAccess: AssistantAccess;
  deleteChat: Chat;
  deleteFont: Font;
  deleteGroup: Group;
  deleteMagicTableColumn: MagicTableActionResponse;
  deleteMagicTableRow: MagicTableActionResponse;
  deleteManyAssistantAccess: Scalars['Boolean'];
  /** Delete multiple memberships for a given user. */
  deleteMemberships: Scalars['Boolean'];
  deleteModule: Scalars['Boolean'];
  deleteModuleTemplate: Scalars['Boolean'];
  deleteScope: Scope;
  deleteScopeAccess: ScopeAccess;
  deleteScopesByExternalIds: Array<Scope>;
  externalLanguageModelCreate: ExternalLanguageModel;
  externalLanguageModelDelete: ExternalLanguageModel;
  externalLanguageModelUpdate: ExternalLanguageModel;
  forceIngestionState: Scalars['Boolean'];
  generateScopesBasedOnPaths: Array<Scope>;
  ignoreNetPromoterScoreRating: NetPromoterScoreActivity;
  importMagicTableColumns: MagicTableImportColumnResponse;
  ingestionEventProcessor: Content;
  languageModelGroupCreate: LanguageModelGroup;
  languageModelGroupDelete: LanguageModelGroup;
  languageModelGroupUpdate: LanguageModelGroup;
  markAllForRebuidingMetadata: Scalars['Boolean'];
  markAllForReindexing: Scalars['Boolean'];
  markAllForReingestion: Scalars['Boolean'];
  markAllForVectorDataIntegrityCheck: Scalars['Boolean'];
  markForReembedding: Scalars['Boolean'];
  messageCreate: Message;
  messageCreateBySystem: Message;
  messageCreateSync: Array<Message>;
  messageStopStreaming: Message;
  messageUpdateData: Message;
  moveScope: Scope;
  reIndexVectorDB: Scalars['Boolean'];
  rebuildMetadata: Scalars['Boolean'];
  reembedFiles: Scalars['Boolean'];
  reingestFiles: Scalars['Boolean'];
  removeScopeAcesses: Scope;
  renameScope: Scope;
  resetSetupForUsers: Array<User>;
  runNestingMigrationByExternalIds: Scalars['Boolean'];
  scopeRuleCreateMany: Array<ScopeRule>;
  setScopeProperties: Scope;
  submitNetPromoterScoreRating: NetPromoterScoreRating;
  undoDeleteChat: Chat;
  updateAssistant: Assistant;
  updateCompany: Company;
  updateDefaultAssistant: Assistant;
  updateGroup: Group;
  updateMagicTableCell: MagicTableActionResponse;
  updateMagicTableSheet: MagicTableSheet;
  updateModule: Module;
  updateModuleTemplate: ModuleTemplate;
  updateScope: Scope;
  updateTheme: Theme;
  upsertScopeByExternalId: Scope;
  upsertScopesByExternalIds: Array<Scope>;
  userPromptCreate: UserPrompt;
  userPromptCreateMany: Array<UserPrompt>;
  userPromptDelete: UserPrompt;
  userPromptDeleteAllUserOwnedPrompts: Scalars['Int'];
  userPromptDeleteMany: Scalars['Int'];
  userPromptUpdate: UserPrompt;
};


export type MutationAbortBenchmarkArgs = {
  benchmarkId: Scalars['String'];
};


export type MutationAddMagicTableDocumentsArgs = {
  contentIds: Array<Scalars['String']>;
  sheetId: Scalars['String'];
};


export type MutationAddScopeAcessesArgs = {
  applyToSubScopes: Scalars['Boolean'];
  scopeAccesses: Array<ScopeAccessDto>;
  scopeId: Scalars['String'];
  skipUserCheck?: InputMaybe<Scalars['Boolean']>;
};


export type MutationAgreedToTermsAndConditionsArgs = {
  termsAndConditionsId: Scalars['String'];
};


export type MutationAnalyticsOrderDeleteArgs = {
  orderId: Scalars['String'];
};


export type MutationAnalyticsOrderStartArgs = {
  orderId: Scalars['String'];
};


export type MutationChatCreateArgs = {
  assistantId?: InputMaybe<Scalars['String']>;
  title?: InputMaybe<Scalars['String']>;
};


export type MutationChatUpdateTitleArgs = {
  chatId: Scalars['String'];
  title: Scalars['String'];
};


export type MutationCheckVectorDataIntegrityArgs = {
  waitAfterRounds?: InputMaybe<Scalars['Int']>;
  waitInMs?: InputMaybe<Scalars['Int']>;
};


export type MutationChunkCreateArgs = {
  contentId: Scalars['String'];
  input: ChunkCreateInput;
};


export type MutationChunkCreateManyArgs = {
  contentId: Scalars['String'];
  input: Array<ChunkCreateInput>;
};


export type MutationChunkDeleteByContentArgs = {
  id: Scalars['String'];
};


export type MutationChunkUpdateArgs = {
  chunkId: Scalars['String'];
  contentId: Scalars['String'];
  input: ChunkUpdateInput;
};


export type MutationCompanyMetaUpdateArgs = {
  embeddingModel?: InputMaybe<Scalars['String']>;
};


export type MutationCompanyUpdateDataArgs = {
  input: CompanyUpdateInput;
};


export type MutationContentDeleteArgs = {
  id: Scalars['String'];
};


export type MutationContentDeleteByContentIdsArgs = {
  contentIds: Array<Scalars['String']>;
};


export type MutationContentDeleteByKeyArgs = {
  baseUrl?: InputMaybe<Scalars['String']>;
  key: Scalars['String'];
  ownerType: Scalars['String'];
  scopeId?: InputMaybe<Scalars['String']>;
  url?: InputMaybe<Scalars['String']>;
};


export type MutationContentDeleteByOwnerIdArgs = {
  ownerIds: Array<Scalars['String']>;
  shouldSoftDeleteContent?: InputMaybe<Scalars['Boolean']>;
};


export type MutationContentServiceUpdateArgs = {
  contentId: Scalars['String'];
  input: ContentUpdateInput;
  previewPdfFileName?: InputMaybe<Scalars['String']>;
};


export type MutationContentUpdateArgs = {
  contentId: Scalars['String'];
  input: ContentUpdateInput;
  ownerId?: InputMaybe<Scalars['String']>;
};


export type MutationContentUpsertArgs = {
  baseUrl?: InputMaybe<Scalars['String']>;
  chatId?: InputMaybe<Scalars['String']>;
  fileUrl?: InputMaybe<Scalars['String']>;
  input: ContentCreateInput;
  scopeId?: InputMaybe<Scalars['String']>;
  sourceKind?: InputMaybe<Scalars['String']>;
  sourceName?: InputMaybe<Scalars['String']>;
  sourceOwnerType?: InputMaybe<Scalars['String']>;
  storeInternally?: InputMaybe<Scalars['Boolean']>;
  text?: InputMaybe<Scalars['String']>;
};


export type MutationContentUpsertByChatArgs = {
  chatId: Scalars['String'];
  fileUrl?: InputMaybe<Scalars['String']>;
  input: ContentCreateInput;
  storeInternally?: InputMaybe<Scalars['Boolean']>;
};


export type MutationCreateAssistantArgs = {
  assistantAccessCreateInput?: InputMaybe<Array<AssistantAccessCreateDto>>;
  input: AssistantCreateInput;
};


export type MutationCreateAssistantAccessArgs = {
  accessType: Scalars['String'];
  assistantId: Scalars['String'];
  entityId: Scalars['String'];
  entityType: Scalars['String'];
};


export type MutationCreateDefaultModuleTemplateArgs = {
  defautlModuleTemplateName: Scalars['String'];
};


export type MutationCreateGroupArgs = {
  input: GroupCreateInput;
};


export type MutationCreateMagicTableSheetArgs = {
  assistantId: Scalars['String'];
  sheetName?: InputMaybe<Scalars['String']>;
};


export type MutationCreateManyAssistantAccessByAssistantIdArgs = {
  assistantId: Scalars['String'];
  data: Array<AssistantAccessCreateManyInput>;
  skipDuplicates?: InputMaybe<Scalars['Boolean']>;
};


export type MutationCreateMembershipsArgs = {
  groupId: Scalars['String'];
  userIds: Array<Scalars['String']>;
};


export type MutationCreateModuleArgs = {
  assistantId: Scalars['String'];
  input: ModuleCreateInput;
  moduleTemplateId?: InputMaybe<Scalars['String']>;
};


export type MutationCreateModuleFromTemplateArgs = {
  assistantId: Scalars['String'];
  templateId: Scalars['String'];
};


export type MutationCreateModuleTemplateArgs = {
  input: ModuleTemplateCreateWithoutCompanyInput;
};


export type MutationCreatePromptArgs = {
  assistantId: Scalars['String'];
  input: PromptCreateInput;
};


export type MutationCreateScopeArgs = {
  externalId?: InputMaybe<Scalars['String']>;
  name: Scalars['String'];
};


export type MutationCreateScopeAccessArgs = {
  accessType: Scalars['String'];
  entityId: Scalars['String'];
  entityType: Scalars['String'];
  scopeId: Scalars['String'];
  skipUserCheck?: InputMaybe<Scalars['Boolean']>;
};


export type MutationCreateSubScopeArgs = {
  name: Scalars['String'];
  parentId?: InputMaybe<Scalars['String']>;
};


export type MutationCreateTermsAndConditionsArgs = {
  termsAndConditions: Scalars['String'];
};


export type MutationCreateThemeArgs = {
  input: ThemeCreateInput;
};


export type MutationDeleteAssistantArgs = {
  id: Scalars['String'];
};


export type MutationDeleteAssistantAccessArgs = {
  accessType: Scalars['String'];
  assistantId: Scalars['String'];
  entityId: Scalars['String'];
  entityType: Scalars['String'];
};


export type MutationDeleteChatArgs = {
  chatId: Scalars['String'];
};


export type MutationDeleteFontArgs = {
  fontId: Scalars['String'];
  themeId: Scalars['String'];
};


export type MutationDeleteGroupArgs = {
  id: Scalars['String'];
};


export type MutationDeleteMagicTableColumnArgs = {
  columnOrder: Scalars['Float'];
  sheetId: Scalars['String'];
};


export type MutationDeleteMagicTableRowArgs = {
  rowOrder: Scalars['Float'];
  sheetId: Scalars['String'];
};


export type MutationDeleteManyAssistantAccessArgs = {
  assistantAccessIds?: InputMaybe<Array<Scalars['String']>>;
  assistantId: Scalars['String'];
};


export type MutationDeleteMembershipsArgs = {
  groupIds: Array<Scalars['String']>;
  userId: Scalars['String'];
};


export type MutationDeleteModuleArgs = {
  moduleId: Scalars['String'];
};


export type MutationDeleteModuleTemplateArgs = {
  moduleTemplateId: Scalars['String'];
};


export type MutationDeleteScopeArgs = {
  scopeId: Scalars['String'];
};


export type MutationDeleteScopeAccessArgs = {
  accessType: Scalars['String'];
  entityId: Scalars['String'];
  entityType: Scalars['String'];
  scopeId: Scalars['String'];
};


export type MutationDeleteScopesByExternalIdsArgs = {
  externalIds: Array<Scalars['String']>;
  force?: InputMaybe<Scalars['Boolean']>;
};


export type MutationExternalLanguageModelCreateArgs = {
  groupName: Scalars['String'];
  input: ExternalLanguageModelCreateInput;
};


export type MutationExternalLanguageModelDeleteArgs = {
  id: Scalars['String'];
};


export type MutationExternalLanguageModelUpdateArgs = {
  id: Scalars['String'];
  input: ExternalLanguageModelUpdateInput;
};


export type MutationForceIngestionStateArgs = {
  ingestionState: IngestionState;
  where?: InputMaybe<ContentWhereInput>;
};


export type MutationGenerateScopesBasedOnPathsArgs = {
  paths: Array<Scalars['String']>;
};


export type MutationImportMagicTableColumnsArgs = {
  contentId: Scalars['String'];
  sheetId: Scalars['String'];
  sheetName?: InputMaybe<Scalars['String']>;
};


export type MutationIngestionEventProcessorArgs = {
  contentId: Scalars['String'];
  event: Scalars['String'];
};


export type MutationLanguageModelGroupCreateArgs = {
  input: LanguageModelGroupCreateInput;
};


export type MutationLanguageModelGroupDeleteArgs = {
  groupName: Scalars['String'];
};


export type MutationLanguageModelGroupUpdateArgs = {
  groupName: Scalars['String'];
  input: LanguageModelGroupUpdateInput;
};


export type MutationMarkAllForRebuidingMetadataArgs = {
  where?: InputMaybe<ContentWhereInput>;
};


export type MutationMarkAllForReindexingArgs = {
  where?: InputMaybe<ContentWhereInput>;
};


export type MutationMarkAllForReingestionArgs = {
  where?: InputMaybe<ContentWhereInput>;
};


export type MutationMarkAllForVectorDataIntegrityCheckArgs = {
  where?: InputMaybe<ContentWhereInput>;
};


export type MutationMarkForReembeddingArgs = {
  where?: InputMaybe<ContentWhereInput>;
};


export type MutationMessageCreateArgs = {
  assistantId?: InputMaybe<Scalars['String']>;
  chatId?: InputMaybe<Scalars['String']>;
  input: MessageCreateInput;
  moduleSelectionOnly?: InputMaybe<Scalars['Boolean']>;
  syncMode?: InputMaybe<Scalars['Boolean']>;
};


export type MutationMessageCreateBySystemArgs = {
  chatId: Scalars['String'];
  input: MessageCreateInput;
};


export type MutationMessageCreateSyncArgs = {
  assistantId?: InputMaybe<Scalars['String']>;
  chatId?: InputMaybe<Scalars['String']>;
  input: MessageCreateInput;
};


export type MutationMessageStopStreamingArgs = {
  chatId: Scalars['String'];
  messageId: Scalars['String'];
};


export type MutationMessageUpdateDataArgs = {
  chatId: Scalars['String'];
  input: MessageUpdateInput;
  messageId: Scalars['String'];
};


export type MutationMoveScopeArgs = {
  newParentId?: InputMaybe<Scalars['String']>;
  scopeId: Scalars['String'];
};


export type MutationRebuildMetadataArgs = {
  waitAfterRounds?: InputMaybe<Scalars['Int']>;
  waitInMs?: InputMaybe<Scalars['Int']>;
};


export type MutationReembedFilesArgs = {
  waitAfterRounds?: InputMaybe<Scalars['Int']>;
  waitInMs?: InputMaybe<Scalars['Int']>;
};


export type MutationReingestFilesArgs = {
  waitAfterRounds?: InputMaybe<Scalars['Int']>;
  waitInMs?: InputMaybe<Scalars['Int']>;
};


export type MutationRemoveScopeAcessesArgs = {
  applyToSubScopes: Scalars['Boolean'];
  scopeAccesses: Array<ScopeAccessDto>;
  scopeId: Scalars['String'];
  skipUserCheck?: InputMaybe<Scalars['Boolean']>;
};


export type MutationRenameScopeArgs = {
  name: Scalars['String'];
  scopeId: Scalars['String'];
};


export type MutationResetSetupForUsersArgs = {
  userIds: Array<Scalars['String']>;
};


export type MutationRunNestingMigrationByExternalIdsArgs = {
  basePath: Scalars['String'];
};


export type MutationScopeRuleCreateManyArgs = {
  assistantId: Scalars['String'];
  data: Array<ScopeRuleCreateManyInput>;
  skipDuplicates?: InputMaybe<Scalars['Boolean']>;
};


export type MutationSetScopePropertiesArgs = {
  applyToSubScopes: Scalars['Boolean'];
  properties: ScopeProperties;
  scopeId: Scalars['String'];
};


export type MutationSubmitNetPromoterScoreRatingArgs = {
  input: NetPromoterScoreRatingCreateInput;
};


export type MutationUndoDeleteChatArgs = {
  chatId: Scalars['String'];
};


export type MutationUpdateAssistantArgs = {
  assistantAccessCreateInput?: InputMaybe<Array<AssistantAccessCreateDto>>;
  assistantAccessIdsToDelete?: InputMaybe<Array<Scalars['String']>>;
  id: Scalars['String'];
  input: AssistantUpdateInput;
};


export type MutationUpdateCompanyArgs = {
  groupConfiguration: Scalars['JSON'];
};


export type MutationUpdateDefaultAssistantArgs = {
  newDefaultAssistantId: Scalars['String'];
};


export type MutationUpdateGroupArgs = {
  id: Scalars['String'];
  input: GroupUpdateInput;
};


export type MutationUpdateMagicTableCellArgs = {
  columnOrder: Scalars['Float'];
  data: Scalars['String'];
  rowOrder: Scalars['Float'];
  sheetId: Scalars['String'];
};


export type MutationUpdateMagicTableSheetArgs = {
  name: Scalars['String'];
  sheetId: Scalars['String'];
};


export type MutationUpdateModuleArgs = {
  input: ModuleUpdateInput;
  moduleId: Scalars['String'];
};


export type MutationUpdateModuleTemplateArgs = {
  input: ModuleTemplateUpdateInput;
  moduleTemplateId: Scalars['String'];
};


export type MutationUpdateScopeArgs = {
  id: Scalars['String'];
  name: Scalars['String'];
};


export type MutationUpdateThemeArgs = {
  input: ThemeUpdateInput;
  themeId: Scalars['String'];
};


export type MutationUpsertScopeByExternalIdArgs = {
  externalId: Scalars['String'];
  name: Scalars['String'];
  parentId?: InputMaybe<Scalars['String']>;
  scopeAccess?: InputMaybe<Array<ScopeAccessDto>>;
};


export type MutationUpsertScopesByExternalIdsArgs = {
  externalIds: Array<Scalars['String']>;
  names: Array<Scalars['String']>;
};


export type MutationUserPromptCreateArgs = {
  assistantId: Scalars['String'];
  input: UserPromptCreateInput;
};


export type MutationUserPromptCreateManyArgs = {
  areOwnedByUser: Scalars['Boolean'];
  assistantId: Scalars['String'];
  data: Array<UserPromptCreateManyInput>;
  skipDuplicates?: InputMaybe<Scalars['Boolean']>;
};


export type MutationUserPromptDeleteArgs = {
  assistantId: Scalars['String'];
  id: Scalars['String'];
};


export type MutationUserPromptDeleteAllUserOwnedPromptsArgs = {
  assistantId: Scalars['String'];
  where?: InputMaybe<UserPromptWhereInput>;
};


export type MutationUserPromptDeleteManyArgs = {
  areOwnedByUser: Scalars['Boolean'];
  assistantId: Scalars['String'];
  where?: InputMaybe<UserPromptWhereInput>;
};


export type MutationUserPromptUpdateArgs = {
  assistantId: Scalars['String'];
  id: Scalars['String'];
  input: UserPromptUpdateInput;
};

export type NestedBoolFilter = {
  equals?: InputMaybe<Scalars['Boolean']>;
  not?: InputMaybe<NestedBoolFilter>;
};

export type NestedDateTimeFilter = {
  equals?: InputMaybe<Scalars['DateTime']>;
  gt?: InputMaybe<Scalars['DateTime']>;
  gte?: InputMaybe<Scalars['DateTime']>;
  in?: InputMaybe<Array<Scalars['DateTime']>>;
  lt?: InputMaybe<Scalars['DateTime']>;
  lte?: InputMaybe<Scalars['DateTime']>;
  not?: InputMaybe<NestedDateTimeFilter>;
  notIn?: InputMaybe<Array<Scalars['DateTime']>>;
};

export type NestedDateTimeNullableFilter = {
  equals?: InputMaybe<Scalars['DateTime']>;
  gt?: InputMaybe<Scalars['DateTime']>;
  gte?: InputMaybe<Scalars['DateTime']>;
  in?: InputMaybe<Array<Scalars['DateTime']>>;
  lt?: InputMaybe<Scalars['DateTime']>;
  lte?: InputMaybe<Scalars['DateTime']>;
  not?: InputMaybe<NestedDateTimeNullableFilter>;
  notIn?: InputMaybe<Array<Scalars['DateTime']>>;
};

export type NestedEnumAnalyticsOrderStateFilter = {
  equals?: InputMaybe<AnalyticsOrderState>;
  in?: InputMaybe<Array<AnalyticsOrderState>>;
  not?: InputMaybe<NestedEnumAnalyticsOrderStateFilter>;
  notIn?: InputMaybe<Array<AnalyticsOrderState>>;
};

export type NestedEnumAnalyticsTypeFilter = {
  equals?: InputMaybe<AnalyticsType>;
  in?: InputMaybe<Array<AnalyticsType>>;
  not?: InputMaybe<NestedEnumAnalyticsTypeFilter>;
  notIn?: InputMaybe<Array<AnalyticsType>>;
};

export type NestedEnumAssistantAccessEntityTypeFilter = {
  equals?: InputMaybe<AssistantAccessEntityType>;
  in?: InputMaybe<Array<AssistantAccessEntityType>>;
  not?: InputMaybe<NestedEnumAssistantAccessEntityTypeFilter>;
  notIn?: InputMaybe<Array<AssistantAccessEntityType>>;
};

export type NestedEnumAssistantAccessTypeFilter = {
  equals?: InputMaybe<AssistantAccessType>;
  in?: InputMaybe<Array<AssistantAccessType>>;
  not?: InputMaybe<NestedEnumAssistantAccessTypeFilter>;
  notIn?: InputMaybe<Array<AssistantAccessType>>;
};

export type NestedEnumBenchmarkStatusFilter = {
  equals?: InputMaybe<BenchmarkStatus>;
  in?: InputMaybe<Array<BenchmarkStatus>>;
  not?: InputMaybe<NestedEnumBenchmarkStatusFilter>;
  notIn?: InputMaybe<Array<BenchmarkStatus>>;
};

export type NestedEnumChatUploadFilter = {
  equals?: InputMaybe<ChatUpload>;
  in?: InputMaybe<Array<ChatUpload>>;
  not?: InputMaybe<NestedEnumChatUploadFilter>;
  notIn?: InputMaybe<Array<ChatUpload>>;
};

export type NestedEnumIngestionStateFilter = {
  equals?: InputMaybe<IngestionState>;
  in?: InputMaybe<Array<IngestionState>>;
  not?: InputMaybe<NestedEnumIngestionStateFilter>;
  notIn?: InputMaybe<Array<IngestionState>>;
};

export type NestedEnumOwnerTypeFilter = {
  equals?: InputMaybe<OwnerType>;
  in?: InputMaybe<Array<OwnerType>>;
  not?: InputMaybe<NestedEnumOwnerTypeFilter>;
  notIn?: InputMaybe<Array<OwnerType>>;
};

export type NestedEnumRoleFilter = {
  equals?: InputMaybe<Role>;
  in?: InputMaybe<Array<Role>>;
  not?: InputMaybe<NestedEnumRoleFilter>;
  notIn?: InputMaybe<Array<Role>>;
};

export type NestedEnumScopeAccessEntityTypeFilter = {
  equals?: InputMaybe<ScopeAccessEntityType>;
  in?: InputMaybe<Array<ScopeAccessEntityType>>;
  not?: InputMaybe<NestedEnumScopeAccessEntityTypeFilter>;
  notIn?: InputMaybe<Array<ScopeAccessEntityType>>;
};

export type NestedEnumScopeAccessTypeFilter = {
  equals?: InputMaybe<ScopeAccessType>;
  in?: InputMaybe<Array<ScopeAccessType>>;
  not?: InputMaybe<NestedEnumScopeAccessTypeFilter>;
  notIn?: InputMaybe<Array<ScopeAccessType>>;
};

export type NestedEnumSourceKindFilter = {
  equals?: InputMaybe<SourceKind>;
  in?: InputMaybe<Array<SourceKind>>;
  not?: InputMaybe<NestedEnumSourceKindFilter>;
  notIn?: InputMaybe<Array<SourceKind>>;
};

export type NestedIntFilter = {
  equals?: InputMaybe<Scalars['Int']>;
  gt?: InputMaybe<Scalars['Int']>;
  gte?: InputMaybe<Scalars['Int']>;
  in?: InputMaybe<Array<Scalars['Int']>>;
  lt?: InputMaybe<Scalars['Int']>;
  lte?: InputMaybe<Scalars['Int']>;
  not?: InputMaybe<NestedIntFilter>;
  notIn?: InputMaybe<Array<Scalars['Int']>>;
};

export type NestedIntNullableFilter = {
  equals?: InputMaybe<Scalars['Int']>;
  gt?: InputMaybe<Scalars['Int']>;
  gte?: InputMaybe<Scalars['Int']>;
  in?: InputMaybe<Array<Scalars['Int']>>;
  lt?: InputMaybe<Scalars['Int']>;
  lte?: InputMaybe<Scalars['Int']>;
  not?: InputMaybe<NestedIntNullableFilter>;
  notIn?: InputMaybe<Array<Scalars['Int']>>;
};

export type NestedStringFilter = {
  contains?: InputMaybe<Scalars['String']>;
  endsWith?: InputMaybe<Scalars['String']>;
  equals?: InputMaybe<Scalars['String']>;
  gt?: InputMaybe<Scalars['String']>;
  gte?: InputMaybe<Scalars['String']>;
  in?: InputMaybe<Array<Scalars['String']>>;
  lt?: InputMaybe<Scalars['String']>;
  lte?: InputMaybe<Scalars['String']>;
  not?: InputMaybe<NestedStringFilter>;
  notIn?: InputMaybe<Array<Scalars['String']>>;
  startsWith?: InputMaybe<Scalars['String']>;
};

export type NestedStringNullableFilter = {
  contains?: InputMaybe<Scalars['String']>;
  endsWith?: InputMaybe<Scalars['String']>;
  equals?: InputMaybe<Scalars['String']>;
  gt?: InputMaybe<Scalars['String']>;
  gte?: InputMaybe<Scalars['String']>;
  in?: InputMaybe<Array<Scalars['String']>>;
  lt?: InputMaybe<Scalars['String']>;
  lte?: InputMaybe<Scalars['String']>;
  not?: InputMaybe<NestedStringNullableFilter>;
  notIn?: InputMaybe<Array<Scalars['String']>>;
  startsWith?: InputMaybe<Scalars['String']>;
};

export type NetPromoterScoreActivity = {
  __typename?: 'NetPromoterScoreActivity';
  companyId: Scalars['String'];
  createdAt: Scalars['DateTime'];
  createdBy: Scalars['String'];
  id: Scalars['ID'];
  type: NetPromoterScoreActivityType;
  updatedAt: Scalars['DateTime'];
};

export enum NetPromoterScoreActivityType {
  Ignored = 'IGNORED',
  Rated = 'RATED'
}

export type NetPromoterScoreRating = {
  __typename?: 'NetPromoterScoreRating';
  companyId: Scalars['String'];
  createdAt: Scalars['DateTime'];
  createdBy: Scalars['String'];
  feedback?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  score: Scalars['Int'];
  updatedAt: Scalars['DateTime'];
};

export type NetPromoterScoreRatingCreateInput = {
  companyId: Scalars['String'];
  createdAt?: InputMaybe<Scalars['DateTime']>;
  createdBy: Scalars['String'];
  feedback?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['String']>;
  score: Scalars['Int'];
  updatedAt?: InputMaybe<Scalars['DateTime']>;
};

export type NetPromoterScoreTriggerCheckResponse = {
  __typename?: 'NetPromoterScoreTriggerCheckResponse';
  delayInSecBeforeQuestionDisplay?: Maybe<Scalars['Float']>;
  message: Scalars['String'];
  question?: Maybe<Scalars['String']>;
  status: Scalars['Boolean'];
};

export type OversizedContent = {
  __typename?: 'OversizedContent';
  chunkid: Scalars['String'];
  count: Scalars['Float'];
  id: Scalars['String'];
  key: Scalars['String'];
};

export enum OwnerType {
  Chat = 'CHAT',
  Company = 'COMPANY',
  Scope = 'SCOPE',
  User = 'USER'
}

export type PaginatedContent = {
  __typename?: 'PaginatedContent';
  contentContributors: Array<PaginatedContentContributor>;
  contentOwners: Array<PaginatedContentOwner>;
  nodes?: Maybe<Array<Content>>;
  totalCount: Scalars['Int'];
};

export type PaginatedContentContributor = {
  __typename?: 'PaginatedContentContributor';
  contentId: Scalars['String'];
  contributor: Scalars['String'];
};

export type PaginatedContentOwner = {
  __typename?: 'PaginatedContentOwner';
  ownerId: Scalars['String'];
  ownerName: Scalars['String'];
};

export type PaginatedScope = {
  __typename?: 'PaginatedScope';
  nodes?: Maybe<Array<Scope>>;
  totalCount: Scalars['Int'];
};

export type PaginatedUserGroups = {
  __typename?: 'PaginatedUserGroups';
  groups: Array<Group>;
  userId: Scalars['String'];
};

export type PaginatedUsers = {
  __typename?: 'PaginatedUsers';
  nodes?: Maybe<Array<User>>;
  paginatedGroups: Array<PaginatedUserGroups>;
  totalCount: Scalars['Int'];
};

export type Prompt = {
  __typename?: 'Prompt';
  assistant: Assistant;
  assistantId: Scalars['String'];
  constraints?: Maybe<Array<Scalars['String']>>;
  createdAt: Scalars['DateTime'];
  goals?: Maybe<Array<Scalars['String']>>;
  id: Scalars['ID'];
  name: Scalars['String'];
  prefix: Scalars['String'];
  suffix: Scalars['String'];
  updatedAt: Scalars['DateTime'];
};

export type PromptCreateInput = {
  constraints?: InputMaybe<Array<Scalars['String']>>;
  goals?: InputMaybe<Array<Scalars['String']>>;
  id?: InputMaybe<Scalars['String']>;
  name: Scalars['String'];
  prefix: Scalars['String'];
  suffix: Scalars['String'];
};

export type PromptCreateManyAssistantInput = {
  constraints?: InputMaybe<Array<Scalars['String']>>;
  goals?: InputMaybe<Array<Scalars['String']>>;
  id?: InputMaybe<Scalars['String']>;
  name: Scalars['String'];
  prefix: Scalars['String'];
  suffix: Scalars['String'];
};

export type PromptCreateManyAssistantInputEnvelope = {
  data: Array<PromptCreateManyAssistantInput>;
  skipDuplicates?: InputMaybe<Scalars['Boolean']>;
};

export type PromptCreateNestedManyWithoutAssistantInput = {
  connect?: InputMaybe<Array<PromptWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<PromptCreateOrConnectWithoutAssistantInput>>;
  create?: InputMaybe<Array<PromptCreateWithoutAssistantInput>>;
  createMany?: InputMaybe<PromptCreateManyAssistantInputEnvelope>;
};

export type PromptCreateOrConnectWithoutAssistantInput = {
  create: PromptCreateWithoutAssistantInput;
  where: PromptWhereUniqueInput;
};

export type PromptCreateWithoutAssistantInput = {
  constraints?: InputMaybe<Array<Scalars['String']>>;
  goals?: InputMaybe<Array<Scalars['String']>>;
  id?: InputMaybe<Scalars['String']>;
  name: Scalars['String'];
  prefix: Scalars['String'];
  suffix: Scalars['String'];
};

export type PromptListRelationFilter = {
  every?: InputMaybe<PromptWhereInput>;
  none?: InputMaybe<PromptWhereInput>;
  some?: InputMaybe<PromptWhereInput>;
};

export type PromptOrderByRelationAggregateInput = {
  _count?: InputMaybe<SortOrder>;
};

export type PromptScalarWhereInput = {
  AND?: InputMaybe<Array<PromptScalarWhereInput>>;
  NOT?: InputMaybe<Array<PromptScalarWhereInput>>;
  OR?: InputMaybe<Array<PromptScalarWhereInput>>;
  constraints?: InputMaybe<StringNullableListFilter>;
  goals?: InputMaybe<StringNullableListFilter>;
  id?: InputMaybe<StringFilter>;
  name?: InputMaybe<StringFilter>;
  prefix?: InputMaybe<StringFilter>;
  suffix?: InputMaybe<StringFilter>;
};

export type PromptUpdateManyMutationInput = {
  constraints?: InputMaybe<Array<Scalars['String']>>;
  goals?: InputMaybe<Array<Scalars['String']>>;
  id?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
  prefix?: InputMaybe<Scalars['String']>;
  suffix?: InputMaybe<Scalars['String']>;
};

export type PromptUpdateManyWithWhereWithoutAssistantInput = {
  data: PromptUpdateManyMutationInput;
  where: PromptScalarWhereInput;
};

export type PromptUpdateManyWithoutAssistantNestedInput = {
  connect?: InputMaybe<Array<PromptWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<PromptCreateOrConnectWithoutAssistantInput>>;
  create?: InputMaybe<Array<PromptCreateWithoutAssistantInput>>;
  createMany?: InputMaybe<PromptCreateManyAssistantInputEnvelope>;
  delete?: InputMaybe<Array<PromptWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<PromptScalarWhereInput>>;
  disconnect?: InputMaybe<Array<PromptWhereUniqueInput>>;
  set?: InputMaybe<Array<PromptWhereUniqueInput>>;
  update?: InputMaybe<Array<PromptUpdateWithWhereUniqueWithoutAssistantInput>>;
  updateMany?: InputMaybe<Array<PromptUpdateManyWithWhereWithoutAssistantInput>>;
  upsert?: InputMaybe<Array<PromptUpsertWithWhereUniqueWithoutAssistantInput>>;
};

export type PromptUpdateWithWhereUniqueWithoutAssistantInput = {
  data: PromptUpdateWithoutAssistantInput;
  where: PromptWhereUniqueInput;
};

export type PromptUpdateWithoutAssistantInput = {
  constraints?: InputMaybe<Array<Scalars['String']>>;
  goals?: InputMaybe<Array<Scalars['String']>>;
  id?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
  prefix?: InputMaybe<Scalars['String']>;
  suffix?: InputMaybe<Scalars['String']>;
};

export type PromptUpsertWithWhereUniqueWithoutAssistantInput = {
  create: PromptCreateWithoutAssistantInput;
  update: PromptUpdateWithoutAssistantInput;
  where: PromptWhereUniqueInput;
};

export type PromptWhereInput = {
  AND?: InputMaybe<Array<PromptWhereInput>>;
  NOT?: InputMaybe<Array<PromptWhereInput>>;
  OR?: InputMaybe<Array<PromptWhereInput>>;
  constraints?: InputMaybe<StringNullableListFilter>;
  goals?: InputMaybe<StringNullableListFilter>;
  id?: InputMaybe<StringFilter>;
  name?: InputMaybe<StringFilter>;
  prefix?: InputMaybe<StringFilter>;
  suffix?: InputMaybe<StringFilter>;
};

export type PromptWhereUniqueInput = {
  id?: InputMaybe<Scalars['String']>;
};

export type Query = {
  __typename?: 'Query';
  allAssistants: Array<Assistant>;
  allAssistantsByCompanyId: Array<Assistant>;
  allAssistantsByUserId: Array<Assistant>;
  allGroups: Array<Group>;
  analyticsOrders: Array<AnalyticsOrder>;
  assistantAccessByAssistantId: Array<AssistantAccess>;
  assistantByCompany: Assistant;
  assistantByUser: Assistant;
  assistantModuleTypes: Array<Scalars['String']>;
  assistants: Array<Assistant>;
  chat: Chat;
  chats: Array<Chat>;
  chunk: Array<Chunk>;
  chunkById: Chunk;
  company: Company;
  companyMeta: CompanyMeta;
  content: Array<Content>;
  contentByChat: Array<Content>;
  contentByCompany: Array<Content>;
  contentById: Array<Content>;
  contentByUser: Array<Content>;
  contentWithOversizedChunks: Array<OversizedContent>;
  dueDiligence: Array<DueDiligence>;
  externalLanguageModelFindMany: Array<ExternalLanguageModel>;
  findAssistantAccessesByUser: Array<AssistantAccess>;
  findManyUsersById: Array<User>;
  folderIdPath?: Maybe<Scalars['String']>;
  /** Retrieves a hierarchical tree structure of folders. This query allows users to fetch a specified folder's child folders up to a certain depth, its parent folders up to a certain depth, and the folder itself. It supports filtering based on access type and allows specification of how many levels of child and parent folders to include in the result. The tree is returned as a flat array of folders, with the starting folder as the first element. */
  folderTree: Array<Scope>;
  getCompaniesById: Array<Company>;
  getCurrentStatus: BenchmarkStatusResponse;
  getLatestPrompt: Prompt;
  getScopeAndSubscopes: Array<Scope>;
  getTransformedMagicTableSheet: TransformedMagicTableResponse;
  groupsByCompany: Array<Group>;
  languageModelGroupFindMany: Array<LanguageModelGroup>;
  latestTermsAndConditions: TermsAndConditions;
  magicTableSheet: MagicTableSheet;
  me: Me;
  memberships: Array<Membership>;
  message: Message;
  messages: Array<Message>;
  moduleTemplates: Array<ModuleTemplate>;
  modules: Array<Module>;
  netPromoterScoreTriggerCheck: NetPromoterScoreTriggerCheckResponse;
  ownGroups: Array<Group>;
  ownScopes: Array<Scope>;
  paginatedContent: PaginatedContent;
  paginatedContentForRule: PaginatedContent;
  paginatedScope: PaginatedScope;
  paginatedUsers: PaginatedUsers;
  scopeRulesByAssistant: Array<ScopeRule>;
  scopes: Array<Scope>;
  scopesByCompany: Array<Scope>;
  searchMembers: Array<MemberSearchResult>;
  spaceManagerVerify: Scalars['Boolean'];
  statisticsIngestion: StatisticsIngestion;
  termsAndConditions: Array<TermsAndConditions>;
  theme: Theme;
  userPrompt: Array<UserPrompt>;
  userScopesByService: Array<Scope>;
  users: Array<User>;
};


export type QueryAllAssistantsArgs = {
  cursor?: InputMaybe<AssistantWhereUniqueInput>;
  distinct?: InputMaybe<Array<AssistantScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<AssistantOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<AssistantWhereInput>;
};


export type QueryAllAssistantsByUserIdArgs = {
  cursor?: InputMaybe<AssistantWhereUniqueInput>;
  distinct?: InputMaybe<Array<AssistantScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<AssistantOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  userId: Scalars['String'];
  where?: InputMaybe<AssistantWhereInput>;
};


export type QueryAllGroupsArgs = {
  cursor?: InputMaybe<GroupWhereUniqueInput>;
  distinct?: InputMaybe<Array<GroupScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<GroupOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<GroupWhereInput>;
};


export type QueryAnalyticsOrdersArgs = {
  cursor?: InputMaybe<AnalyticsOrderWhereUniqueInput>;
  distinct?: InputMaybe<Array<AnalyticsOrderScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<AnalyticsOrderOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<AnalyticsOrderWhereInput>;
};


export type QueryAssistantAccessByAssistantIdArgs = {
  assistantId: Scalars['String'];
};


export type QueryAssistantByCompanyArgs = {
  assistantId: Scalars['String'];
};


export type QueryAssistantByUserArgs = {
  assistantId: Scalars['String'];
};


export type QueryAssistantsArgs = {
  cursor?: InputMaybe<AssistantWhereUniqueInput>;
  distinct?: InputMaybe<Array<AssistantScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<AssistantOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<AssistantWhereInput>;
};


export type QueryChatArgs = {
  chatId: Scalars['String'];
};


export type QueryChatsArgs = {
  cursor?: InputMaybe<ChatWhereUniqueInput>;
  distinct?: InputMaybe<Array<ChatScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<ChatOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<ChatWhereInput>;
};


export type QueryChunkArgs = {
  chatId?: InputMaybe<Scalars['String']>;
  contentId: Scalars['String'];
  cursor?: InputMaybe<ChunkWhereUniqueInput>;
  distinct?: InputMaybe<Array<ChunkScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<ChunkOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<ChunkWhereInput>;
};


export type QueryChunkByIdArgs = {
  contentId: Scalars['String'];
  id: Scalars['String'];
};


export type QueryContentArgs = {
  accessType?: InputMaybe<Scalars['String']>;
  chatId?: InputMaybe<Scalars['String']>;
  cursor?: InputMaybe<ContentWhereUniqueInput>;
  distinct?: InputMaybe<Array<ContentScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<ContentOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<ContentWhereInput>;
};


export type QueryContentByChatArgs = {
  accessType?: InputMaybe<Scalars['String']>;
  chatId?: InputMaybe<Scalars['String']>;
};


export type QueryContentByCompanyArgs = {
  chatId?: InputMaybe<Scalars['String']>;
  cursor?: InputMaybe<ContentWhereUniqueInput>;
  distinct?: InputMaybe<Array<ContentScalarFieldEnum>>;
  includeDeletedContent?: InputMaybe<Scalars['Boolean']>;
  orderBy?: InputMaybe<Array<ContentOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<ContentWhereInput>;
};


export type QueryContentByIdArgs = {
  accessType?: InputMaybe<Scalars['String']>;
  chatId?: InputMaybe<Scalars['String']>;
  contentIds: Array<Scalars['String']>;
};


export type QueryContentByUserArgs = {
  accessType?: InputMaybe<Scalars['String']>;
  chatId?: InputMaybe<Scalars['String']>;
  cursor?: InputMaybe<ContentWhereUniqueInput>;
  distinct?: InputMaybe<Array<ContentScalarFieldEnum>>;
  includeDeletedContent?: InputMaybe<Scalars['Boolean']>;
  orderBy?: InputMaybe<Array<ContentOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<ContentWhereInput>;
};


export type QueryContentWithOversizedChunksArgs = {
  textLength?: InputMaybe<Scalars['Float']>;
};


export type QueryDueDiligenceArgs = {
  cursor?: InputMaybe<DueDiligenceWhereUniqueInput>;
  distinct?: InputMaybe<Array<DueDiligenceScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<DueDiligenceOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<DueDiligenceWhereInput>;
};


export type QueryExternalLanguageModelFindManyArgs = {
  cursor?: InputMaybe<ExternalLanguageModelWhereUniqueInput>;
  distinct?: InputMaybe<Array<ExternalLanguageModelScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<ExternalLanguageModelOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<ExternalLanguageModelWhereInput>;
};


export type QueryFindAssistantAccessesByUserArgs = {
  cursor?: InputMaybe<AssistantAccessWhereUniqueInput>;
  distinct?: InputMaybe<Array<AssistantAccessScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<AssistantAccessOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<AssistantAccessWhereInput>;
};


export type QueryFindManyUsersByIdArgs = {
  userIds: Array<Scalars['String']>;
};


export type QueryFolderIdPathArgs = {
  folderId: Scalars['String'];
};


export type QueryFolderTreeArgs = {
  accessType?: InputMaybe<ScopeAccessType>;
  childLevels?: InputMaybe<Scalars['Int']>;
  folderId?: InputMaybe<Scalars['String']>;
  parentLevels?: InputMaybe<Scalars['Int']>;
};


export type QueryGetCompaniesByIdArgs = {
  companyIds: Array<Scalars['String']>;
};


export type QueryGetLatestPromptArgs = {
  assistantId: Scalars['String'];
  promptName: Scalars['String'];
};


export type QueryGetScopeAndSubscopesArgs = {
  accessType?: InputMaybe<Scalars['String']>;
  depth?: InputMaybe<Scalars['Float']>;
  scopeId?: InputMaybe<Scalars['String']>;
};


export type QueryGetTransformedMagicTableSheetArgs = {
  cursor?: InputMaybe<MagicTableSheetWhereUniqueInput>;
  distinct?: InputMaybe<Array<MagicTableSheetScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<MagicTableSheetOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<MagicTableSheetWhereInput>;
};


export type QueryGroupsByCompanyArgs = {
  cursor?: InputMaybe<GroupWhereUniqueInput>;
  distinct?: InputMaybe<Array<GroupScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<GroupOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<GroupWhereInput>;
};


export type QueryLanguageModelGroupFindManyArgs = {
  cursor?: InputMaybe<LanguageModelGroupWhereUniqueInput>;
  distinct?: InputMaybe<Array<LanguageModelGroupScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<LanguageModelGroupOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<LanguageModelGroupWhereInput>;
};


export type QueryMagicTableSheetArgs = {
  cursor?: InputMaybe<MagicTableSheetWhereUniqueInput>;
  distinct?: InputMaybe<Array<MagicTableSheetScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<MagicTableSheetOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<MagicTableSheetWhereInput>;
};


export type QueryMembershipsArgs = {
  cursor?: InputMaybe<MembershipWhereUniqueInput>;
  distinct?: InputMaybe<Array<MembershipScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<MembershipOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  userId: Scalars['String'];
  where?: InputMaybe<MembershipWhereInput>;
};


export type QueryMessageArgs = {
  chatId: Scalars['String'];
  messageId: Scalars['String'];
};


export type QueryMessagesArgs = {
  chatId: Scalars['String'];
  cursor?: InputMaybe<MessageWhereUniqueInput>;
  distinct?: InputMaybe<Array<MessageScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<MessageOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<MessageWhereInput>;
};


export type QueryModuleTemplatesArgs = {
  cursor?: InputMaybe<ModuleTemplateWhereUniqueInput>;
  distinct?: InputMaybe<Array<ModuleTemplateScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<ModuleTemplateOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<ModuleTemplateWhereInput>;
};


export type QueryModulesArgs = {
  cursor?: InputMaybe<ModuleWhereUniqueInput>;
  distinct?: InputMaybe<Array<ModuleScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<ModuleOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<ModuleWhereInput>;
};


export type QueryOwnScopesArgs = {
  accessType?: InputMaybe<Scalars['String']>;
  externalId?: InputMaybe<Scalars['String']>;
};


export type QueryPaginatedContentArgs = {
  accessType?: InputMaybe<Scalars['String']>;
  chatId?: InputMaybe<Scalars['String']>;
  cursor?: InputMaybe<ContentWhereUniqueInput>;
  distinct?: InputMaybe<Array<ContentScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<ContentOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<ContentWhereInput>;
};


export type QueryPaginatedContentForRuleArgs = {
  rule: Scalars['JSON'];
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
};


export type QueryPaginatedScopeArgs = {
  cursor?: InputMaybe<ScopeWhereUniqueInput>;
  distinct?: InputMaybe<Array<ScopeScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<ScopeOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<ScopeWhereInput>;
};


export type QueryPaginatedUsersArgs = {
  cursor?: InputMaybe<UserWhereUniqueInput>;
  distinct?: InputMaybe<Array<UserScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<UserOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<UserWhereInput>;
};


export type QueryScopeRulesByAssistantArgs = {
  assistantId: Scalars['String'];
};


export type QueryScopesArgs = {
  cursor?: InputMaybe<ScopeWhereUniqueInput>;
  distinct?: InputMaybe<Array<ScopeScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<ScopeOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<ScopeWhereInput>;
};


export type QueryScopesByCompanyArgs = {
  cursor?: InputMaybe<ScopeWhereUniqueInput>;
  distinct?: InputMaybe<Array<ScopeScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<ScopeOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<ScopeWhereInput>;
};


export type QuerySearchMembersArgs = {
  limit?: InputMaybe<Scalars['Float']>;
  memberIds?: InputMaybe<Array<Scalars['String']>>;
  page?: InputMaybe<Scalars['Float']>;
  searchText: Scalars['String'];
};


export type QuerySpaceManagerVerifyArgs = {
  assistantIds?: InputMaybe<Array<Scalars['String']>>;
  userId: Scalars['String'];
};


export type QueryStatisticsIngestionArgs = {
  accessType?: InputMaybe<Scalars['String']>;
  chatId?: InputMaybe<Scalars['String']>;
  cursor?: InputMaybe<ContentWhereUniqueInput>;
  distinct?: InputMaybe<Array<ContentScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<ContentOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<ContentWhereInput>;
};


export type QueryTermsAndConditionsArgs = {
  cursor?: InputMaybe<TermsAndConditionsWhereUniqueInput>;
  distinct?: InputMaybe<Array<TermsAndConditionsScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<TermsAndConditionsOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<TermsAndConditionsWhereInput>;
};


export type QueryThemeArgs = {
  themeId?: InputMaybe<Scalars['String']>;
};


export type QueryUserPromptArgs = {
  assistantId: Scalars['String'];
};


export type QueryUserScopesByServiceArgs = {
  accessType: Scalars['String'];
};


export type QueryUsersArgs = {
  cursor?: InputMaybe<UserWhereUniqueInput>;
  distinct?: InputMaybe<Array<UserScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<UserOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<UserWhereInput>;
};

export enum QueryMode {
  Default = 'default',
  Insensitive = 'insensitive'
}

export type Reference = {
  __typename?: 'Reference';
  id: Scalars['ID'];
  message: Message;
  name: Scalars['String'];
  sequenceNumber: Scalars['Int'];
  source: Scalars['String'];
  sourceId: Scalars['String'];
  url?: Maybe<Scalars['String']>;
};

export enum Role {
  Assistant = 'ASSISTANT',
  System = 'SYSTEM',
  User = 'USER'
}

export type Scope = {
  __typename?: 'Scope';
  _count: ScopeCount;
  children?: Maybe<Array<Scope>>;
  companyId: Scalars['String'];
  createdAt: Scalars['DateTime'];
  deleted: Scalars['Boolean'];
  externalId?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  ingestionConfig?: Maybe<Scalars['JSON']>;
  name: Scalars['String'];
  parentId?: Maybe<Scalars['String']>;
  parrentScope?: Maybe<Scope>;
  scopeAccess?: Maybe<Array<ScopeAccess>>;
  updatedAt: Scalars['DateTime'];
};

export type ScopeAccess = {
  __typename?: 'ScopeAccess';
  createdAt: Scalars['DateTime'];
  entityId: Scalars['String'];
  entityType: ScopeAccessEntityType;
  scope: Scope;
  scopeId: Scalars['String'];
  type: ScopeAccessType;
  updatedAt: Scalars['DateTime'];
};

export type ScopeAccessDto = {
  entityId: Scalars['String'];
  entityType: ScopeAccessEntityType;
  type: ScopeAccessType;
};

export enum ScopeAccessEntityType {
  Group = 'GROUP',
  User = 'USER'
}

export type ScopeAccessListRelationFilter = {
  every?: InputMaybe<ScopeAccessWhereInput>;
  none?: InputMaybe<ScopeAccessWhereInput>;
  some?: InputMaybe<ScopeAccessWhereInput>;
};

export type ScopeAccessOrderByRelationAggregateInput = {
  _count?: InputMaybe<SortOrder>;
};

export enum ScopeAccessType {
  Read = 'READ',
  Write = 'WRITE'
}

export type ScopeAccessWhereInput = {
  AND?: InputMaybe<Array<ScopeAccessWhereInput>>;
  NOT?: InputMaybe<Array<ScopeAccessWhereInput>>;
  OR?: InputMaybe<Array<ScopeAccessWhereInput>>;
  entityId?: InputMaybe<StringFilter>;
  entityType?: InputMaybe<EnumScopeAccessEntityTypeFilter>;
  scope?: InputMaybe<ScopeRelationFilter>;
  scopeId?: InputMaybe<StringFilter>;
  type?: InputMaybe<EnumScopeAccessTypeFilter>;
};

export type ScopeCompanyIdExternalIdCompoundUniqueInput = {
  externalId: Scalars['String'];
};

export type ScopeCount = {
  __typename?: 'ScopeCount';
  children: Scalars['Int'];
  scopeAccess: Scalars['Int'];
};

export type ScopeListRelationFilter = {
  every?: InputMaybe<ScopeWhereInput>;
  none?: InputMaybe<ScopeWhereInput>;
  some?: InputMaybe<ScopeWhereInput>;
};

export type ScopeOrderByRelationAggregateInput = {
  _count?: InputMaybe<SortOrder>;
};

export type ScopeOrderByWithRelationInput = {
  children?: InputMaybe<ScopeOrderByRelationAggregateInput>;
  externalId?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  ingestionConfig?: InputMaybe<SortOrder>;
  name?: InputMaybe<SortOrder>;
  parentId?: InputMaybe<SortOrder>;
  parrentScope?: InputMaybe<ScopeOrderByWithRelationInput>;
  scopeAccess?: InputMaybe<ScopeAccessOrderByRelationAggregateInput>;
};

export type ScopeProperties = {
  ingestionConfig?: InputMaybe<IngestionConfig>;
};

export type ScopeRelationFilter = {
  is?: InputMaybe<ScopeWhereInput>;
  isNot?: InputMaybe<ScopeWhereInput>;
};

export type ScopeRule = {
  __typename?: 'ScopeRule';
  assistant: Assistant;
  assistantId: Scalars['String'];
  companyId: Scalars['String'];
  createdAt: Scalars['DateTime'];
  id: Scalars['ID'];
  isAdvanced: Scalars['Boolean'];
  rule: Scalars['JSON'];
  title: Scalars['String'];
  updatedAt: Scalars['DateTime'];
};

export type ScopeRuleCreateManyAssistantInput = {
  createdAt?: InputMaybe<Scalars['DateTime']>;
  id?: InputMaybe<Scalars['String']>;
  isAdvanced?: InputMaybe<Scalars['Boolean']>;
  rule: Scalars['JSON'];
  title: Scalars['String'];
  updatedAt?: InputMaybe<Scalars['DateTime']>;
};

export type ScopeRuleCreateManyAssistantInputEnvelope = {
  data: Array<ScopeRuleCreateManyAssistantInput>;
  skipDuplicates?: InputMaybe<Scalars['Boolean']>;
};

export type ScopeRuleCreateManyInput = {
  createdAt?: InputMaybe<Scalars['DateTime']>;
  id?: InputMaybe<Scalars['String']>;
  isAdvanced?: InputMaybe<Scalars['Boolean']>;
  rule: Scalars['JSON'];
  title: Scalars['String'];
  updatedAt?: InputMaybe<Scalars['DateTime']>;
};

export type ScopeRuleCreateNestedManyWithoutAssistantInput = {
  connect?: InputMaybe<Array<ScopeRuleWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<ScopeRuleCreateOrConnectWithoutAssistantInput>>;
  create?: InputMaybe<Array<ScopeRuleCreateWithoutAssistantInput>>;
  createMany?: InputMaybe<ScopeRuleCreateManyAssistantInputEnvelope>;
};

export type ScopeRuleCreateOrConnectWithoutAssistantInput = {
  create: ScopeRuleCreateWithoutAssistantInput;
  where: ScopeRuleWhereUniqueInput;
};

export type ScopeRuleCreateWithoutAssistantInput = {
  createdAt?: InputMaybe<Scalars['DateTime']>;
  id?: InputMaybe<Scalars['String']>;
  isAdvanced?: InputMaybe<Scalars['Boolean']>;
  rule: Scalars['JSON'];
  title: Scalars['String'];
  updatedAt?: InputMaybe<Scalars['DateTime']>;
};

export type ScopeRuleListRelationFilter = {
  every?: InputMaybe<ScopeRuleWhereInput>;
  none?: InputMaybe<ScopeRuleWhereInput>;
  some?: InputMaybe<ScopeRuleWhereInput>;
};

export type ScopeRuleOrderByRelationAggregateInput = {
  _count?: InputMaybe<SortOrder>;
};

export type ScopeRuleScalarWhereInput = {
  AND?: InputMaybe<Array<ScopeRuleScalarWhereInput>>;
  NOT?: InputMaybe<Array<ScopeRuleScalarWhereInput>>;
  OR?: InputMaybe<Array<ScopeRuleScalarWhereInput>>;
  createdAt?: InputMaybe<DateTimeFilter>;
  id?: InputMaybe<StringFilter>;
  isAdvanced?: InputMaybe<BoolFilter>;
  rule?: InputMaybe<JsonFilter>;
  title?: InputMaybe<StringFilter>;
  updatedAt?: InputMaybe<DateTimeFilter>;
};

export type ScopeRuleUpdateManyMutationInput = {
  createdAt?: InputMaybe<Scalars['DateTime']>;
  id?: InputMaybe<Scalars['String']>;
  isAdvanced?: InputMaybe<Scalars['Boolean']>;
  rule?: InputMaybe<Scalars['JSON']>;
  title?: InputMaybe<Scalars['String']>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
};

export type ScopeRuleUpdateManyWithWhereWithoutAssistantInput = {
  data: ScopeRuleUpdateManyMutationInput;
  where: ScopeRuleScalarWhereInput;
};

export type ScopeRuleUpdateManyWithoutAssistantNestedInput = {
  connect?: InputMaybe<Array<ScopeRuleWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<ScopeRuleCreateOrConnectWithoutAssistantInput>>;
  create?: InputMaybe<Array<ScopeRuleCreateWithoutAssistantInput>>;
  createMany?: InputMaybe<ScopeRuleCreateManyAssistantInputEnvelope>;
  delete?: InputMaybe<Array<ScopeRuleWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<ScopeRuleScalarWhereInput>>;
  disconnect?: InputMaybe<Array<ScopeRuleWhereUniqueInput>>;
  set?: InputMaybe<Array<ScopeRuleWhereUniqueInput>>;
  update?: InputMaybe<Array<ScopeRuleUpdateWithWhereUniqueWithoutAssistantInput>>;
  updateMany?: InputMaybe<Array<ScopeRuleUpdateManyWithWhereWithoutAssistantInput>>;
  upsert?: InputMaybe<Array<ScopeRuleUpsertWithWhereUniqueWithoutAssistantInput>>;
};

export type ScopeRuleUpdateWithWhereUniqueWithoutAssistantInput = {
  data: ScopeRuleUpdateWithoutAssistantInput;
  where: ScopeRuleWhereUniqueInput;
};

export type ScopeRuleUpdateWithoutAssistantInput = {
  createdAt?: InputMaybe<Scalars['DateTime']>;
  id?: InputMaybe<Scalars['String']>;
  isAdvanced?: InputMaybe<Scalars['Boolean']>;
  rule?: InputMaybe<Scalars['JSON']>;
  title?: InputMaybe<Scalars['String']>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
};

export type ScopeRuleUpsertWithWhereUniqueWithoutAssistantInput = {
  create: ScopeRuleCreateWithoutAssistantInput;
  update: ScopeRuleUpdateWithoutAssistantInput;
  where: ScopeRuleWhereUniqueInput;
};

export type ScopeRuleWhereInput = {
  AND?: InputMaybe<Array<ScopeRuleWhereInput>>;
  NOT?: InputMaybe<Array<ScopeRuleWhereInput>>;
  OR?: InputMaybe<Array<ScopeRuleWhereInput>>;
  assistant?: InputMaybe<AssistantRelationFilter>;
  createdAt?: InputMaybe<DateTimeFilter>;
  id?: InputMaybe<StringFilter>;
  isAdvanced?: InputMaybe<BoolFilter>;
  rule?: InputMaybe<JsonFilter>;
  title?: InputMaybe<StringFilter>;
  updatedAt?: InputMaybe<DateTimeFilter>;
};

export type ScopeRuleWhereUniqueInput = {
  id?: InputMaybe<Scalars['String']>;
};

export enum ScopeScalarFieldEnum {
  CompanyId = 'companyId',
  CreatedAt = 'createdAt',
  Deleted = 'deleted',
  ExternalId = 'externalId',
  Id = 'id',
  IngestionConfig = 'ingestionConfig',
  Name = 'name',
  ParentId = 'parentId',
  UpdatedAt = 'updatedAt'
}

export type ScopeWhereInput = {
  AND?: InputMaybe<Array<ScopeWhereInput>>;
  NOT?: InputMaybe<Array<ScopeWhereInput>>;
  OR?: InputMaybe<Array<ScopeWhereInput>>;
  children?: InputMaybe<ScopeListRelationFilter>;
  companyId?: InputMaybe<StringFilter>;
  createdAt?: InputMaybe<DateTimeFilter>;
  deleted?: InputMaybe<BoolFilter>;
  externalId?: InputMaybe<StringNullableFilter>;
  id?: InputMaybe<StringFilter>;
  ingestionConfig?: InputMaybe<JsonNullableFilter>;
  name?: InputMaybe<StringFilter>;
  parentId?: InputMaybe<StringNullableFilter>;
  parrentScope?: InputMaybe<ScopeRelationFilter>;
  scopeAccess?: InputMaybe<ScopeAccessListRelationFilter>;
  updatedAt?: InputMaybe<DateTimeFilter>;
};

export type ScopeWhereUniqueInput = {
  companyId_externalId?: InputMaybe<ScopeCompanyIdExternalIdCompoundUniqueInput>;
  id?: InputMaybe<Scalars['String']>;
};

export type ShortTermMemory = {
  __typename?: 'ShortTermMemory';
  chat?: Maybe<Chat>;
  chatId?: Maybe<Scalars['String']>;
  companyId?: Maybe<Scalars['String']>;
  createdAt: Scalars['DateTime'];
  data: Scalars['JSON'];
  id: Scalars['ID'];
  memoryName: Scalars['String'];
  message?: Maybe<Message>;
  messageId?: Maybe<Scalars['String']>;
};

export enum SortOrder {
  Asc = 'asc',
  Desc = 'desc'
}

export type Source = {
  __typename?: 'Source';
  _count: SourceCount;
  /** @DtoReadOnly */
  createdAt: Scalars['DateTime'];
  id: Scalars['ID'];
  kind: SourceKind;
  name?: Maybe<Scalars['String']>;
  /** @DtoReadOnly */
  ownerId: Scalars['String'];
  /** @DtoReadOnly */
  ownerType: OwnerType;
  /** @DtoReadOnly */
  updatedAt: Scalars['DateTime'];
};

export type SourceCount = {
  __typename?: 'SourceCount';
  content: Scalars['Int'];
};

export enum SourceKind {
  AtlassianConfluenceCloud = 'ATLASSIAN_CONFLUENCE_CLOUD',
  AtlassianConfluenceOnprem = 'ATLASSIAN_CONFLUENCE_ONPREM',
  FileDownload = 'FILE_DOWNLOAD',
  Intranet = 'INTRANET',
  Microsoft_365Sharepoint = 'MICROSOFT_365_SHAREPOINT',
  UniqueBlobStorage = 'UNIQUE_BLOB_STORAGE'
}

export type SourceRelationFilter = {
  is?: InputMaybe<SourceWhereInput>;
  isNot?: InputMaybe<SourceWhereInput>;
};

export type SourceWhereInput = {
  AND?: InputMaybe<Array<SourceWhereInput>>;
  NOT?: InputMaybe<Array<SourceWhereInput>>;
  OR?: InputMaybe<Array<SourceWhereInput>>;
  createdAt?: InputMaybe<DateTimeFilter>;
  id?: InputMaybe<StringFilter>;
  kind?: InputMaybe<EnumSourceKindFilter>;
  name?: InputMaybe<StringNullableFilter>;
  ownerType?: InputMaybe<EnumOwnerTypeFilter>;
  updatedAt?: InputMaybe<DateTimeFilter>;
};

export type StatisticsIngestion = {
  __typename?: 'StatisticsIngestion';
  counts: Scalars['JSON'];
};

export type StreamingError = {
  __typename?: 'StreamingError';
  message: Scalars['String'];
  status: Scalars['Int'];
};

export type StringFilter = {
  contains?: InputMaybe<Scalars['String']>;
  endsWith?: InputMaybe<Scalars['String']>;
  equals?: InputMaybe<Scalars['String']>;
  gt?: InputMaybe<Scalars['String']>;
  gte?: InputMaybe<Scalars['String']>;
  in?: InputMaybe<Array<Scalars['String']>>;
  lt?: InputMaybe<Scalars['String']>;
  lte?: InputMaybe<Scalars['String']>;
  mode?: InputMaybe<QueryMode>;
  not?: InputMaybe<NestedStringFilter>;
  notIn?: InputMaybe<Array<Scalars['String']>>;
  startsWith?: InputMaybe<Scalars['String']>;
};

export type StringNullableFilter = {
  contains?: InputMaybe<Scalars['String']>;
  endsWith?: InputMaybe<Scalars['String']>;
  equals?: InputMaybe<Scalars['String']>;
  gt?: InputMaybe<Scalars['String']>;
  gte?: InputMaybe<Scalars['String']>;
  in?: InputMaybe<Array<Scalars['String']>>;
  lt?: InputMaybe<Scalars['String']>;
  lte?: InputMaybe<Scalars['String']>;
  mode?: InputMaybe<QueryMode>;
  not?: InputMaybe<NestedStringNullableFilter>;
  notIn?: InputMaybe<Array<Scalars['String']>>;
  startsWith?: InputMaybe<Scalars['String']>;
};

export type StringNullableListFilter = {
  equals?: InputMaybe<Array<Scalars['String']>>;
  has?: InputMaybe<Scalars['String']>;
  hasEvery?: InputMaybe<Array<Scalars['String']>>;
  hasSome?: InputMaybe<Array<Scalars['String']>>;
  isEmpty?: InputMaybe<Scalars['Boolean']>;
};

export type Subscription = {
  __typename?: 'Subscription';
  chatUpdate: Chat;
  magicTableCellUpdate: MagicTableCell;
  magicTableSheetUpdate: MagicTableSheet;
  messageUpdate: Message;
  streamingError: StreamingError;
};


export type SubscriptionMagicTableCellUpdateArgs = {
  sheetId: Scalars['String'];
};


export type SubscriptionMagicTableSheetUpdateArgs = {
  sheetId: Scalars['String'];
};


export type SubscriptionMessageUpdateArgs = {
  chatId: Scalars['String'];
};


export type SubscriptionStreamingErrorArgs = {
  chatId: Scalars['String'];
};

export type TermsAndConditions = {
  __typename?: 'TermsAndConditions';
  TermsAndConditionsAgreement?: Maybe<Array<TermsAndConditionsAgreement>>;
  _count: TermsAndConditionsCount;
  acceptanceStatus: Scalars['String'];
  company: Company;
  companyId: Scalars['String'];
  createdAt: Scalars['DateTime'];
  createdBy: Scalars['String'];
  id: Scalars['ID'];
  isUniqueTermsAndConditions: Scalars['Boolean'];
  text: Scalars['String'];
};

export type TermsAndConditionsAgreement = {
  __typename?: 'TermsAndConditionsAgreement';
  createdAt: Scalars['DateTime'];
  id: Scalars['ID'];
  termsAndConditions: TermsAndConditions;
  termsAndConditionsId: Scalars['String'];
  updatedAt: Scalars['DateTime'];
};

export type TermsAndConditionsCount = {
  __typename?: 'TermsAndConditionsCount';
  TermsAndConditionsAgreement: Scalars['Int'];
};

export type TermsAndConditionsCreateManyCompanyInput = {
  id?: InputMaybe<Scalars['String']>;
  text: Scalars['String'];
};

export type TermsAndConditionsCreateManyCompanyInputEnvelope = {
  data: Array<TermsAndConditionsCreateManyCompanyInput>;
  skipDuplicates?: InputMaybe<Scalars['Boolean']>;
};

export type TermsAndConditionsCreateNestedManyWithoutCompanyInput = {
  connect?: InputMaybe<Array<TermsAndConditionsWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<TermsAndConditionsCreateOrConnectWithoutCompanyInput>>;
  create?: InputMaybe<Array<TermsAndConditionsCreateWithoutCompanyInput>>;
  createMany?: InputMaybe<TermsAndConditionsCreateManyCompanyInputEnvelope>;
};

export type TermsAndConditionsCreateOrConnectWithoutCompanyInput = {
  create: TermsAndConditionsCreateWithoutCompanyInput;
  where: TermsAndConditionsWhereUniqueInput;
};

export type TermsAndConditionsCreateWithoutCompanyInput = {
  id?: InputMaybe<Scalars['String']>;
  text: Scalars['String'];
};

export type TermsAndConditionsListRelationFilter = {
  every?: InputMaybe<TermsAndConditionsWhereInput>;
  none?: InputMaybe<TermsAndConditionsWhereInput>;
  some?: InputMaybe<TermsAndConditionsWhereInput>;
};

export type TermsAndConditionsOrderByRelationAggregateInput = {
  _count?: InputMaybe<SortOrder>;
};

export type TermsAndConditionsOrderByWithRelationInput = {
  id?: InputMaybe<SortOrder>;
  text?: InputMaybe<SortOrder>;
};

export enum TermsAndConditionsScalarFieldEnum {
  CompanyId = 'companyId',
  CreatedAt = 'createdAt',
  CreatedBy = 'createdBy',
  Id = 'id',
  IsUniqueTermsAndConditions = 'isUniqueTermsAndConditions',
  Text = 'text'
}

export type TermsAndConditionsScalarWhereInput = {
  AND?: InputMaybe<Array<TermsAndConditionsScalarWhereInput>>;
  NOT?: InputMaybe<Array<TermsAndConditionsScalarWhereInput>>;
  OR?: InputMaybe<Array<TermsAndConditionsScalarWhereInput>>;
  id?: InputMaybe<StringFilter>;
  text?: InputMaybe<StringFilter>;
};

export type TermsAndConditionsUpdateManyMutationInput = {
  id?: InputMaybe<Scalars['String']>;
  text?: InputMaybe<Scalars['String']>;
};

export type TermsAndConditionsUpdateManyWithWhereWithoutCompanyInput = {
  data: TermsAndConditionsUpdateManyMutationInput;
  where: TermsAndConditionsScalarWhereInput;
};

export type TermsAndConditionsUpdateManyWithoutCompanyNestedInput = {
  connect?: InputMaybe<Array<TermsAndConditionsWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<TermsAndConditionsCreateOrConnectWithoutCompanyInput>>;
  create?: InputMaybe<Array<TermsAndConditionsCreateWithoutCompanyInput>>;
  createMany?: InputMaybe<TermsAndConditionsCreateManyCompanyInputEnvelope>;
  delete?: InputMaybe<Array<TermsAndConditionsWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<TermsAndConditionsScalarWhereInput>>;
  disconnect?: InputMaybe<Array<TermsAndConditionsWhereUniqueInput>>;
  set?: InputMaybe<Array<TermsAndConditionsWhereUniqueInput>>;
  update?: InputMaybe<Array<TermsAndConditionsUpdateWithWhereUniqueWithoutCompanyInput>>;
  updateMany?: InputMaybe<Array<TermsAndConditionsUpdateManyWithWhereWithoutCompanyInput>>;
  upsert?: InputMaybe<Array<TermsAndConditionsUpsertWithWhereUniqueWithoutCompanyInput>>;
};

export type TermsAndConditionsUpdateWithWhereUniqueWithoutCompanyInput = {
  data: TermsAndConditionsUpdateWithoutCompanyInput;
  where: TermsAndConditionsWhereUniqueInput;
};

export type TermsAndConditionsUpdateWithoutCompanyInput = {
  id?: InputMaybe<Scalars['String']>;
  text?: InputMaybe<Scalars['String']>;
};

export type TermsAndConditionsUpsertWithWhereUniqueWithoutCompanyInput = {
  create: TermsAndConditionsCreateWithoutCompanyInput;
  update: TermsAndConditionsUpdateWithoutCompanyInput;
  where: TermsAndConditionsWhereUniqueInput;
};

export type TermsAndConditionsWhereInput = {
  AND?: InputMaybe<Array<TermsAndConditionsWhereInput>>;
  NOT?: InputMaybe<Array<TermsAndConditionsWhereInput>>;
  OR?: InputMaybe<Array<TermsAndConditionsWhereInput>>;
  id?: InputMaybe<StringFilter>;
  text?: InputMaybe<StringFilter>;
};

export type TermsAndConditionsWhereUniqueInput = {
  id?: InputMaybe<Scalars['String']>;
};

export type Theme = {
  __typename?: 'Theme';
  _count: ThemeCount;
  colors?: Maybe<Array<Color>>;
  company?: Maybe<Array<Company>>;
  createdAt?: Maybe<Scalars['DateTime']>;
  favicon?: Maybe<Scalars['String']>;
  fontFamilies?: Maybe<Array<FontFamily>>;
  fonts?: Maybe<Array<Font>>;
  helpCenterLink?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  isUniqueTheme?: Maybe<Scalars['Boolean']>;
  logoHeader?: Maybe<Scalars['String']>;
  logoNavbar?: Maybe<Scalars['String']>;
  settings?: Maybe<Scalars['JSON']>;
  supportEmail?: Maybe<Scalars['String']>;
  tabName?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  users?: Maybe<Array<User>>;
};

export type ThemeCount = {
  __typename?: 'ThemeCount';
  colors: Scalars['Int'];
  company: Scalars['Int'];
  fontFamilies: Scalars['Int'];
  fonts: Scalars['Int'];
  users: Scalars['Int'];
};

export type ThemeCreateInput = {
  colors?: InputMaybe<ColorCreateNestedManyWithoutThemeInput>;
  company?: InputMaybe<CompanyCreateNestedManyWithoutThemeInput>;
  createdAt?: InputMaybe<Scalars['DateTime']>;
  favicon?: InputMaybe<Scalars['String']>;
  fontFamilies?: InputMaybe<FontFamilyCreateNestedManyWithoutThemeInput>;
  fonts?: InputMaybe<FontCreateNestedManyWithoutThemeInput>;
  helpCenterLink?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['String']>;
  isUniqueTheme?: InputMaybe<Scalars['Boolean']>;
  logoHeader?: InputMaybe<Scalars['String']>;
  logoNavbar?: InputMaybe<Scalars['String']>;
  settings?: InputMaybe<Scalars['JSON']>;
  supportEmail?: InputMaybe<Scalars['String']>;
  tabName?: InputMaybe<Scalars['String']>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
  users?: InputMaybe<UserCreateNestedManyWithoutThemeInput>;
};

export type ThemeCreateNestedOneWithoutCompanyInput = {
  connect?: InputMaybe<ThemeWhereUniqueInput>;
  connectOrCreate?: InputMaybe<ThemeCreateOrConnectWithoutCompanyInput>;
  create?: InputMaybe<ThemeCreateWithoutCompanyInput>;
};

export type ThemeCreateNestedOneWithoutUsersInput = {
  connect?: InputMaybe<ThemeWhereUniqueInput>;
  connectOrCreate?: InputMaybe<ThemeCreateOrConnectWithoutUsersInput>;
  create?: InputMaybe<ThemeCreateWithoutUsersInput>;
};

export type ThemeCreateOrConnectWithoutCompanyInput = {
  create: ThemeCreateWithoutCompanyInput;
  where: ThemeWhereUniqueInput;
};

export type ThemeCreateOrConnectWithoutUsersInput = {
  create: ThemeCreateWithoutUsersInput;
  where: ThemeWhereUniqueInput;
};

export type ThemeCreateWithoutCompanyInput = {
  colors?: InputMaybe<ColorCreateNestedManyWithoutThemeInput>;
  createdAt?: InputMaybe<Scalars['DateTime']>;
  favicon?: InputMaybe<Scalars['String']>;
  fontFamilies?: InputMaybe<FontFamilyCreateNestedManyWithoutThemeInput>;
  fonts?: InputMaybe<FontCreateNestedManyWithoutThemeInput>;
  helpCenterLink?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['String']>;
  isUniqueTheme?: InputMaybe<Scalars['Boolean']>;
  logoHeader?: InputMaybe<Scalars['String']>;
  logoNavbar?: InputMaybe<Scalars['String']>;
  settings?: InputMaybe<Scalars['JSON']>;
  supportEmail?: InputMaybe<Scalars['String']>;
  tabName?: InputMaybe<Scalars['String']>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
  users?: InputMaybe<UserCreateNestedManyWithoutThemeInput>;
};

export type ThemeCreateWithoutUsersInput = {
  colors?: InputMaybe<ColorCreateNestedManyWithoutThemeInput>;
  company?: InputMaybe<CompanyCreateNestedManyWithoutThemeInput>;
  createdAt?: InputMaybe<Scalars['DateTime']>;
  favicon?: InputMaybe<Scalars['String']>;
  fontFamilies?: InputMaybe<FontFamilyCreateNestedManyWithoutThemeInput>;
  fonts?: InputMaybe<FontCreateNestedManyWithoutThemeInput>;
  helpCenterLink?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['String']>;
  isUniqueTheme?: InputMaybe<Scalars['Boolean']>;
  logoHeader?: InputMaybe<Scalars['String']>;
  logoNavbar?: InputMaybe<Scalars['String']>;
  settings?: InputMaybe<Scalars['JSON']>;
  supportEmail?: InputMaybe<Scalars['String']>;
  tabName?: InputMaybe<Scalars['String']>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
};

export type ThemeUpdateInput = {
  colors?: InputMaybe<ColorUpdateManyWithoutThemeNestedInput>;
  company?: InputMaybe<CompanyUpdateManyWithoutThemeNestedInput>;
  createdAt?: InputMaybe<Scalars['DateTime']>;
  favicon?: InputMaybe<Scalars['String']>;
  fontFamilies?: InputMaybe<FontFamilyUpdateManyWithoutThemeNestedInput>;
  fonts?: InputMaybe<FontUpdateManyWithoutThemeNestedInput>;
  helpCenterLink?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['String']>;
  isUniqueTheme?: InputMaybe<Scalars['Boolean']>;
  logoHeader?: InputMaybe<Scalars['String']>;
  logoNavbar?: InputMaybe<Scalars['String']>;
  settings?: InputMaybe<Scalars['JSON']>;
  supportEmail?: InputMaybe<Scalars['String']>;
  tabName?: InputMaybe<Scalars['String']>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
  users?: InputMaybe<UserUpdateManyWithoutThemeNestedInput>;
};

export type ThemeUpdateOneWithoutCompanyNestedInput = {
  connect?: InputMaybe<ThemeWhereUniqueInput>;
  connectOrCreate?: InputMaybe<ThemeCreateOrConnectWithoutCompanyInput>;
  create?: InputMaybe<ThemeCreateWithoutCompanyInput>;
  delete?: InputMaybe<Scalars['Boolean']>;
  disconnect?: InputMaybe<Scalars['Boolean']>;
  update?: InputMaybe<ThemeUpdateWithoutCompanyInput>;
  upsert?: InputMaybe<ThemeUpsertWithoutCompanyInput>;
};

export type ThemeUpdateOneWithoutUsersNestedInput = {
  connect?: InputMaybe<ThemeWhereUniqueInput>;
  connectOrCreate?: InputMaybe<ThemeCreateOrConnectWithoutUsersInput>;
  create?: InputMaybe<ThemeCreateWithoutUsersInput>;
  delete?: InputMaybe<Scalars['Boolean']>;
  disconnect?: InputMaybe<Scalars['Boolean']>;
  update?: InputMaybe<ThemeUpdateWithoutUsersInput>;
  upsert?: InputMaybe<ThemeUpsertWithoutUsersInput>;
};

export type ThemeUpdateWithoutCompanyInput = {
  colors?: InputMaybe<ColorUpdateManyWithoutThemeNestedInput>;
  createdAt?: InputMaybe<Scalars['DateTime']>;
  favicon?: InputMaybe<Scalars['String']>;
  fontFamilies?: InputMaybe<FontFamilyUpdateManyWithoutThemeNestedInput>;
  fonts?: InputMaybe<FontUpdateManyWithoutThemeNestedInput>;
  helpCenterLink?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['String']>;
  isUniqueTheme?: InputMaybe<Scalars['Boolean']>;
  logoHeader?: InputMaybe<Scalars['String']>;
  logoNavbar?: InputMaybe<Scalars['String']>;
  settings?: InputMaybe<Scalars['JSON']>;
  supportEmail?: InputMaybe<Scalars['String']>;
  tabName?: InputMaybe<Scalars['String']>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
  users?: InputMaybe<UserUpdateManyWithoutThemeNestedInput>;
};

export type ThemeUpdateWithoutUsersInput = {
  colors?: InputMaybe<ColorUpdateManyWithoutThemeNestedInput>;
  company?: InputMaybe<CompanyUpdateManyWithoutThemeNestedInput>;
  createdAt?: InputMaybe<Scalars['DateTime']>;
  favicon?: InputMaybe<Scalars['String']>;
  fontFamilies?: InputMaybe<FontFamilyUpdateManyWithoutThemeNestedInput>;
  fonts?: InputMaybe<FontUpdateManyWithoutThemeNestedInput>;
  helpCenterLink?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['String']>;
  isUniqueTheme?: InputMaybe<Scalars['Boolean']>;
  logoHeader?: InputMaybe<Scalars['String']>;
  logoNavbar?: InputMaybe<Scalars['String']>;
  settings?: InputMaybe<Scalars['JSON']>;
  supportEmail?: InputMaybe<Scalars['String']>;
  tabName?: InputMaybe<Scalars['String']>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
};

export type ThemeUpsertWithoutCompanyInput = {
  create: ThemeCreateWithoutCompanyInput;
  update: ThemeUpdateWithoutCompanyInput;
};

export type ThemeUpsertWithoutUsersInput = {
  create: ThemeCreateWithoutUsersInput;
  update: ThemeUpdateWithoutUsersInput;
};

export type ThemeWhereUniqueInput = {
  id?: InputMaybe<Scalars['String']>;
};

export type TransformedMagicTableResponse = {
  __typename?: 'TransformedMagicTableResponse';
  magicTableSheet?: Maybe<TransformedMagicTableSheet>;
  message?: Maybe<Scalars['String']>;
  status: Scalars['Boolean'];
};

export type TransformedMagicTableSheet = {
  __typename?: 'TransformedMagicTableSheet';
  chat: Chat;
  companyId: Scalars['String'];
  createdAt: Scalars['DateTime'];
  dueDiligence?: Maybe<DueDiligence>;
  magicTableArtifact?: Maybe<Array<MagicTableArtifact>>;
  name: Scalars['String'];
  ownerId: Scalars['String'];
  rows?: Maybe<Array<Scalars['JSONObject']>>;
  sheetId: Scalars['String'];
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type User = {
  __typename?: 'User';
  company: Company;
  companyId: Scalars['String'];
  createdAt: Scalars['DateTime'];
  displayName?: Maybe<Scalars['String']>;
  email: Scalars['String'];
  firstName?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  lastName?: Maybe<Scalars['String']>;
  setupDoneAt?: Maybe<Scalars['DateTime']>;
  theme?: Maybe<Theme>;
  themeId?: Maybe<Scalars['String']>;
  updatedAt: Scalars['DateTime'];
  userConfiguration: Scalars['JSON'];
  userMetadata: Scalars['JSON'];
  userName?: Maybe<Scalars['String']>;
};

export type UserCreateManyCompanyInput = {
  createdAt?: InputMaybe<Scalars['DateTime']>;
  id?: InputMaybe<Scalars['String']>;
  themeId?: InputMaybe<Scalars['String']>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
};

export type UserCreateManyCompanyInputEnvelope = {
  data: Array<UserCreateManyCompanyInput>;
  skipDuplicates?: InputMaybe<Scalars['Boolean']>;
};

export type UserCreateManyThemeInput = {
  companyId: Scalars['String'];
  createdAt?: InputMaybe<Scalars['DateTime']>;
  id?: InputMaybe<Scalars['String']>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
};

export type UserCreateManyThemeInputEnvelope = {
  data: Array<UserCreateManyThemeInput>;
  skipDuplicates?: InputMaybe<Scalars['Boolean']>;
};

export type UserCreateNestedManyWithoutCompanyInput = {
  connect?: InputMaybe<Array<UserWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<UserCreateOrConnectWithoutCompanyInput>>;
  create?: InputMaybe<Array<UserCreateWithoutCompanyInput>>;
  createMany?: InputMaybe<UserCreateManyCompanyInputEnvelope>;
};

export type UserCreateNestedManyWithoutThemeInput = {
  connect?: InputMaybe<Array<UserWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<UserCreateOrConnectWithoutThemeInput>>;
  create?: InputMaybe<Array<UserCreateWithoutThemeInput>>;
  createMany?: InputMaybe<UserCreateManyThemeInputEnvelope>;
};

export type UserCreateOrConnectWithoutCompanyInput = {
  create: UserCreateWithoutCompanyInput;
  where: UserWhereUniqueInput;
};

export type UserCreateOrConnectWithoutThemeInput = {
  create: UserCreateWithoutThemeInput;
  where: UserWhereUniqueInput;
};

export type UserCreateWithoutCompanyInput = {
  createdAt?: InputMaybe<Scalars['DateTime']>;
  id?: InputMaybe<Scalars['String']>;
  theme?: InputMaybe<ThemeCreateNestedOneWithoutUsersInput>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
};

export type UserCreateWithoutThemeInput = {
  company: CompanyCreateNestedOneWithoutUsersInput;
  createdAt?: InputMaybe<Scalars['DateTime']>;
  id?: InputMaybe<Scalars['String']>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
};

export type UserListRelationFilter = {
  every?: InputMaybe<UserWhereInput>;
  none?: InputMaybe<UserWhereInput>;
  some?: InputMaybe<UserWhereInput>;
};

export type UserOrderByRelationAggregateInput = {
  _count?: InputMaybe<SortOrder>;
};

export type UserOrderByWithRelationInput = {
  company?: InputMaybe<CompanyOrderByWithRelationInput>;
  companyId?: InputMaybe<SortOrder>;
  createdAt?: InputMaybe<SortOrder>;
  displayName?: InputMaybe<SortOrder>;
  email?: InputMaybe<SortOrder>;
  firstName?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  lastName?: InputMaybe<SortOrder>;
  setupDoneAt?: InputMaybe<SortOrder>;
  updatedAt?: InputMaybe<SortOrder>;
  userConfiguration?: InputMaybe<SortOrder>;
  userName?: InputMaybe<SortOrder>;
};

export type UserPrompt = {
  __typename?: 'UserPrompt';
  assistant: Assistant;
  assistantId: Scalars['String'];
  createdAt: Scalars['DateTime'];
  id: Scalars['ID'];
  ownedBy?: Maybe<Scalars['String']>;
  prompt: Scalars['String'];
  title: Scalars['String'];
  updatedAt: Scalars['DateTime'];
};

export type UserPromptCreateInput = {
  id?: InputMaybe<Scalars['String']>;
  ownedBy?: InputMaybe<Scalars['String']>;
  prompt: Scalars['String'];
  title: Scalars['String'];
};

export type UserPromptCreateManyAssistantInput = {
  id?: InputMaybe<Scalars['String']>;
  ownedBy?: InputMaybe<Scalars['String']>;
  prompt: Scalars['String'];
  title: Scalars['String'];
};

export type UserPromptCreateManyAssistantInputEnvelope = {
  data: Array<UserPromptCreateManyAssistantInput>;
  skipDuplicates?: InputMaybe<Scalars['Boolean']>;
};

export type UserPromptCreateManyInput = {
  assistantId: Scalars['String'];
  id?: InputMaybe<Scalars['String']>;
  prompt: Scalars['String'];
  title: Scalars['String'];
};

export type UserPromptCreateNestedManyWithoutAssistantInput = {
  connect?: InputMaybe<Array<UserPromptWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<UserPromptCreateOrConnectWithoutAssistantInput>>;
  create?: InputMaybe<Array<UserPromptCreateWithoutAssistantInput>>;
  createMany?: InputMaybe<UserPromptCreateManyAssistantInputEnvelope>;
};

export type UserPromptCreateOrConnectWithoutAssistantInput = {
  create: UserPromptCreateWithoutAssistantInput;
  where: UserPromptWhereUniqueInput;
};

export type UserPromptCreateWithoutAssistantInput = {
  id?: InputMaybe<Scalars['String']>;
  ownedBy?: InputMaybe<Scalars['String']>;
  prompt: Scalars['String'];
  title: Scalars['String'];
};

export type UserPromptListRelationFilter = {
  every?: InputMaybe<UserPromptWhereInput>;
  none?: InputMaybe<UserPromptWhereInput>;
  some?: InputMaybe<UserPromptWhereInput>;
};

export type UserPromptOrderByRelationAggregateInput = {
  _count?: InputMaybe<SortOrder>;
};

export type UserPromptScalarWhereInput = {
  AND?: InputMaybe<Array<UserPromptScalarWhereInput>>;
  NOT?: InputMaybe<Array<UserPromptScalarWhereInput>>;
  OR?: InputMaybe<Array<UserPromptScalarWhereInput>>;
  assistantId?: InputMaybe<StringFilter>;
  id?: InputMaybe<StringFilter>;
  ownedBy?: InputMaybe<StringNullableFilter>;
  prompt?: InputMaybe<StringFilter>;
  title?: InputMaybe<StringFilter>;
};

export type UserPromptUpdateInput = {
  id?: InputMaybe<Scalars['String']>;
  prompt?: InputMaybe<Scalars['String']>;
  title?: InputMaybe<Scalars['String']>;
};

export type UserPromptUpdateManyMutationInput = {
  id?: InputMaybe<Scalars['String']>;
  prompt?: InputMaybe<Scalars['String']>;
  title?: InputMaybe<Scalars['String']>;
};

export type UserPromptUpdateManyWithWhereWithoutAssistantInput = {
  data: UserPromptUpdateManyMutationInput;
  where: UserPromptScalarWhereInput;
};

export type UserPromptUpdateManyWithoutAssistantNestedInput = {
  connect?: InputMaybe<Array<UserPromptWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<UserPromptCreateOrConnectWithoutAssistantInput>>;
  create?: InputMaybe<Array<UserPromptCreateWithoutAssistantInput>>;
  createMany?: InputMaybe<UserPromptCreateManyAssistantInputEnvelope>;
  delete?: InputMaybe<Array<UserPromptWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<UserPromptScalarWhereInput>>;
  disconnect?: InputMaybe<Array<UserPromptWhereUniqueInput>>;
  set?: InputMaybe<Array<UserPromptWhereUniqueInput>>;
  update?: InputMaybe<Array<UserPromptUpdateWithWhereUniqueWithoutAssistantInput>>;
  updateMany?: InputMaybe<Array<UserPromptUpdateManyWithWhereWithoutAssistantInput>>;
  upsert?: InputMaybe<Array<UserPromptUpsertWithWhereUniqueWithoutAssistantInput>>;
};

export type UserPromptUpdateWithWhereUniqueWithoutAssistantInput = {
  data: UserPromptUpdateWithoutAssistantInput;
  where: UserPromptWhereUniqueInput;
};

export type UserPromptUpdateWithoutAssistantInput = {
  id?: InputMaybe<Scalars['String']>;
  prompt?: InputMaybe<Scalars['String']>;
  title?: InputMaybe<Scalars['String']>;
};

export type UserPromptUpsertWithWhereUniqueWithoutAssistantInput = {
  create: UserPromptCreateWithoutAssistantInput;
  update: UserPromptUpdateWithoutAssistantInput;
  where: UserPromptWhereUniqueInput;
};

export type UserPromptWhereInput = {
  AND?: InputMaybe<Array<UserPromptWhereInput>>;
  NOT?: InputMaybe<Array<UserPromptWhereInput>>;
  OR?: InputMaybe<Array<UserPromptWhereInput>>;
  assistantId?: InputMaybe<StringFilter>;
  id?: InputMaybe<StringFilter>;
  ownedBy?: InputMaybe<StringNullableFilter>;
  prompt?: InputMaybe<StringFilter>;
  title?: InputMaybe<StringFilter>;
};

export type UserPromptWhereUniqueInput = {
  id?: InputMaybe<Scalars['String']>;
};

export enum UserScalarFieldEnum {
  CompanyId = 'companyId',
  CreatedAt = 'createdAt',
  DisplayName = 'displayName',
  Email = 'email',
  FirstName = 'firstName',
  Id = 'id',
  LastName = 'lastName',
  SetupDoneAt = 'setupDoneAt',
  UpdatedAt = 'updatedAt',
  UserConfiguration = 'userConfiguration',
  UserName = 'userName'
}

export type UserScalarWhereInput = {
  AND?: InputMaybe<Array<UserScalarWhereInput>>;
  NOT?: InputMaybe<Array<UserScalarWhereInput>>;
  OR?: InputMaybe<Array<UserScalarWhereInput>>;
  companyId?: InputMaybe<StringFilter>;
  createdAt?: InputMaybe<DateTimeNullableFilter>;
  id?: InputMaybe<StringFilter>;
  themeId?: InputMaybe<StringNullableFilter>;
  updatedAt?: InputMaybe<DateTimeNullableFilter>;
};

export type UserUpdateManyMutationInput = {
  createdAt?: InputMaybe<Scalars['DateTime']>;
  id?: InputMaybe<Scalars['String']>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
};

export type UserUpdateManyWithWhereWithoutCompanyInput = {
  data: UserUpdateManyMutationInput;
  where: UserScalarWhereInput;
};

export type UserUpdateManyWithWhereWithoutThemeInput = {
  data: UserUpdateManyMutationInput;
  where: UserScalarWhereInput;
};

export type UserUpdateManyWithoutCompanyNestedInput = {
  connect?: InputMaybe<Array<UserWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<UserCreateOrConnectWithoutCompanyInput>>;
  create?: InputMaybe<Array<UserCreateWithoutCompanyInput>>;
  createMany?: InputMaybe<UserCreateManyCompanyInputEnvelope>;
  delete?: InputMaybe<Array<UserWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<UserScalarWhereInput>>;
  disconnect?: InputMaybe<Array<UserWhereUniqueInput>>;
  set?: InputMaybe<Array<UserWhereUniqueInput>>;
  update?: InputMaybe<Array<UserUpdateWithWhereUniqueWithoutCompanyInput>>;
  updateMany?: InputMaybe<Array<UserUpdateManyWithWhereWithoutCompanyInput>>;
  upsert?: InputMaybe<Array<UserUpsertWithWhereUniqueWithoutCompanyInput>>;
};

export type UserUpdateManyWithoutThemeNestedInput = {
  connect?: InputMaybe<Array<UserWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<UserCreateOrConnectWithoutThemeInput>>;
  create?: InputMaybe<Array<UserCreateWithoutThemeInput>>;
  createMany?: InputMaybe<UserCreateManyThemeInputEnvelope>;
  delete?: InputMaybe<Array<UserWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<UserScalarWhereInput>>;
  disconnect?: InputMaybe<Array<UserWhereUniqueInput>>;
  set?: InputMaybe<Array<UserWhereUniqueInput>>;
  update?: InputMaybe<Array<UserUpdateWithWhereUniqueWithoutThemeInput>>;
  updateMany?: InputMaybe<Array<UserUpdateManyWithWhereWithoutThemeInput>>;
  upsert?: InputMaybe<Array<UserUpsertWithWhereUniqueWithoutThemeInput>>;
};

export type UserUpdateWithWhereUniqueWithoutCompanyInput = {
  data: UserUpdateWithoutCompanyInput;
  where: UserWhereUniqueInput;
};

export type UserUpdateWithWhereUniqueWithoutThemeInput = {
  data: UserUpdateWithoutThemeInput;
  where: UserWhereUniqueInput;
};

export type UserUpdateWithoutCompanyInput = {
  createdAt?: InputMaybe<Scalars['DateTime']>;
  id?: InputMaybe<Scalars['String']>;
  theme?: InputMaybe<ThemeUpdateOneWithoutUsersNestedInput>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
};

export type UserUpdateWithoutThemeInput = {
  company?: InputMaybe<CompanyUpdateOneRequiredWithoutUsersNestedInput>;
  createdAt?: InputMaybe<Scalars['DateTime']>;
  id?: InputMaybe<Scalars['String']>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
};

export type UserUpsertWithWhereUniqueWithoutCompanyInput = {
  create: UserCreateWithoutCompanyInput;
  update: UserUpdateWithoutCompanyInput;
  where: UserWhereUniqueInput;
};

export type UserUpsertWithWhereUniqueWithoutThemeInput = {
  create: UserCreateWithoutThemeInput;
  update: UserUpdateWithoutThemeInput;
  where: UserWhereUniqueInput;
};

export type UserWhereInput = {
  AND?: InputMaybe<Array<UserWhereInput>>;
  NOT?: InputMaybe<Array<UserWhereInput>>;
  OR?: InputMaybe<Array<UserWhereInput>>;
  company?: InputMaybe<CompanyRelationFilter>;
  companyId?: InputMaybe<StringFilter>;
  createdAt?: InputMaybe<DateTimeFilter>;
  displayName?: InputMaybe<StringNullableFilter>;
  email?: InputMaybe<StringFilter>;
  firstName?: InputMaybe<StringNullableFilter>;
  id?: InputMaybe<StringFilter>;
  lastName?: InputMaybe<StringNullableFilter>;
  setupDoneAt?: InputMaybe<DateTimeNullableFilter>;
  updatedAt?: InputMaybe<DateTimeFilter>;
  userConfiguration?: InputMaybe<JsonFilter>;
  userName?: InputMaybe<StringNullableFilter>;
};

export type UserWhereUniqueInput = {
  id?: InputMaybe<Scalars['String']>;
};

export type AgreedToTermsAndConditionsMutationVariables = Exact<{
  termsAndConditionsId: Scalars['String'];
}>;


export type AgreedToTermsAndConditionsMutation = { __typename?: 'Mutation', agreedToTermsAndConditions: { __typename?: 'TermsAndConditionsAgreement', id: string, createdAt: any, updatedAt: any, termsAndConditions: { __typename?: 'TermsAndConditions', acceptanceStatus: string, text: string } } };

export type AssistantsQueryVariables = Exact<{
  orderBy?: InputMaybe<Array<AssistantOrderByWithRelationInput> | AssistantOrderByWithRelationInput>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<AssistantWhereInput>;
}>;


export type AssistantsQuery = { __typename?: 'Query', assistants: Array<{ __typename?: 'Assistant', id: string, name: string, defaultForCompanyId?: string | null, createdAt: any, title?: string | null, subtitle?: string | null, explanation?: string | null, alert?: string | null, inputLimit?: number | null, inputPlaceholder?: string | null, chatUpload: ChatUpload, settings?: any | null, userPrompts: Array<{ __typename?: 'UserPrompt', id: string, title: string, prompt: string, ownedBy?: string | null }> }> };

export type FolderTreeQueryVariables = Exact<{
  folderId?: InputMaybe<Scalars['String']>;
  childLevels?: InputMaybe<Scalars['Int']>;
  parentLevels?: InputMaybe<Scalars['Int']>;
}>;


export type FolderTreeQuery = { __typename?: 'Query', folderTree: Array<{ __typename?: 'Scope', id: string, name: string, parentId?: string | null, externalId?: string | null, children?: Array<{ __typename?: 'Scope', id: string, name: string }> | null }> };

export type IgnoreNetPromoterScoreRatingMutationVariables = Exact<{ [key: string]: never; }>;


export type IgnoreNetPromoterScoreRatingMutation = { __typename?: 'Mutation', ignoreNetPromoterScoreRating: { __typename?: 'NetPromoterScoreActivity', companyId: string, createdAt: any, createdBy: string, id: string, type: NetPromoterScoreActivityType, updatedAt: any } };

export type LatestTermsAndConditionsQueryVariables = Exact<{ [key: string]: never; }>;


export type LatestTermsAndConditionsQuery = { __typename?: 'Query', latestTermsAndConditions: { __typename?: 'TermsAndConditions', id: string, acceptanceStatus: string, text: string, createdAt: any } };

export type MeQueryVariables = Exact<{ [key: string]: never; }>;


export type MeQuery = { __typename?: 'Query', me: { __typename?: 'Me', assignments: Array<Assignments>, user: { __typename?: 'User', id: string } } };

export type NetPromoterScoreTriggerCheckQueryVariables = Exact<{ [key: string]: never; }>;


export type NetPromoterScoreTriggerCheckQuery = { __typename?: 'Query', netPromoterScoreTriggerCheck: { __typename?: 'NetPromoterScoreTriggerCheckResponse', delayInSecBeforeQuestionDisplay?: number | null, message: string, question?: string | null, status: boolean } };

export type PaginatedContentQueryVariables = Exact<{
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<ContentOrderByWithRelationInput> | ContentOrderByWithRelationInput>;
  where?: InputMaybe<ContentWhereInput>;
  chatId?: InputMaybe<Scalars['String']>;
}>;


export type PaginatedContentQuery = { __typename?: 'Query', paginatedContent: { __typename?: 'PaginatedContent', totalCount: number, nodes?: Array<{ __typename?: 'Content', id: string, mimeType: string, key: string, title?: string | null, ownerId: string }> | null } };

export type SubmitNetPromoterScoreRatingMutationVariables = Exact<{
  input: NetPromoterScoreRatingCreateInput;
}>;


export type SubmitNetPromoterScoreRatingMutation = { __typename?: 'Mutation', submitNetPromoterScoreRating: { __typename?: 'NetPromoterScoreRating', companyId: string, createdAt: any, createdBy: string, feedback?: string | null, id: string, score: number, updatedAt: any } };

export type CreateTermsAndConditionsMutationVariables = Exact<{
  termsAndConditions: Scalars['String'];
}>;


export type CreateTermsAndConditionsMutation = { __typename?: 'Mutation', createTermsAndConditions: { __typename?: 'TermsAndConditions', text: string, acceptanceStatus: string } };

export type ThemeQueryVariables = Exact<{
  themeId?: InputMaybe<Scalars['String']>;
}>;


export type ThemeQuery = { __typename?: 'Query', theme: { __typename?: 'Theme', id: string, updatedAt?: any | null, logoHeader?: string | null, logoNavbar?: string | null, tabName?: string | null, supportEmail?: string | null, helpCenterLink?: string | null, favicon?: string | null, settings?: any | null, colors?: Array<{ __typename?: 'Color', hexValue: string, name: string, id: string }> | null, fontFamilies?: Array<{ __typename?: 'FontFamily', family: string, id: string, name: string }> | null, fonts?: Array<{ __typename?: 'Font', family: string, id: string, style: string, weight: string, files?: Array<string> | null, fileTypes?: Array<string> | null }> | null } };


export const AgreedToTermsAndConditionsDocument = gql`
    mutation AgreedToTermsAndConditions($termsAndConditionsId: String!) {
  agreedToTermsAndConditions(termsAndConditionsId: $termsAndConditionsId) {
    id
    createdAt
    updatedAt
    termsAndConditions {
      acceptanceStatus
      text
    }
  }
}
    `;
export const AssistantsDocument = gql`
    query Assistants($orderBy: [AssistantOrderByWithRelationInput!], $take: Int, $where: AssistantWhereInput) {
  assistants(orderBy: $orderBy, take: $take, where: $where) {
    id
    name
    defaultForCompanyId
    createdAt
    title
    subtitle
    explanation
    alert
    inputLimit
    inputPlaceholder
    chatUpload
    settings
    userPrompts {
      id
      title
      prompt
      ownedBy
    }
  }
}
    `;
export const FolderTreeDocument = gql`
    query FolderTree($folderId: String, $childLevels: Int, $parentLevels: Int) {
  folderTree(
    folderId: $folderId
    childLevels: $childLevels
    parentLevels: $parentLevels
    accessType: null
  ) {
    id
    name
    parentId
    externalId
    children {
      id
      name
    }
  }
}
    `;
export const IgnoreNetPromoterScoreRatingDocument = gql`
    mutation IgnoreNetPromoterScoreRating {
  ignoreNetPromoterScoreRating {
    companyId
    createdAt
    createdBy
    id
    type
    updatedAt
  }
}
    `;
export const LatestTermsAndConditionsDocument = gql`
    query LatestTermsAndConditions {
  latestTermsAndConditions {
    id
    acceptanceStatus
    text
    createdAt
  }
}
    `;
export const MeDocument = gql`
    query Me {
  me {
    user {
      id
    }
    assignments
  }
}
    `;
export const NetPromoterScoreTriggerCheckDocument = gql`
    query NetPromoterScoreTriggerCheck {
  netPromoterScoreTriggerCheck {
    delayInSecBeforeQuestionDisplay
    message
    question
    status
  }
}
    `;
export const PaginatedContentDocument = gql`
    query PaginatedContent($skip: Int, $take: Int, $orderBy: [ContentOrderByWithRelationInput!], $where: ContentWhereInput, $chatId: String) {
  paginatedContent(
    skip: $skip
    take: $take
    orderBy: $orderBy
    where: $where
    chatId: $chatId
  ) {
    nodes {
      id
      mimeType
      key
      title
      ownerId
    }
    totalCount
  }
}
    `;
export const SubmitNetPromoterScoreRatingDocument = gql`
    mutation SubmitNetPromoterScoreRating($input: NetPromoterScoreRatingCreateInput!) {
  submitNetPromoterScoreRating(input: $input) {
    companyId
    createdAt
    createdBy
    feedback
    id
    score
    updatedAt
  }
}
    `;
export const CreateTermsAndConditionsDocument = gql`
    mutation CreateTermsAndConditions($termsAndConditions: String!) {
  createTermsAndConditions(termsAndConditions: $termsAndConditions) {
    text
    acceptanceStatus
  }
}
    `;
export const ThemeDocument = gql`
    query Theme($themeId: String) {
  theme(themeId: $themeId) {
    id
    updatedAt
    colors {
      hexValue
      name
      id
    }
    fontFamilies {
      family
      id
      name
    }
    fonts {
      family
      id
      style
      weight
      files
      fileTypes
    }
    logoHeader
    logoNavbar
    tabName
    supportEmail
    helpCenterLink
    favicon
    settings
  }
}
    `;

export type SdkFunctionWrapper = <T>(action: (requestHeaders?:Record<string, string>) => Promise<T>, operationName: string, operationType?: string) => Promise<T>;


const defaultWrapper: SdkFunctionWrapper = (action, _operationName, _operationType) => action();

export function getSdk(client: GraphQLClient, withWrapper: SdkFunctionWrapper = defaultWrapper) {
  return {
    AgreedToTermsAndConditions(variables: AgreedToTermsAndConditionsMutationVariables, requestHeaders?: GraphQLClientRequestHeaders): Promise<AgreedToTermsAndConditionsMutation> {
      return withWrapper((wrappedRequestHeaders) => client.request<AgreedToTermsAndConditionsMutation>(AgreedToTermsAndConditionsDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'AgreedToTermsAndConditions', 'mutation');
    },
    Assistants(variables?: AssistantsQueryVariables, requestHeaders?: GraphQLClientRequestHeaders): Promise<AssistantsQuery> {
      return withWrapper((wrappedRequestHeaders) => client.request<AssistantsQuery>(AssistantsDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'Assistants', 'query');
    },
    FolderTree(variables?: FolderTreeQueryVariables, requestHeaders?: GraphQLClientRequestHeaders): Promise<FolderTreeQuery> {
      return withWrapper((wrappedRequestHeaders) => client.request<FolderTreeQuery>(FolderTreeDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'FolderTree', 'query');
    },
    IgnoreNetPromoterScoreRating(variables?: IgnoreNetPromoterScoreRatingMutationVariables, requestHeaders?: GraphQLClientRequestHeaders): Promise<IgnoreNetPromoterScoreRatingMutation> {
      return withWrapper((wrappedRequestHeaders) => client.request<IgnoreNetPromoterScoreRatingMutation>(IgnoreNetPromoterScoreRatingDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'IgnoreNetPromoterScoreRating', 'mutation');
    },
    LatestTermsAndConditions(variables?: LatestTermsAndConditionsQueryVariables, requestHeaders?: GraphQLClientRequestHeaders): Promise<LatestTermsAndConditionsQuery> {
      return withWrapper((wrappedRequestHeaders) => client.request<LatestTermsAndConditionsQuery>(LatestTermsAndConditionsDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'LatestTermsAndConditions', 'query');
    },
    Me(variables?: MeQueryVariables, requestHeaders?: GraphQLClientRequestHeaders): Promise<MeQuery> {
      return withWrapper((wrappedRequestHeaders) => client.request<MeQuery>(MeDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'Me', 'query');
    },
    NetPromoterScoreTriggerCheck(variables?: NetPromoterScoreTriggerCheckQueryVariables, requestHeaders?: GraphQLClientRequestHeaders): Promise<NetPromoterScoreTriggerCheckQuery> {
      return withWrapper((wrappedRequestHeaders) => client.request<NetPromoterScoreTriggerCheckQuery>(NetPromoterScoreTriggerCheckDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'NetPromoterScoreTriggerCheck', 'query');
    },
    PaginatedContent(variables?: PaginatedContentQueryVariables, requestHeaders?: GraphQLClientRequestHeaders): Promise<PaginatedContentQuery> {
      return withWrapper((wrappedRequestHeaders) => client.request<PaginatedContentQuery>(PaginatedContentDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'PaginatedContent', 'query');
    },
    SubmitNetPromoterScoreRating(variables: SubmitNetPromoterScoreRatingMutationVariables, requestHeaders?: GraphQLClientRequestHeaders): Promise<SubmitNetPromoterScoreRatingMutation> {
      return withWrapper((wrappedRequestHeaders) => client.request<SubmitNetPromoterScoreRatingMutation>(SubmitNetPromoterScoreRatingDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'SubmitNetPromoterScoreRating', 'mutation');
    },
    CreateTermsAndConditions(variables: CreateTermsAndConditionsMutationVariables, requestHeaders?: GraphQLClientRequestHeaders): Promise<CreateTermsAndConditionsMutation> {
      return withWrapper((wrappedRequestHeaders) => client.request<CreateTermsAndConditionsMutation>(CreateTermsAndConditionsDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'CreateTermsAndConditions', 'mutation');
    },
    Theme(variables?: ThemeQueryVariables, requestHeaders?: GraphQLClientRequestHeaders): Promise<ThemeQuery> {
      return withWrapper((wrappedRequestHeaders) => client.request<ThemeQuery>(ThemeDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'Theme', 'query');
    }
  };
}
export type Sdk = ReturnType<typeof getSdk>;