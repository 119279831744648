'use client';
import {
  CellEditingStartedEvent,
  CellEditingStoppedEvent,
  CellEditRequestEvent,
  ColDef,
  GetRowIdParams,
  MenuItemDef,
  RowSelectedEvent,
  SizeColumnsToContentStrategy,
  SizeColumnsToFitGridStrategy,
  SizeColumnsToFitProvidedWidthStrategy,
} from 'ag-grid-community';
import 'ag-grid-community/styles/ag-grid.css'; // Mandatory CSS required by the Data Grid
import 'ag-grid-community/styles/ag-theme-quartz.css'; // Optional Theme applied to the Data Grid
import { LicenseManager, MenuModule, ModuleRegistry } from 'ag-grid-enterprise';
import { AgGridReact } from 'ag-grid-react';
import lodash from 'lodash';
import {
  forwardRef,
  memo,
  useCallback,
  useImperativeHandle,
  useMemo,
  useRef,
  useState,
} from 'react';
import './styles/ag-theme-unique.css'; // Custom Theme applied to the Data Grid
import { idColumn, TOTAL_COLUMNS } from './utils/helpers';

// it's a trial license key, hence it's safe to be here. for the production key we would add to github secret and add to the build process. Sorry Michael, I know you don't like this but it's a trial key.
LicenseManager.setLicenseKey(
  '[TRIAL]_this_{AG_Charts_and_AG_Grid}_Enterprise_key_{AG-069969}_is_granted_for_evaluation_only___Use_in_production_is_not_permitted___Please_report_misuse_to_legal@ag-grid.com___For_help_with_purchasing_a_production_key_please_contact_info@ag-grid.com___You_are_granted_a_{Single_Application}_Developer_License_for_one_application_only___All_Front-End_JavaScript_developers_working_on_the_application_would_need_to_be_licensed___This_key_will_deactivate_on_{14 December 2024}____[v3]_[0102]_MTczNDEzNDQwMDAwMA==780fd123a2c611cd03659bed35285e94',
);
ModuleRegistry.registerModules([MenuModule]);

export type CellEditing = {
  value: string | number;
  columnId: string;
  rowId: number | null;
};

export interface MagicTableResult {
  position: number;
  rowData?: {
    columnId: string;
    value: unknown;
  }[];
}

interface MagicTableProps {
  onRowSelected?: (selected: unknown) => void;
  cellEditingStart?: (event: CellEditing) => void;
  cellEditingEnd?: (event: CellEditing) => void;
  className?: string;
  handleCellEditRequest: ({ rowIndex, columnId, newValue, event }: CellEditRequest) => void;
}
export interface MagicTableRefHandles {
  updateColumnDefs: (newColDefs: ColDef[]) => void;
  updateRowData: (newRowData: unknown[]) => void;
  getTableData: () => MagicTableResult[];
  exportAsCSV: (fileName: string) => void;
  stopEditingCell: () => void;
  updateCellValues: (rowNode: number, columnNode: string, newValue: string) => void;
  getLastOccupiedColumn: () => number;
  getColumnIndex: (columnId: string) => number;
  getColumnAtIndex: (index: number) => string;
}

export interface CellEditRequest {
  rowIndex: number;
  columnId: string;
  newValue: string;
  event: CellEditRequestEvent;
}

const defaultColDef: ColDef = {
  flex: 1,
  sortable: false,
  minWidth: 200,
  editable: true,
  wrapText: true,
  autoHeight: true,
  cellClass: 'custom-ag-cell',
  context: false,
  suppressHeaderMenuButton: true,
  suppressHeaderFilterButton: true,
  suppressHeaderContextMenu: true,
  enableCellChangeFlash: true,
  valueFormatter: (params) => {
    const newValue = params.value;
    const newValueFormatted =
      typeof newValue === 'string' ? newValue?.replace(/\[source\d+\]/g, '') : newValue;
    return newValueFormatted;
  },
};

const Table = forwardRef<MagicTableRefHandles, MagicTableProps>((props, ref) => {
  const {
    onRowSelected,
    cellEditingStart,
    cellEditingEnd,
    className = 'h-[73vh]',
    handleCellEditRequest,
  } = props;
  const [rowData, setRowData] = useState<unknown[]>([]);

  const agGridRef = useRef<AgGridReact>(null);

  const [colDefs, setColDefs] = useState<ColDef[]>([]);

  const updateColumnDefs = (newColDefs: ColDef[]) => {
    setColDefs(newColDefs);
  };

  const updateRowData = (newRowData: unknown[]) => {
    setRowData(newRowData);
  };

  const handleSelect = (event: RowSelectedEvent) => {
    onRowSelected?.(event.data);
  };

  useImperativeHandle(ref, () => ({
    updateColumnDefs,
    updateRowData,
    getTableData,
    exportAsCSV,
    stopEditingCell,
    updateCellValues,
    getLastOccupiedColumn,
    getColumnIndex,
    getColumnAtIndex,
  }));

  const autoSizeStrategy = useMemo<
    | SizeColumnsToFitGridStrategy
    | SizeColumnsToFitProvidedWidthStrategy
    | SizeColumnsToContentStrategy
  >(() => {
    return {
      type: 'fitGridWidth',
      defaultMinWidth: 100,
    };
  }, []);

  const getCellValue = (event: CellEditingStartedEvent | CellEditingStoppedEvent) => {
    return event.api.getCellValue({ colKey: event.column, rowNode: event.node });
  };

  const onCellEditingStarted = useCallback((event: CellEditingStartedEvent) => {
    const cellValue = getCellValue(event);
    cellEditingStart?.({
      value: cellValue,
      columnId: event.column.getId(),
      rowId: event.rowIndex !== null ? event.rowIndex + 1 : null,
    });
  }, []);

  const onCellEditingStopped = useCallback((event: CellEditingStoppedEvent) => {
    const cellValue = getCellValue(event);
    cellEditingEnd?.({
      value: cellValue,
      columnId: event.column.getId(),
      rowId: event.rowIndex !== null ? event.rowIndex + 1 : null,
    });
  }, []);

  // Get the table data in the JSON format
  const getTableData = (): MagicTableResult[] => {
    const rowCount = agGridRef.current?.api.getDisplayedRowCount() || 0;

    return Array.from({ length: rowCount }, (_, i) => {
      const row = agGridRef.current?.api.getDisplayedRowAtIndex(i)?.data || {};
      const { id, ...rest } = row;

      if (Object.keys(rest).length > 0 && id !== undefined) {
        const rowData = Object.entries(rest).map(([columnId, value]) => ({ columnId, value }));
        return { position: id, rowData };
      }
      return null;
    }).filter(Boolean) as MagicTableResult[];
  };

  // Get the column index of the given column name
  const getColumnIndex = (columnId: string) => {
    const columnDefs = agGridRef.current?.api?.getColumnDefs() || [];
    const index = columnDefs.findIndex((col) => col.headerName === columnId);
    return index - 1;
  };

  // Get the column name at the given index
  const getColumnAtIndex = (index: number) => {
    const columnDefs = agGridRef.current?.api?.getColumnDefs() || [];
    return columnDefs[index + 1]?.headerName || '';
  };

  const exportAsCSV = (fileName: string) => {
    agGridRef.current?.api.exportDataAsExcel({
      fileName: fileName,
      skipColumnHeaders: true,
      shouldRowBeSkipped: (params) => {
        const countEmptyStrings = lodash.filter(
          Object.values(params.node.data),
          (value) => value === '',
        ).length;
        return countEmptyStrings === TOTAL_COLUMNS - 1;
      },
      columnKeys: agGridRef.current?.api
        ?.getColumnDefs()
        ?.slice(1)
        ?.map((col: ColDef) => col.field)
        ?.filter((field): field is string => field !== undefined),
    });
  };

  const getContextMenuItems = (): (string | MenuItemDef)[] => {
    return [
      {
        name: 'View History',
      },
    ];
  };

  const getRowId = useCallback(
    (params: GetRowIdParams) => String((params.data as { id: string }).id),
    [],
  );

  const onCellEditRequest = (event: CellEditRequestEvent) => {
    const oldData = event.data;
    const field = event.colDef.field;
    const newValue = event.newValue;
    const newData = { ...oldData };
    newData[field!] = event.newValue;
    if (newData[field!] === oldData[field!]) return;

    const rowIndex = event.node.sourceRowIndex;
    const columnId = event.colDef.field!;

    const tx = {
      update: [newData],
    };

    event.api.applyTransaction(tx);

    handleCellEditRequest({ rowIndex, columnId, newValue, event });
  };

  const updateCellValues = (rowNode: number, columnNode: string, newValue: string) => {
    const row = agGridRef.current?.api.getDisplayedRowAtIndex(rowNode);

    if (row) {
      row.setDataValue(columnNode, newValue);
    }
  };

  const stopEditingCell = () => {
    agGridRef.current?.api.stopEditing(true);
  };

  const getLastOccupiedColumn = () => {
    const lastOccupiedColumnIndexes: number[] = [];

    const columnDefs = agGridRef?.current?.api.getColumnDefs() || [];

    // Loop through each row to find the last non-empty column
    agGridRef?.current?.api.forEachNode((node) => {
      const rowData = node.data;
      let lastColumnIndex = -1;

      // Loop through each column in the row data
      columnDefs.forEach((colDef, index) => {
        const value = rowData[colDef.headerName as string];
        if (value !== null && value !== undefined && value !== '') {
          lastColumnIndex = index;
        }
      });

      lastOccupiedColumnIndexes.push(lastColumnIndex);
    });

    // Return the max last occupied index across all rows
    return Math.max(...lastOccupiedColumnIndexes);
  };

  return (
    <div className={`ag-theme-quartz ag-theme-unique ${className}`}>
      <AgGridReact
        ref={agGridRef}
        debug={false}
        rowData={rowData}
        columnDefs={[idColumn, ...colDefs]}
        defaultColDef={defaultColDef}
        getRowId={getRowId}
        autoSizeStrategy={autoSizeStrategy}
        rowSelection={{
          mode: 'multiRow',
          checkboxes: false,
          headerCheckbox: false,
          enableClickSelection: true,
        }}
        onColumnHeaderContextMenu={() => null}
        onRowSelected={(event) => handleSelect(event)}
        readOnlyEdit
        onCellEditingStarted={(event) => onCellEditingStarted(event)}
        onCellEditingStopped={(event) => onCellEditingStopped(event)}
        getContextMenuItems={getContextMenuItems}
        onCellEditRequest={onCellEditRequest}
      />
    </div>
  );
});

export const MagicTable = memo(Table);
